import React, { useState, useEffect, useRef } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Cookies from 'js-cookie';
import { IOutletReturnForm, IOutletTransferDet, IOutletTransferItems } from './model';
import { saveValidation } from './validation';
import { Autocomplete, TextField } from '@mui/material';
import { removeDuplicates } from '../../common/shared/utils';


const outletReturnFormNew = observer((): JSX.Element => {

    const { supplierMasterStore, commonStore, outwardtoOutletStore, damageEntryOutletStore } = useStores();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>({});
    const [suplierName, setSuplierName] = useState<any[]>([]);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [isLoadModal, setLoadModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [supplierId, setSupplierId] = useState(0);
    const [supplierName, setSupplierName] = useState("");


    console.log(suplierName, "suplierName");

    const { loadSupplierMastes } = commonStore;

    const [damageEntryItemList, setDamageEntryItemList] = useState<any[]>([]);
    const [damageOutletTransfDet, setDamageOutletTransfDet] = useState<IOutletReturnForm>(new IOutletReturnForm())

    async function addAssignBatchNo(value, inx, checked) {
        if (!damageOutletTransfDet?.damageoutlettransferdet?.some((obj, i) => obj?.doT_damageentrytransferdid === value?.damage_entry_details_id)) {
            let damageTransferDetails: IOutletTransferDet = {};
            damageTransferDetails.doT_OUTID = value?.damageoutlet;
            damageTransferDetails.doT_ItemID = value?.itemid;
            damageTransferDetails.doT_Batchno = value?.batch_no;
            damageTransferDetails.doT_Barcode = value?.Barcode;
            damageTransferDetails.doT_Qty = value?.damage_stk;
            damageTransferDetails.doT_Rate = value?.rate ? value?.rate : value?.purchasePrice;
            damageTransferDetails.doT_TotalAmt = value?.rate ? (Number(value?.rate ?? 0) * Number(value?.damage_stk ?? 0)) : (Number(value?.purchasePrice ?? 0) * Number(value?.damage_stk ?? 0));
            damageTransferDetails.doT_Type = 'Return'
            damageTransferDetails.doT_Reason = value?.Remarks;
            damageTransferDetails.doT_damageentrytransfermstid = damageOutletTransfDet.doM_EntryNo;
            damageTransferDetails.isChecked = checked;
            damageTransferDetails.master_id = value?.damage_entry_master_id;
            damageTransferDetails.doT_damageentrytransferdid = value?.damage_entry_details_id;
            // damageTransferDetails.doT_supplierId = supplierId;
            // damageTransferDetails.doT_supplierName = supplierIdName;
            damageTransferDetails.doT_damageEntryNo = value?.damage_entry_no;
            damageTransferDetails.doT_itemName = value.itemname;
            damageOutletTransfDet?.damageoutlettransferdet?.push(damageTransferDetails)
            setDamageOutletTransfDet(damageOutletTransfDet)
        } else {
            let findInex = damageOutletTransfDet?.damageoutlettransferdet?.findIndex((obj, i) => obj?.doT_damageentrytransferdid === value?.damage_entry_details_id)
            if (findInex !== -1) {
                damageOutletTransfDet?.damageoutlettransferdet?.splice(findInex, 1)
                setDamageOutletTransfDet(damageOutletTransfDet)
            }
        }
        damageEntryItemList.forEach((each) => {
            if (each?.damage_entry_details_id === value?.damage_entry_details_id) {
                each.isChecked = checked;
                //  each.rate = checked ? each?.rate : 0;
                each.type = checked ? each?.type : "";
                each.debitnote = checked ? each.debitnote : 0;
                each.debittype = checked ? each.debittype : "";
            }
            return each
        });

        let damageEntryItemListFilter: any[] = [];
        console.log(damageEntryItemList, "damageEntryItemListaaa");
        damageEntryItemListFilter = damageEntryItemList?.filter((each) => each?.damage_entry_details_id !== value?.damage_entry_details_id)
        setDamageEntryItemList([...damageEntryItemListFilter])

        const totalAmount = damageEntryItemList.reduce((total, entry) => {
            if (entry.isChecked) {
                if (entry?.rate) {
                    return total + (Number(entry?.rate ?? 0) * Number(entry?.damage_stk ?? 0));
                }
                else {
                    return total + (Number(entry?.purchasePrice ?? 0) * Number(entry?.damage_stk ?? 0));
                }
            }
            return total;
        }, 0);

        setDamageOutletTransfDet((prev) => ({
            ...prev,
            rtnamount: totalAmount,
        }));
    }


    async function handleRemove(value: any, index: number) {
        if (damageEntryItemList?.some((item) => item?.damage_entry_details_id !== value.doT_damageentrytransferdid)) {
            let damageEntryItems: IOutletTransferItems = {};
            damageEntryItems.damageoutlet = value.doT_OUTID;
            damageEntryItems.Barcode = value.doT_Barcode;
            damageEntryItems.batch_no = value.doT_Batchno;
            damageEntryItems.SupplierId = value.doT_supplierId;
            damageEntryItems.Remarks = value.doT_Reason;
            damageEntryItems.damage_entry_details_id = value.doT_damageentrytransferdid;
            damageEntryItems.damage_entry_master_id = value.master_id;
            damageEntryItems.damage_entry_no = value.doT_damageEntryNo;
            damageEntryItems.damage_stk = value.doT_Qty;
            damageEntryItems.itemid = value.doT_ItemID;
            damageEntryItems.itemname = value.doT_itemName;
            damageEntryItems.purchasePrice = value.doT_Rate;
            damageEntryItems.rate = value.doT_Rate;
            damageEntryItemList?.push(damageEntryItems);
            setDamageEntryItemList([...damageEntryItemList]);
        } else {
            let damageEntryItems: IOutletTransferItems = {};
            damageEntryItems.damageoutlet = value.doT_OUTID;
            damageEntryItems.Barcode = value.doT_Barcode;
            damageEntryItems.batch_no = value.doT_Batchno;
            damageEntryItems.SupplierId = value.doT_supplierId;
            damageEntryItems.Remarks = value.doT_Reason;
            damageEntryItems.damage_entry_details_id = value.doT_damageentrytransferdid;
            damageEntryItems.damage_entry_master_id = value.master_id;
            damageEntryItems.damage_entry_no = value.doT_damageEntryNo;
            damageEntryItems.damage_stk = value.doT_Qty;
            damageEntryItems.itemid = value.doT_ItemID;
            damageEntryItems.itemname = value.doT_itemName;
            damageEntryItems.purchasePrice = value.doT_Rate;
            damageEntryItems.rate = value.doT_Rate;
            damageEntryItemList?.push(damageEntryItems);
            setDamageEntryItemList([...damageEntryItemList]);
        }

        if (damageOutletTransfDet?.damageoutlettransferdet?.some((obj, i) => i === index)) {
            damageOutletTransfDet?.damageoutlettransferdet?.splice(index, 1)
            setDamageOutletTransfDet({ ...damageOutletTransfDet, "damageoutlettransferdet": damageOutletTransfDet?.damageoutlettransferdet })
        }
    }

    function handleRateUpdate(rate, masterid) {
        if (masterid) {
            damageOutletTransfDet?.damageoutlettransferdet?.forEach((each) => {
                if (each?.doT_damageentrytransferdid === masterid) {
                    each['doT_Rate'] = Number(rate);
                    each['doT_TotalAmt'] = (Number(each.doT_Qty!) * Number(rate));
                }
                return each;
            })
            setDamageOutletTransfDet({ ...damageOutletTransfDet })
            damageEntryItemList.forEach((each) => {
                if (each?.damage_entry_details_id === masterid) {
                    each['rate'] = Number(rate);
                }
                return each;
            });
            setDamageEntryItemList([...damageEntryItemList])
            const totalAmount = damageEntryItemList.reduce((total, entry) => {
                if (entry.isChecked) {
                    if (entry?.rate) {
                        return total + (Number(entry?.rate ?? 0) * Number(entry?.damage_stk ?? 0));
                    }
                    else {
                        return total + (Number(entry?.purchasePrice ?? 0) * Number(entry?.damage_stk ?? 0));
                    }
                }
                return total;
            }, 0);

            setDamageOutletTransfDet((prev) => ({
                ...prev,
                rtnamount: Number(totalAmount),
            }));
        }
    }

    function handleTypeUpdate(type, masterid) {
        if (masterid) {
            damageOutletTransfDet?.damageoutlettransferdet?.forEach((each) => {
                if (each?.master_id === masterid) {
                    each['doT_Type'] = type
                }
                return each;
            })
            setDamageOutletTransfDet({ ...damageOutletTransfDet })
            damageEntryItemList.forEach((each) => {
                if (each?.damage_entry_master_id === masterid) {
                    each['type'] = type;
                }
                return each;
            });
            setDamageEntryItemList([...damageEntryItemList])
        }
    }

    async function saveAssignBatchNo(e) {
        e.preventDefault();
        damageOutletTransfDet.doM_SupplierId = supplierId;
        damageOutletTransfDet.doM_SupplierName = supplierName;
        let error: any = {}
        error = saveValidation(damageOutletTransfDet)
        setErrors({ ...error })
        console.log(damageOutletTransfDet, "damageOutletTransfDet");
        console.log(Object.keys(error)?.length, "errorlength")
        if (Object.keys(error)?.length === 0) {
            const status = await damageEntryOutletStore?.saveDamageOutletTransferMst(damageOutletTransfDet)
            if (status === "Success") {
                setSuccessModal(true)
            } else {
                setFailureModal(true);
            }
        }
    }
    const handleFailureModalClose = () => setFailureModal(false);
    const handleCancel = () => {
        navigate('/Admin/OutletReturnForm/List');
    }
    const handleSuccessModalClose = () => {
        navigate('/Admin/OutletReturnForm/List');
    }
    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }


    async function fetchAssignDefaultLoad() {
        const entryNos = await outwardtoOutletStore.getEntryNo('DamageOutletTransfer');
        setDamageOutletTransfDet({ ...damageOutletTransfDet, doM_EntryNo: entryNos?.entryNo })
        // const data = await damageEntryOutletStore.LoadDamageTransfersuppliers()
        // setSuplierName(data);
        const data = supplierMasterStore?.supplierMasterList
        console.log(data, "datavalueee");

        const supplierMaster = await supplierMasterStore.getSupplierMaster();
        setSuplierName(supplierMaster);

        const damageEntryItemList = await damageEntryOutletStore.loadDamageTransferOutletDetails()
        setDamageEntryItemList([...damageEntryItemList])
        await loadSupplierMastes()
    }

    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Outlet_return_form';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            fetchAssignDefaultLoad();
                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/OutletReturnForm/List');
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);


    const handleChaneValue = (event: any, value: any) => {
        setSupplierId(value?.Supid);
        setSupplierName(value?.SupplierName);
    }

    function handleInputchange(e) {
        const { name, value } = e.target;
        setDamageOutletTransfDet({ ...damageOutletTransfDet, [name]: value })
    }

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {
        return (
            <>
                <div className='container'>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField'>
                        <div className='hrBox'>
                            <h3>Outlet Return Form New</h3>
                        </div>
                        <div className='inputBoxLists'>
                            <div className='ItemInwardInputBox'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <div className='inputBox'>
                                            <label>Entry No <span>*</span></label>
                                            <input
                                                type="text"
                                                placeholder="Entry No"
                                                name='doM_EntryNo'
                                                value={damageOutletTransfDet?.doM_EntryNo}
                                                disabled
                                            >
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='inputBox'>
                                            <label>Date <span>*</span></label>
                                            <input
                                                type="date"
                                                placeholder="DD-MMM-YYYY"
                                                data-date=""
                                                name='doM_Date'
                                                value={damageOutletTransfDet?.doM_Date}
                                                // value={inwardDetail.inwardDate = inwardDetail.inwardDate ?? new Date().toISOString().substr(0, 10)}
                                                onKeyDown={(event) => event.preventDefault()}
                                                data-date-format="DD MMMM YYYY"
                                                onChange={handleInputchange}
                                            >
                                            </input>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <label>Select Supplier Name <span style={{ color: 'red' }}>*</span></label>
                                        <Autocomplete
                                            size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={suplierName}
                                            getOptionLabel={(option: any) => option?.SupplierName}
                                            onChange={(event, val) => handleChaneValue(event, val)}
                                            renderInput={(params: any) =>
                                                <TextField
                                                    {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    fullWidth
                                                    placeholder='Supplier Name'
                                                    name='doM_SupplierId'
                                                />
                                            }
                                        />
                                        {errors.doM_SupplierId && <p style={{ color: 'red' }}>{errors.doM_SupplierId}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='inputBoxLists' >
                            <div className='tableBox' style={{ maxHeight: '500px', overflow: 'scroll' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col"></th>
                                            <th scope="col">DT No</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Barcode</th>
                                            <th scope="col">Batch No</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">Rate</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Reason</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ verticalAlign: 'middle' }}>
                                        {damageEntryItemList?.slice()?.sort((a, b) => b.damage_entry_no - a.damage_entry_no)?.map((val, key) => {
                                            return val?.purchasePrice !== null && val?.damage_stk !== null ? (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td><input type="checkbox" checked={false} onChange={(event) => addAssignBatchNo(val, key, event?.target?.checked)} /></td>
                                                    <td>{val?.damage_entry_no}</td>
                                                    <td>{moment(val.damage_entry_date).format('DD-MMM-YYYY')}</td>
                                                    <td>{val?.itemname}</td>
                                                    <td>{val?.Barcode}</td>
                                                    <td>{val?.batch_no}</td>
                                                    <td>{val?.damage_stk}</td>
                                                 
                                                    <td><input defaultValue={val?.purchasePrice?.toFixed(2)} type='text' name='rate' step='0.01'
                                                        style={{ width: '70%' }} onChange={(event) => {
                                                            const value = parseFloat(event.target.value);
                                                            if (!isNaN(value)) {
                                                                handleRateUpdate(value.toFixed(2), val?.damage_entry_details_id);
                                                            }
                                                        }} ></input> </td>
                                                    <td>
                                                        {val?.rate !== undefined
                                                            ? (Number(val.rate) * Number(val.damage_stk ?? 0)).toFixed(3)
                                                            : (Number(val.purchasePrice ?? 0) * Number(val.damage_stk ?? 0)).toFixed(3)}
                                                    </td>
                                                    <td>{val?.Remarks}</td>
                                                    <td>
                                                        <select className="form-selected" onChange={(event) => handleTypeUpdate(event?.target?.value, val?.damage_entry_master_id)}
                                                            name="type" disabled={!val?.isChecked} value={val?.type}
                                                            style={{ width: "100%" }} id="Openingtype" >
                                                            <option value="Return" selected>Return</option>
                                                        </select>
                                                    </td>
                                                    {/* <td>{val?.damage_entry_details_id}</td> */}
                                                </tr>
                                            ) : null;
                                        })}
                                        <tr>
                                            <td colSpan={3}></td>
                                            <td>{errors.rate && <p style={{ color: 'red' }}>{errors.rate}</p>}</td>
                                            <td colSpan={2}></td>
                                            <td>{errors.type && <p style={{ color: 'red' }}>{errors.type}</p>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {errors.damageoutlettransferdet && <p style={{ color: 'red' }}>{errors.damageoutlettransferdet}</p>}

                            <div>
                                <div className='tableBox mt-5' style={{ maxHeight: '500px', overflow: 'scroll' }}>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">DT No</th>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Barcode</th>
                                                <th scope="col">Batch No</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Rate</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">Reason</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ verticalAlign: 'middle' }}>
                                            {damageOutletTransfDet?.damageoutlettransferdet?.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{val.master_id}</td>
                                                        <td>{val.doT_itemName}</td>
                                                        <td>{val?.doT_Barcode}</td>
                                                        <td>{val?.doT_Batchno}</td>
                                                        <td>{val?.doT_Qty}</td>
                                                        <td>{val?.doT_Rate}</td>
                                                        <td>{val?.doT_TotalAmt}</td>
                                                        <td>{val?.doT_Reason}</td>
                                                        <td>
                                                            <button onClick={() => handleRemove(val, key)}>Remove</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' onClick={handleCancel}>List</button>
                            <button className='dfBtn' type='submit'
                                onClick={saveAssignBatchNo}
                            >Submit</button>
                        </div>
                    </div>
                    <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../gsmecom/images/checked.png')} />
                                </div>

                                <h4>Succesfully Submitted</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleSuccessModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>

                                <div className='imgBox'>
                                    <Image src={require('../../../gsmecom/images/warning.png')} />
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFailureModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        );
    }
});

export default outletReturnFormNew;