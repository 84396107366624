import React, { useState, useEffect, useDebugValue, useRef, } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import assignOfferDiscountDetail, { IAssignOfferAndDiscountDetails, IAssignOfferAndDiscount, IAssignOfferDiscountModel } from './model';
import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import assignofferValidation, { saveValidation } from './validation';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { handleFindToDate, removeDuplicates } from '../../common/shared/utils';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const AssignOfferDicount = observer((): JSX.Element => {
  const {supplierMasterStore, commonStore, assignOfferDiscountStores, userCreationStore } = useStores();;
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const { loadItemMaster, loadItemMasters, loadEmployee, loadSubcategories,
    loadManageOfferOrDiscount, loadManageOffersOrDiscounts, loadSubcategory } = commonStore;
  const { getUserCreation } = userCreationStore;
  const [selectedOption, setSelectedOption] = useState('products');
  const [isProducts, setProducts] = useState(false);
  const [isCategory, setCategory] = useState(false);
  const [isBillValue, setBillValue] = useState(false);
  const [isEveryBilling, setEveryBilling] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [employeeList, setEmployeeList] = useState<any>([])
  const { assignofferid } = useParams<{ assignofferid: string }>();
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 
  //loadEmployees,

  async function fetchPreLoadngData() {
    setLoading(false)
    await Promise.all([
      loadItemMaster(),
      loadEmployee(),
      loadManageOfferOrDiscount(),
      loadSubcategory(),
    ])
    const employeeList = await getUserCreation();
    setEmployeeList([...employeeList])
    console.log(employeeList, 'employeeList');
  }
  const isInitialRender = useRef(true);


  // useEffect(() => {
  //   if (isInitialRender.current) {
  //     fetchPreLoadngData();
  //     getAssignOfferDet();
  //     isInitialRender.current = false;
  //   } else {
  //   }
  //   return () => {
  //     // Cleanup logic (if needed)
  //   };
  // }, []);
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Assign_offer_discounts';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);
 
        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadngData();
             
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/AssignOfferDiscount/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }
       else {
        setLoadModal(true)
       }
      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

 



  const handleCancel = () => {
    navigate('/Admin/AssignOfferDiscount/list');
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignOfferDiscount/list');
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  var radio_props = [{ val: "Products", key: 'products' }, { val: "Sub category", key: 'category' },
  { val: "Bill Value", key: 'billValue' }, { val: "Every Billing", key: 'everyBilling' }];

  const [options, setOptions] = useState<any>({});

  const [assignOfferDiscount, setAssignOfferDiscount] = useState<IAssignOfferAndDiscountDetails>(new IAssignOfferAndDiscountDetails())
  const [assignOffer, setAssigOffer] = useState<IAssignOfferDiscountModel>(new IAssignOfferDiscountModel())


  function handleInputChanges(e) {
    const { name, value } = e.target;
    if (name === 'approvalRequest') {
      setAssigOffer({ ...assignOffer, approvalRequest: e.target.value })
    } else {
      setAssignOfferDiscount({ ...assignOfferDiscount, [name]: value })
    }
  }

  function handleAutoComplete(name, value) {
    if (name === "assignItemId" && value != null) {
      setAssignOfferDiscount({ ...assignOfferDiscount, [name]: value?.itemid, assignOfferItemName: value?.itemName })
    } else if (name === "assignCategoryId" && value != null) {
      setAssignOfferDiscount({ ...assignOfferDiscount, [name]: value?.subCategoryId, assignCategoryName: value?.subCategoryName })
    } else if (name === "assignDiscountOfferId" && value != null) {
      setAssigOffer({ ...assignOffer, offerId: value?.offerId })
      setAssignOfferDiscount({ ...assignOfferDiscount, [name]: value?.offerId, assignOfferName: value?.offerName })
    } else if (name === "assignedById" && value != null) {
      setAssigOffer({ ...assignOffer, [name]: value?.employeeId })
    }
  }

  function addOfferToGrid(e) {
    e.preventDefault();
    let error: any = {};

    error = assignofferValidation(assignOfferDiscount, options);
    if (!assignOffer!.assignedById) {
      error.assignedById = "Select Assigned By"
    }
    setErrors(error);
    if (Object.keys(error).length === 0) {
      assignOfferDiscount.assignEffectiveFromDate = moment(assignOfferDiscount.assignEffectiveFromDate).format('YYYY-MM-DD HH:mm:ss:SS');
      assignOfferDiscount.assignEffectiveToDate = moment(assignOfferDiscount.assignEffectiveToDate).format('YYYY-MM-DD HH:mm:ss:SS');
      if (assignOfferDiscount) {
        if (!assignOffer?.assignOffetDetails) {
          assignOffer.assignOffetDetails = [];
        }
        assignOffer?.assignOffetDetails?.push(assignOfferDiscount!)
        setAssigOffer(assignOffer)
        setAssignOfferDiscount({
          ...assignOfferDiscount,
          assignOfferItemName: '', assignCategoryName: '',
          assignOfferName: '', assignEffectiveFromDate: new Date().toISOString(),
          assignEffectiveToDate: new Date().toISOString(),
        })
        setErrors({})
      }
    }
  }

  async function getAssignOfferDet() {
    console.log(assignofferid,'assignofferid');
    
    if (assignofferid !== undefined) {
      const data: IAssignOfferDiscountModel[] = await assignOfferDiscountStores.getAssignOfferDiscounts();
      const formattedData = Array.from(data);
      console.log(JSON.stringify(formattedData, null, 2), 'data');
      const assignOfferList = await assignOfferDiscountStores.getAssignOfferDiscountsDetails(Number(assignofferid));

      if (assignOfferList?.length > 0) {
        console.log(assignOfferList, 'assignOfferList');

        const updateProducts = assignOfferList.map((offer) => ({
          assignOffeId: offer?.assignId,
          assignOffeDetailId: offer?.assignOfferDetailId,
          assignOfferItemId: offer?.assignOfferItemId,
          assignItemId: offer?.assignItemId,
          assignOfferItemName: offer?.assignOfferItemName,
          // assignCategoryId: offer?.assignCategoryId,
          // assignBillValue: offer?.assignBillValue,
          // assignEveryBilling: offer?.assignEveryBilling,
          // assignCategoryName: offer?.assignCategoryName,
          assignDiscountOfferId: offer?.assignDiscountOfferId,
          assignOfferName: offer?.assignOfferName,
          assignEffectiveFromDate: offer?.assignEffectiveFromDate,
          assignEffectiveToDate: offer?.assignEffectiveToDate
        }));

        const updateCategory = assignOfferList.map((offer) => ({
          assignOffeId: offer?.assignId,
          assignOffeDetailId: offer?.assignOfferDetailId,
          assignOfferItemId: offer?.assignOfferItemId,
          assignCategoryId: offer?.assignCategoryId,
          assignCategoryName: offer?.assignCategoryName,
          assignDiscountOfferId: offer?.assignDiscountOfferId,
          assignOfferName: offer?.assignOfferName,
          assignEffectiveFromDate: offer?.assignEffectiveFromDate,
          assignEffectiveToDate: offer?.assignEffectiveToDate
        }));

        const updateBilling = assignOfferList.map((offer) => ({
          assignOffeId: offer?.assignId,
          assignOffeDetailId: offer?.assignOfferDetailId,
          assignDiscountOfferId: offer?.assignDiscountOfferId,
          assignOfferName: offer?.assignOfferName,
          assignBillValue: offer?.assignBillValue,
          assignEffectiveFromDate: offer?.assignEffectiveFromDate,
          assignEffectiveToDate: offer?.assignEffectiveToDate
        }));

        const updateEveryBilling = assignOfferList.map((offer) => ({
          assignOffeId: offer?.assignId,
          assignOffeDetailId: offer?.assignOfferDetailId,
          assignDiscountOfferId: offer?.assignDiscountOfferId,
          assignEveryBilling: offer?.assignEveryBilling,
          assignOfferName: offer?.assignOfferName,
          assignEffectiveFromDate: offer?.assignEffectiveFromDate,
          assignEffectiveToDate: offer?.assignEffectiveToDate
        }));


        const matchedUser = formattedData.find(assign => Number(assign.assignOfferId) === Number(assignofferid));
        if (matchedUser) {


          setAssigOffer({
            ...assignOffer,
            assignOfferId: matchedUser.assignOfferId,
            assignOffeDetailId: matchedUser.assignOffeDetailId,
            assignedById: matchedUser.assignedById,
            approvalRequest: matchedUser.approvalRequest,
            assinOfferType: matchedUser.assinOfferType,
            // assignOffetDetails: updateProducts
          });
 

          setProducts(false);
          setBillValue(false);
          setCategory(false);
          setEveryBilling(false);

          switch (matchedUser.assinOfferType) {
            case 'products':
              setProducts(true);
              setOptions('products');
              setSelectedOption('products');
              setOptions({ ...options, Products: 'products' })  
              setAssigOffer({
                ...assignOffer,
                assignOfferId: matchedUser.assignOfferId,
                assignOffeDetailId: matchedUser.assignOffeDetailId,
                assignedById: matchedUser.assignedById,
                approvalRequest: matchedUser.approvalRequest,
                assinOfferType: matchedUser.assinOfferType,
                assignOffetDetails: updateProducts
              });
              break;
            case 'category':
              setCategory(true);
              setOptions('category');
              setSelectedOption('category');
              setAssigOffer({
                ...assignOffer,
                assignOfferId: matchedUser.assignOfferId,
                assignOffeDetailId: matchedUser.assignOffeDetailId,
                assignedById: matchedUser.assignedById,
                approvalRequest: matchedUser.approvalRequest,
                assinOfferType: matchedUser.assinOfferType,
                assignOffetDetails: updateCategory
              });
              break;
            case 'billValue':
              setBillValue(true);
              setOptions('billValue');
              setSelectedOption('billValue');
              setAssigOffer({
                ...assignOffer,
                assignOfferId: matchedUser.assignOfferId,
                assignOffeDetailId: matchedUser.assignOffeDetailId,
                assignedById: matchedUser.assignedById,
                approvalRequest: matchedUser.approvalRequest,
                assinOfferType: matchedUser.assinOfferType,
                assignOffetDetails: updateBilling
              });
              break;
            case 'everyBilling':
              setEveryBilling(true);
              setOptions('everyBilling');
              setSelectedOption('everyBilling');
               setAssigOffer({
                ...assignOffer,
                assignOfferId: matchedUser.assignOfferId,
                assignOffeDetailId: matchedUser.assignOffeDetailId,
                assignedById: matchedUser.assignedById,
                approvalRequest: matchedUser.approvalRequest,
                assinOfferType: matchedUser.assinOfferType,
                assignOffetDetails: updateEveryBilling
              });
              break;
            default:
          }

          console.log(matchedUser.assinOfferType, 'matchedUser.assinOfferType');
        }
        console.log(assignOffer, 'assignOffer');
      }
    }
  }

  if(assignofferid) {
    useEffect(() => {
      console.log('assignOffer updated:', assignOffer);
    }, [assignOffer]);  
  }

  function handleRemove(index) {
    assignOffer?.assignOffetDetails?.splice(index, 1)
    setAssigOffer(_.cloneDeep(assignOffer))
  }

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setOptions({ ...options, [name]: value })
    setSelectedOption(value);
    if (value === 'products') {
      setProducts(true);
      setBillValue(false);
      setCategory(false);
      setEveryBilling(false);
    }
    if (value === 'category') {
      setProducts(false);
      setBillValue(false);
      setCategory(true);
      setEveryBilling(false);
    }
    if (value === 'billValue') {
      setProducts(false);
      setBillValue(true);
      setCategory(false);
      setEveryBilling(false);
    }
    if (value === 'everyBilling') {
      setProducts(false);
      setBillValue(false);
      setCategory(false);
      setEveryBilling(true);
    }
  }

  async function saveAssignOfferDiscount(e) {
    let error: any = {}
    error = saveValidation(assignOffer)
    setErrors({ ...error })
    if (Object.keys(error)?.length === 0) {
      setLoading(true);
      console.log(assignOffer,'assignOffer');
      
      if (assignofferid) {
        assignOffer.approvalRequest = assignOffer?.approvalRequest ? assignOffer?.approvalRequest : "No";
        const status = await assignOfferDiscountStores.saveAssignOfferDiscount(assignOffer);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        assignOffer.assinOfferType = options?.options ? options?.options : 'products';
        assignOffer.approvalRequest = assignOffer?.approvalRequest ? assignOffer?.approvalRequest : "No";
        console.log(assignOffer,'assignOffer555');
        
        const status = await assignOfferDiscountStores.saveAssignOfferDiscount(assignOffer);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  }

  useEffect(() => {
    const defaultEvent = { target: { value: selectedOption } };
    handleRadioChange(defaultEvent);
  }, []);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>

            <div className='container'>

              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Assign Offers & Discount</h3>
                </div>
                <div className='inputBoxLists pd-b-0'>
                  <div className='LgInputListsBox mr-left-0'>
                    <div className='row'>
                      {radio_props.map((val, key) =>
                        <div className='col-md-3' >
                          <div className='inputBox'>
                            <label>{val.val}</label>
                            <input type='radio' className='' onChange={handleRadioChange} value={val.key} name='options' checked={selectedOption === val.key}></input>
                          </div>
                        </div>
                      )}
                       {/* checked={selectedOption === val.key} */}
                      {errors.options && <p style={{ color: 'red' }}>{errors.options}</p>}
                      {isProducts ?
                        <div className='col-md-12'>
                          <div className='inputBox'>
                            <label>Product <span>*</span></label>
                            <AutoComplete value={assignOfferDiscount?.assignOfferItemName} clsName='full-width' getOptionLabel='itemName' options={loadItemMasters}
                              emitOption={(option: any) => handleAutoComplete('assignItemId', option)} placeholder={'Select Product...'}
                            />
                            {errors.assignItemId && <p style={{ color: 'red' }}>{errors.assignItemId}</p>}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div> : isCategory ?
                          <div className='col-md-12'>
                            <div className='inputBox'>
                              <label>Sub Category <span>*</span></label>
                              <AutoComplete value={assignOfferDiscount?.assignCategoryName} clsName='full-width' getOptionLabel='label' options={loadSubcategories}
                                emitOption={(option: any) => handleAutoComplete('assignCategoryId', option)} placeholder={'Select Sub Category...'} />
                              {errors.assignCategoryId && <p style={{ color: 'red' }}>{errors.assignCategoryId}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div> : null
                      }

                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>Discount Name<span>*</span> </label>
                          <AutoComplete value={assignOfferDiscount?.assignOfferName} clsName='full-width' getOptionLabel='offerName' options={loadManageOffersOrDiscounts}
                            emitOption={(option: any) => handleAutoComplete('assignDiscountOfferId', option)} placeholder={'Select Discount...'}
                          />
                          {errors.assignDiscountOfferId && <p style={{ color: 'red' }}>{errors.assignDiscountOfferId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Effective From Date & Time <span>*</span></label>
                          <input type="datetime-local" style={{ width: "100%" }}
                            name='assignEffectiveFromDate'
                            value={assignOfferDiscount?.assignEffectiveFromDate}
                            onChange={handleInputChanges}
                            min={new Date().toISOString().slice(0, 16)}
                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" onKeyDown={(event) => event.preventDefault()}></input>
                          {errors.assignEffectiveFromDate && <p style={{ color: 'red' }}>{errors.assignEffectiveFromDate}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Effective To Date & Time <span>*</span></label>
                          <input type="datetime-local" style={{ width: "100%" }}
                            name='assignEffectiveToDate'
                            value={assignOfferDiscount?.assignEffectiveToDate}
                            onChange={handleInputChanges}
                            min={handleFindToDate(assignOfferDiscount?.assignEffectiveFromDate ?? new Date(), 'datetime-local')}
                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" onKeyDown={(event) => event.preventDefault()}></input>
                          {errors.assignEffectiveToDate && <p style={{ color: 'red' }}>{errors.assignEffectiveToDate}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Assigned By <span>*</span></label>
                          {/* <AutoComplete 
                            clsName='full-width' getOptionLabel='employeeName' options={removeDuplicates(loadEmployee, 'employeeName')}
                            emitOption={(option: any) => handleAutoComplete('assignedById', option)} placeholder={'Select Assign By...'}
                          /> */}
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={removeDuplicates(employeeList, 'user_name')}
                            value={employeeList.userId}
                            // value={employeeList?.find((each) => each?.EmployeeId === userAllocation?.empid)}
                            getOptionLabel={(option: any) => option?.user_name}
                            onChange={(event, newValue) => setAssigOffer({ ...assignOffer, assignedById: newValue?.userId })}
                            renderInput={(params: any) =>
                              <TextField  {...params}
                                fullWidth
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Employee..'
                                name='empid'
                              />}
                          />
                          {errors.assignedById && <p style={{ color: 'red' }}>{errors.assignedById}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      {/* <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Approval Request </label>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='inputBox'>
                                <label>Yes</label>
                                <input type='radio' className='' value='Yes' name='approvalRequest' onChange={(e) => handleInputChanges(e)}></input>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='inputBox'>
                                <label>No </label>
                                <input type='radio' className='' defaultChecked value='No' name='approvalRequest' onChange={(e) => handleInputChanges(e)}></input>
                              </div>
                            </div>
                          </div>
                          {errors.approvalRequest && <p style={{ color: 'red' }}>{errors.approvalRequest}</p>}
                          <div className='vertical-space-10'></div>
                        </div>
                      </div> */}
                      <div className='col-sm-2'>
                        <button className='dfBtn pd-0-12 full-width mr-1 ' type='submit' onClick={addOfferToGrid} >ADD</button>
                      </div>
                    </div>
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className=''>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {isProducts ? <th scope="col">Products</th> : null}
                            {isCategory ? <th scope="col">Category</th> : null}
                            {isBillValue ? <th scope="col">Bill Value</th> : null}
                            {isEveryBilling ? <th scope="col">Every Billing</th> : null}
                            {isProducts || isCategory ? <th scope="col">Discount Name</th> : null}
                            <th scope="col">Effective From Date</th>
                            <th scope="col">Effective To Date</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {assignOffer?.assignOffetDetails?.map((val, key) => {
                            return (
                              <tr key={key}>
                                {isCategory ? <td>{val.assignCategoryName}</td> : null}
                                {isProducts ? <td>{val.assignOfferItemName}</td> : null}
                                {isBillValue ? <td>{val.assignBillValue}</td> : null}
                                {isEveryBilling ? <td>{val.assignEveryBilling}</td> : null}
                                {isCategory || isProducts ? <td>{val.assignOfferName}</td> : null}
                                <td>{moment(val.assignEffectiveFromDate).format("DD-MMM-YYYY HH:MM")}</td>
                                <td>{moment(val.assignEffectiveToDate).format("DD-MMM-YYYY HH:MM")}</td>
                                <td>
                                  <button onClick={() => handleRemove(key)} style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit'>Remove</button>
                                </td>
                              </tr>
                            )
                          })
                          }
                        </tbody>
                      </table>
                      {errors?.assignOffetDetails && <p style={{ color: 'red' }}>{errors?.assignOffetDetails}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit' onClick={saveAssignOfferDiscount} >Submit</button>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>
  );
  }
});

export default AssignOfferDicount;
