import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IPOSHoldDetails, IPOSProductDetails, IPosBatchDetails, IPosSalesDetails, IPossalesMaster,IPosupdate } from '../model/index';
import { ICounterOpeningSave } from '../../../BackOffice/CounterOpeningEntry/model';
import { ICounterClose } from '../../../BackOffice/ClosingCashHandover/model';


class PosMasterStore {
    @observable posMasterDetls = new Map();
    @observable posMasterList = new Map<number, IPossalesMaster>();
    getposMasters = new Map();
    getposMasterDetls = new Map();
    @observable posCounter = new Map();
    @observable posCustomerDetails = new Map();
    @observable posItemMaster = new Map();
    @observable getItemOffers = new Map();
    @observable getFreeItems = new Map();
    @observable entryNo = new Map();
    @observable getHolds = new Map();
    @observable getPOSHoldsDetails = new Map();
    @observable itemMasterDetails = new Map();
    @observable itemBatchNo = new Map();
    @observable offers = new Map();
    @observable posItemDetail = new Map();
    @observable loadPOSProducts = new Map();
    loadCounterMasterDetails = new Map();
    counterOpeningEntryDetail = new Map();
    constructor() {
        makeObservable(this);
    }
    savePosMaster = async (posMaster: IPossalesMaster) => {
        try {
            const status = await apiAgent.posMaster.savePosMaster(posMaster);
            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    UpdatePosMaster = async (paymentAmount: IPosupdate) => {
        try {
            const status = await apiAgent.posMaster.UpdatePosMaster(paymentAmount);
            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    holdcarts = async (posHoldDetails: IPOSHoldDetails) => {
        try {
            const status = await apiAgent.posMaster.holdCarts(posHoldDetails);
            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    getPosCounter = async () => {

        try {
            const getposCounter = await apiAgent.posMaster.getPosCounter();

            runInAction(() => {

                this.posCounter = getposCounter;
            });
            return this.posCounter;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPosBillNo = async (request) => {

        try {
            const entryNo = await apiAgent.adminModule.getBillNo(request);

            runInAction(() => {

                this.entryNo = entryNo;
            });
            return entryNo
        }
        catch (error) {
            console.log(error);
        }
    }
    getInvoiceList = async (body) => {
        try {
            const invoiceList = await apiAgent.posMaster.getInvoiceList(body);
            return invoiceList
        } catch (error) {
            console.log(error)
        }
    }
    getPOSCustomerDetails = async (mobileNo) => {

        try {
            const getposCustomer = await apiAgent.posMaster.getPOSCustomerDetails(mobileNo);

            runInAction(() => {

                this.posCustomerDetails = getposCustomer;
            });
            return getposCustomer;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPosMaster = async () => {

        try {
            const getposMaster = await apiAgent.posMaster.getPosMaster();

            runInAction(() => {

                this.getposMasters = getposMaster;
            });
            return this.getposMasters;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPosMasterDetails = async (posid) => {

        try {
            const getPos = await apiAgent.posMaster.getPosMasterDetails(posid);

            runInAction(() => {

                this.getposMasterDetls = getPos;
            });
            return this.getposMasterDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    getHoldPOS = async () => {

        try {
            const getholdData = await apiAgent.posMaster.getHoldPOS();

            runInAction(() => {

                this.getHolds = JSON.parse(getholdData);
            });
            return this.getHolds;
        }
        catch (error) {
            console.log(error);
        }
    }
    getHoldBillDetail = async (holdId) => {
        
        try {
            const getPosHoldMaster = await apiAgent.posMaster.getHoldPOSMaster(holdId);
            const getHoldItemDetails = await apiAgent.posMaster.getHoldPOSDetails(holdId);
            const getHoldBatchDetails = await apiAgent.posMaster.getHoldPOSBatchDetails(holdId);

            
            let posDetail: IPossalesMaster = {};

            posDetail.memberId = JSON.parse(getPosHoldMaster)[0]!.MembrId;
            posDetail.memberName = JSON.parse(getPosHoldMaster)[0]!.MemberName;
            posDetail.mobileNo = JSON.parse(getPosHoldMaster)[0]!.MobileNo;
            posDetail.memberType = JSON.parse(getPosHoldMaster)[0]!.cust_type;
            posDetail.memberCategory = JSON.parse(getPosHoldMaster)[0]!.cust_category;
            posDetail.memberAddress = JSON.parse(getPosHoldMaster)[0]!.Address;
            posDetail.employeeId = JSON.parse(getPosHoldMaster)[0]!.EmployeeId;
            posDetail.employeeName = JSON.parse(getPosHoldMaster)[0]!.EmployeeName;
            posDetail.counterNumber = JSON.parse(getPosHoldMaster)[0]!.CounterNumber;
            posDetail.holdid=holdId;

            let posSalesDetais = JSON.parse(getHoldItemDetails)?.map((items) => {
                let PosSalesDetails: IPOSProductDetails = {
                    checked: true,
                    posDiscountPercentage: items?.Discount_PV,
                    posDiscountValue: items?.Discountvalue,
                    posItemId: items?.itemid,
                    posItemMRP: items?.Mrprate,
                    posItemName: items?.itemname,
                    posItemQty: items?.ItemQty,
                    posItemSellingRate: items?.sellingprice,
                    posNetAmount: items?.Netamount,
                    posSubCategoryId: 0,
                    posTaxPercentage: items?.taxper,
                    posTaxValue: items?.Taxvalue,
                    posTotalPrice: items?.Amount,
                    posbatchNo: items?.Batchno,
                    postRowId: 1
                }
                return PosSalesDetails;
            })
            let posbatchdetail = JSON.parse(getHoldBatchDetails)?.map((batches) => {
                let posBatchDetails: IPosBatchDetails = {
                    barcode: batches?.Barcode,
                    batchNo: batches?.batch_no,
                    batchQty: batches?.batchqty,
                    itemId: batches?.itemid
                }
                return posBatchDetails;
            })

            posDetail.PosSalesDetails = posSalesDetais;
            posDetail.posBatchDetails = posbatchdetail

            return posDetail
        }
        catch (error) {
            console.log(error);
        }
    }
    
    getHoldPOSDetails = async (holdId) => {
        try {
            const getPOSHoldsDetails = await apiAgent.posMaster.getHoldPOSDetails(holdId);
            return this.getPOSHoldsDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteHoldBill = async (holdId) => {
        try {
            const status = await apiAgent.posMaster.deleteHoldBill(holdId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getItemDetails = async (product) => {

        try {
            const itemDetails = await apiAgent.posMaster.getItemDetails(product);

            runInAction(() => {

                this.itemMasterDetails = itemDetails;
            });
            return itemDetails
        }
        catch (error) {
            console.log(error);
        }
    }
    getProductPriceDetails = async (posItemDetails: IPosSalesDetails) => {
        try {
            const itemDetails = await apiAgent.posMaster.getProductPriceDetails(posItemDetails);
            runInAction(() => {
                this.posItemDetail = itemDetails
            })
            return itemDetails
        }
        catch (error) {
            console.log(error);
        }
    }
    loadItemBatchNo = async (itemid, outletid) => {

        try {
            const item = await apiAgent.posMaster.loadItemBatchNo(itemid, outletid);
            this.itemBatchNo = new Map();

            runInAction(() => {
                this.itemBatchNo = item;

            });
            return this.itemBatchNo;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadInvoiceBatchNo = async (itemid, outletid) => {

        try {
            const item = await apiAgent.posMaster.loadInvoiceBatchNo(itemid, outletid);
            this.itemBatchNo = new Map();

            runInAction(() => {
                this.itemBatchNo = item;

            });
            return item;
        }
        catch (error) {
            console.log(error);
        }
    }
    ProceedPayment = async (paymentRequest) => {

        try {
            const status = await apiAgent.posMaster.ProceedPayment(paymentRequest);


            return status
        }
        catch (error) {
            console.log(error);
        }
    }
    confirmPayment = async (confirmPaymentPayload) => {

        try {
            const status = await apiAgent.posMaster.confirmPayment(confirmPaymentPayload);


            return status
        }
        catch (error) {
            console.log(error);
        }
    }
    getFreeItem = async (pOSFreeItemRequest) => {

        try {
            const status = await apiAgent.posMaster.getFreeItem(pOSFreeItemRequest);


            return status
        }
        catch (error) {
            console.log(error);
        }
    }
    getBillvalueOffer = async (checkofferRequest) => {

        try {
            const offerDetails = await apiAgent.posMaster.getBillvalueOffer(checkofferRequest);


            runInAction(() => {
                this.offers = offerDetails;
            });
            return this.offers;
        }
        catch (error) {
            console.log(error);
        }
    }
    submitCounterOpeningSave = async (counterOpeningsave: ICounterOpeningSave) => {

        try {
            const status = await apiAgent.posMaster.submitcounterOpeningEntry(counterOpeningsave);

            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    submitClosingCashHandOver = async (closingCashHandOver: ICounterClose) => {

        try {
            const status = await apiAgent.posMaster.submintClosingCashHandOver(closingCashHandOver);

            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    loadCounterOpeningMaster = async (denominations: any) => {
        try {
            const loadCounterMasterDetail = await apiAgent.posMaster.loadCounterOpeningMaster(denominations);
            runInAction(() => {
                this.loadCounterMasterDetails = loadCounterMasterDetail;
            });

            return this.loadCounterMasterDetails;
        }
        catch (error) {

        }
    }
    getConuterEntryDetails = async (denominationid) => {
        try {
            const counterOpeningEntryDetail = await apiAgent.posMaster.getCounterEntryDetails(denominationid);

            runInAction(() => {
                this.counterOpeningEntryDetail = counterOpeningEntryDetail;
            })

            return this.counterOpeningEntryDetail;
        }
        catch (error) {
            console.log(error)
        }
    }
    getCounterEntryUpdateDetails = async (denominationid) => {
        try {
            const counterOpeningEntryDetail = await apiAgent.posMaster.getCounterEntryUpdateDetails(denominationid);

            return counterOpeningEntryDetail;
        }
        catch (error) {
            console.log(error)
        }
    }
    deleteCounterEntry = async (denominationid) => {
        try {
            const status = await apiAgent.posMaster.deleteCounterEntry(denominationid);

            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    getClosingHandOverDetails = async (denominationid) => {
        try {
            const counterOpeningEntryDetail = await apiAgent.posMaster.getClosingHandOverDetails(denominationid);

            runInAction(() => {
                this.counterOpeningEntryDetail = counterOpeningEntryDetail;
            })

            return this.counterOpeningEntryDetail;
        }
        catch (error) {
            console.log(error)
        }
    }
    loadClosingHandoverMaster = async (denominations: any) => {
        try {
            const loadCounterMasterDetail = await apiAgent.posMaster.loadClosingHandoverMaster(denominations);
            runInAction(() => {
                this.loadCounterMasterDetails = loadCounterMasterDetail;
            });

            return this.loadCounterMasterDetails;
        }
        catch (error) {

        }
    }
    deleteClosingHandoverEntry = async (denominationid) => {
        try {
            const status = await apiAgent.posMaster.deleteClosingHandoverEntry(denominationid);

            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    getClosingHandOverUpdateDetail = async (denomination) => {
        try {
            const counterOpeningEntryDetail = await apiAgent.posMaster.getClosingHandOverUpdateDetail(denomination);

            return counterOpeningEntryDetail;
        }
        catch (error) {
            console.log(error)
        }
    }
    getInvoiceDetails = async (posid?: number) => {
        try {
            const invoiceDetails = await apiAgent.posMaster.getInvoiceDetails(posid);

            return invoiceDetails
        } catch (error) {
            console.log(error)
        }
    }
    getPosBatchStock = async (body) => {
        try {
            const posBatchStocks = await apiAgent.posMaster.getPosBatchStock(body);
            return JSON.parse(posBatchStocks)
        } catch (error) {
            console.log(error)
        }
    }
    loadPOSProductList = async (outletid) => {
        try {
            const productList = await apiAgent.posMaster.loadPOSProductList(outletid);
            runInAction(() => {
                this.loadPOSProducts = JSON.parse(productList);
            });

            return JSON.parse(productList);
        } catch (error) {
            console.log(error)
        }
    }
    loadItemListJson = async (outletid) => {
        try {
            const productList = await apiAgent.posMaster.GETItemListJson(outletid);
            runInAction(() => {
                this.loadPOSProducts = JSON.parse(productList);
            });

            return JSON.parse(productList);
        } catch (error) {
            console.log(error)
        }
    }
    getPosItemMaster = async () => {
        try {
            const getposItem = await apiAgent.posMaster.getPosItemMaster();
            runInAction(() => {
                this.posItemMaster = getposItem;
            });
            return getposItem;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPosItemOffers = async (membertype) => {
        try {
            const getItemOffers = await apiAgent.posMaster.getPosItemOffers(membertype);
            runInAction(() => {
                this.getItemOffers = JSON.parse(getItemOffers);
            });
            return JSON.parse(getItemOffers);
        }
        catch (error) {
            console.log(error);
        }
    }
    getPosFreeItems = async () => {
        try {
            const getFreeItems = await apiAgent.posMaster.getPosFreeItems();
            runInAction(() => {
                this.getFreeItems = JSON.parse(getFreeItems);
            });
            return JSON.parse(getFreeItems);
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default PosMasterStore;