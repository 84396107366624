import React, { useState, useEffect, useRef, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { IItemRequest, any, any } from './model';
import { QRCode } from 'react-qrcode-logo';
// import outwardoutletValidation from './validation';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import { ILoosedConversion, ILoosedConversionDetails } from './model';
import { AddValidation, saveValidation } from './validation';
import { removeDuplicates } from '../../common/shared/utils';


const LoosedConversion = observer((): JSX.Element => {

    const { outwardtoOutletStore, commonStore, warehouseStores } = useStores();

    const { loadItemMaster, loadWarehouseStockJSON, loadItemMasters, } = commonStore;

    const [loosedConversion, setLoosedConversion] = useState<ILoosedConversion>(new ILoosedConversion())
    const [loosedConversionDetail, setLoosedConversionDetail] = useState<ILoosedConversionDetails>(new ILoosedConversionDetails())
    const [warehouseStockJSON, setWarehouseStockJSON] = useState<any[]>([])

    // console.log(warehouseStockJSON, 'warehouseStockJSON>>')
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>({});
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [isSpinner, setSpinner] = useState(false);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "entryDate" && value != null) {
            setLoosedConversion({ ...loosedConversion, [name]: value });
        } else if ((name === "qty" || name === "convertQty") && value != null) {
            setLoosedConversionDetail({ ...loosedConversionDetail, [name]: Number(value) });
        } else if (name === "convertBarcode" && value != null) {
            setLoosedConversionDetail({ ...loosedConversionDetail, convertBarcode: value })
        } else if (name === "convertBatchno" && value != null) {
            setLoosedConversionDetail({ ...loosedConversionDetail, convertBatchno: value })
        }
    }

    const handleAutoComplete = (name, val) => {
        if (name === "convertedBy" && val != null) {
            setLoosedConversion({ ...loosedConversion, convertedby: val?.employeeId })
        } else if (name === "itemName" && val != null) {
            setLoosedConversionDetail({
                ...loosedConversionDetail, itemid: val?.itemid, itemname: val?.itemname, brandType: val?.BrandType, uom: val?.ConvUOM,
                batchQty: val?.Batchqty
            })
        } else if (name === "barcode" && val != null) {
            setLoosedConversionDetail({ ...loosedConversionDetail, barcode: val?.Barcode })
        } else if (name === "batchNo" && val != null) {
            setLoosedConversionDetail({ ...loosedConversionDetail, batchNo: val?.batchno })
        } else if (name === "convertedItem" && val != null) {
            setLoosedConversionDetail({ ...loosedConversionDetail, convertedItem: val?.itemid, convertedItemName: val?.itemName, convertedUom: val?.itemUOM })
        } else if (name === "convertBarcode" && val != null) {
            setLoosedConversionDetail({ ...loosedConversionDetail, convertBarcode: val?.Barcode })
        } else if (name === "convertBatchno" && val != null) {
            setLoosedConversionDetail({ ...loosedConversionDetail, convertBatchno: val?.batchno })
        }
    }

    async function fetchOultetItems() {
        const [entryNo, warehouseStock] = await Promise.all([
            outwardtoOutletStore.getEntryNo('LooseConversion'),
            loadWarehouseStockJSON(), outwardtoOutletStore.getLoadEmployess(),
            loadItemMaster()
        ]);
        setWarehouseStockJSON([...warehouseStockJSON, ...warehouseStock])
        setLoosedConversion({ ...loosedConversion, entryNo: entryNo?.entryNo + 1 })
        setLoading(false);
    }

    async function addOutwardDetails(e) {
         debugger
        e.preventDefault();
        let error: any = {};
        error = AddValidation(loosedConversionDetail);
        setErrors(error);
        if (error && Object?.keys(error)?.length === 0) {
            loosedConversionDetail.entryid = loosedConversion.entryNo;
            if (!loosedConversion?.looseConversiondetails?.length) {
                loosedConversion.looseConversiondetails = [];
            }
            loosedConversion?.looseConversiondetails?.push(loosedConversionDetail)
            setLoosedConversion(loosedConversion)
        }
    }

    async function saveOutwardOutlet(e) {

        setLoading(true);
        e.preventDefault();

        let error: any = {};
        error = saveValidation(loosedConversion);
        setErrors(error)

        if (Object?.keys(error)?.length === 0) {
            loosedConversion.wid = 0;
            const status = await warehouseStores.saveLoosedConversion(loosedConversion);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        } else {
            setLoading(false);
        }

    }


    const deleteOutwardMaster = async (outwardId) => {
        setLoading(true);
        const status = await warehouseStores.deleteOutwardOutletMaster(outwardId);
    
        if (status === 'Success') {
            fetchOultetItems();
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }

    const handleCancel = () => {
        navigate('/Admin/LoosedConversion/LoosedConversionDetail');
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        navigate('/Admin/LoosedConversion/LoosedConversionDetail');
    }

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            fetchOultetItems();
            isInitialRender.current = false;
        }
        return () => { };
    }, [])

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container'>
                            <div className='vertical-space-20'></div>
                            <div className='outletInputField inputFormBox LgInputField'>
                                <div className='hrBox'>
                                    <h3>Loosed Conversion</h3>
                                </div>
                                <div className='inputBoxLists'>
                                    <div className='row'>
                                        {isSpinner &&
                                            <div className='SpinnerBox'>
                                                <Spinner animation="grow" size="sm" />
                                            </div>}
                                        <div className='col-md-2'>
                                            <div className='inputBox'>
                                                <label>Entry No <span>*</span></label>
                                                <input type="text" className=''
                                                    value={loosedConversion?.entryNo}
                                                    name='outwardId' disabled
                                                    style={{ width: "100%" }} placeholder='Entry No..'></input>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className='inputBox'>
                                                <label>Date <span>*</span></label>
                                                <input type="date" style={{ width: "100%" }}
                                                    value={loosedConversion.entryDate}
                                                    name='entryDate' onChange={handleInputChange}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                            {errors?.entryDate && <p style={{ color: 'red' }}>{errors?.entryDate}</p>}
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='inputBox'>
                                                <label>Converted by </label>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={outwardtoOutletStore.loadEmployess}
                                                    getOptionLabel={(option: any) => option.employeeName}
                                                    onChange={(event, val) => handleAutoComplete('convertedBy', val)}
                                                    renderInput={(params: any) =>
                                                        <TextField  {...params} style={{ width: '29ch' }}
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder='Select Converted By..'
                                                            name='convertedBy'
                                                        />}
                                                />

                                            </div>
                                            <div className='vertical-space-10'></div>
                                            {errors?.entryDate && <p style={{ color: 'red' }}>{errors?.entryDate}</p>}
                                        </div>

                                        <div className='vertical-space-10'></div>

                                        <div className='col-sm-2'>
                                            <div className='inputBox'>
                                                <label>Item Name <span>*</span></label>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={removeDuplicates(warehouseStockJSON, 'itemid')}
                                                    getOptionLabel={(option: any) => option.itemname}
                                                    onChange={(event, val) => handleAutoComplete('itemName', val)}
                                                    renderInput={(params: any) =>
                                                        <TextField  {...params}
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder='Select Item Name..'
                                                            name='itemName'
                                                        />}
                                                />
                                                {errors?.itemid && <p style={{ color: 'red' }}>{errors?.itemid}</p>}
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>
                                        {loosedConversionDetail?.brandType != 'Own Brand' && <div className="col-md-2">
                                            <div className='inputBox'>
                                                <label>Barcode <span>*</span> </label>
                                                <AutoComplete placeholder="Select Barcode"
                                                    emitOption={(val) => handleAutoComplete('barcode', val)}
                                                    options={removeDuplicates(warehouseStockJSON?.filter((obj) => obj?.itemid === loosedConversionDetail?.itemid), 'Barcode')?.map(each => {
                                                        if (each?.Barcode === "") {
                                                            each.Barcode = 'None'
                                                        }
                                                        return each;
                                                    })!}
                                                    getOptionLabel='Barcode'
                                                    clsName='full-width'
                                                />
                                            </div>
                                            <div className='vertical-space-10'></div>
                                            {errors?.barcode && <p style={{ color: 'red' }}>{errors?.barcode}</p>}
                                        </div>}
                                        <div className='col-md-2'>
                                            <div className='inputBox'>
                                                <label>Batch No <span>*</span></label>
                                                <AutoComplete placeholder="Select Batch No"
                                                    emitOption={(val) => handleAutoComplete('batchNo', val)}
                                                    options={(loosedConversionDetail?.brandType !== 'Own Brand' && loosedConversionDetail?.barcode) ?
                                                        removeDuplicates(warehouseStockJSON?.filter((obj) => obj?.Barcode === loosedConversionDetail?.barcode), 'batchno') :
                                                        removeDuplicates(warehouseStockJSON?.filter((obj) => obj?.itemid === loosedConversionDetail?.itemid), 'batchno')}
                                                    getOptionLabel='batchno'
                                                    clsName='full-width'
                                                />
                                            </div>
                                            <div className='vertical-space-10'></div>
                                            {errors?.batchNo && <p style={{ color: 'red' }}>{errors?.batchNo}</p>}
                                        </div>
                                        <div className='col-md-2'>
                                            <div className='inputBox'>
                                                <label>Batch Qty <span>*</span> </label>
                                                <input type="text" className='fullInput' name='batchQty'
                                                    value={loosedConversionDetail?.batchQty} disabled
                                                    style={{ width: "100%" }} placeholder='Item Qty..'></input>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className='inputBox'>
                                                <label>Qty <span>*</span> </label>
                                                <input type="number" className='fullInput' name='qty'
                                                    value={loosedConversionDetail?.qty} onChange={handleInputChange}
                                                    style={{ width: "100%" }} placeholder='Item Qty..'></input>
                                                {errors?.qty && <p style={{ color: 'red' }}>{errors?.qty}</p>}
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className='inputBox'>
                                                <label>Stock UOM </label>
                                                <input type="text" className='fullInput' name='batchQty'
                                                    value={loosedConversionDetail?.uom} disabled
                                                    style={{ width: "100%" }} placeholder='UOM..'></input>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>

                                        <div className='col-sm-2' >
                                            <div className='inputBox'>
                                                <label>Converted Item <span>*</span></label>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={loadItemMasters}
                                                    getOptionLabel={(option: any) => option.itemName}
                                                    onChange={(event, val) => handleAutoComplete('convertedItem', val)}
                                                    renderInput={(params: any) =>
                                                        <TextField  {...params} style={{ width: '23ch' }}
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder='Select Item Name..'
                                                            name='itemName'
                                                        />}
                                                />
                                                {errors?.convertedItem && <p style={{ color: 'red' }}>{errors?.convertedItem}</p>}
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>
                                         <div className="col-md-2">
                                            <div className='inputBox'>
                                                <label>Barcode </label>
                                                <input type="text" className='fullInput' name='convertBarcode'
                                                    value={loosedConversionDetail?.convertBarcode} onChange={handleInputChange}
                                                    style={{ width: "100%" }} placeholder='Enter Barcode..'></input>
                                            </div>
                                            {errors?.convertBarcode && <p style={{ color: 'red' }}>{errors?.convertBarcode}</p>}
                                            <div className='vertical-space-10'></div>
                                        </div>  
                                        <div className='col-md-2'>
                                            <div className='inputBox'>
                                                <label>Batch No <span>*</span></label>
                                                <input type="text" className='fullInput' name='convertBatchno'
                                                    value={loosedConversionDetail?.convertBatchno} onChange={handleInputChange}
                                                    style={{ width: "100%" }} placeholder='Enter Batch No..'></input>
                                            </div>
                                            {errors?.convertBatchno && <p style={{ color: 'red' }}>{errors?.convertBatchno}</p>}
                                            <div className='vertical-space-10'></div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className='inputBox'>
                                                <label>Converted Qty <span>*</span></label>
                                                <input type="number" className='fullInput' name='convertQty'
                                                    value={loosedConversionDetail?.convertQty}
                                                    onChange={handleInputChange}
                                                    style={{ width: "100%" }} placeholder='Conveted UOM..'></input>
                                                {errors?.convertQty && <p style={{ color: 'red' }}>{errors?.convertQty}</p>}
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className='inputBox'>
                                                <label>Converted UOM <span>*</span></label>
                                                <input type="text" className='fullInput' name='batchQty'
                                                    value={loosedConversionDetail?.convertedUom} disabled
                                                    style={{ width: "100%" }} placeholder='UOM..'></input>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>

                                        <div className='col-sm-2'>
                                            <button className='dfBtn' onClick={addOutwardDetails}>ADD</button>
                                        </div>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                    <div className='tableBox'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S.No</th>
                                                    <th scope="col">Item</th>
                                                    <th scope="col">Barcode</th>
                                                    <th scope="col">Batch No</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">UOM</th>
                                                    <th scope="col">Converted Item</th>
                                                    <th scope="col">Barcode</th>
                                                    <th scope="col">Batch No</th>
                                                    <th scope="col">Converted qty</th>
                                                    <th scope="col">UOM</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ verticalAlign: 'middle' }}>
                                                {loosedConversion?.looseConversiondetails?.map((val, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{val.itemname}</td>
                                                            <td>{val.barcode!}</td>
                                                            <td>{val.batchNo!}</td>
                                                            <td>{val.qty!}</td>
                                                            <td>{val.uom}</td>
                                                            <td>{val.convertedItemName}</td>
                                                            <td>{val.convertBarcode}</td> 
                                                            <td>{val.convertBatchno}</td>
                                                            <td>{val.convertQty}</td>
                                                            <td>{val.convertedUom}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={handleCancel} >List</button>
                                    <button className='dfBtn' type='submit' onClick={saveOutwardOutlet}>Submit</button>
                                </div>
                            </div>

                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../gsmecom/images/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../gsmecom/images/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    )
});
export default LoosedConversion;
