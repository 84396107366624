import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
// import { ISalesRegister } from '../model';

class SalesReturnRegisterStore {
    @observable SalesReturnRegisterList = new Map();
    @observable outletItemDetails = new Map();
    entryNo = new Map();

    getSalesReturnRegister = async (posid: any, flag) => {
        try {
            const inwardData = await apiAgent.posMaster.getSalesReturnRegister(posid);

            runInAction(() => {
                this.SalesReturnRegisterList = inwardData;
            });
            return this.SalesReturnRegisterList;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadRtnItems = async (salesRegisterRequest: any) => {
        console.log(salesRegisterRequest, "salesRegisterRequestvalueee");
        try {
            const data = await apiAgent.salesRegister.loadRtnItems(salesRegisterRequest);
            runInAction(() => {
                this.outletItemDetails = data;
            });
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error, "errrlorvaluee");
        }
    }


}

export default SalesReturnRegisterStore;