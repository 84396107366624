import { action, set, makeAutoObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';

class PurchaseReturnRegisterStore {
    @observable purchaseItemDetailsExport= new Map();  
    constructor() {
        makeAutoObservable(this);
    }

    PurchaseRtnRegDetails = async (salesRegisterRequestExport) => {
        try {
            const data = await apiAgent.purchaseBillReg.purchaseretunrExport(salesRegisterRequestExport);
            return  JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }
  }
export default PurchaseReturnRegisterStore;

 