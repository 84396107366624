import { action, set, makeObservable, observable, runInAction } from 'mobx';

import apiAgent from '../../../APIAgent';

class SalesReplaceRetrunStore {

    @observable entryNo = new Map();
    @observable posIds = new Map();
    @observable invoiceIds = new Map();
    @observable loadPackingDistributionItems = new Map()
    @observable posItems = new Map();
    @observable posItemBatch = new Map();
    @observable replacementBatchItems = new Map();
    @observable returnreplaceDetails = new Map();
    @observable loadInvoiceItems = new Map();
    @observable loadProductionDetails = new Map();
    @observable loadReturnWastageDetails = new Map();
    @observable loadPackingPlanItems = new Map();
    // returnreplaceDetails = new Map();
    @observable loadProductionList
    constructor() {
        makeObservable(this);
    }
    loadPosIds = async () => {
        try {
            const posids = await apiAgent.retrunReplace.loadPosIds();

            runInAction(() => {
                this.posIds = posids;
            })
            return this.posIds;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadInvoiceIds = async () => {
        try {
            const invoiceIds = await apiAgent.retrunReplace.loadInvoiceIds();

            runInAction(() => {
                this.invoiceIds = invoiceIds;
            })
            return this.invoiceIds;
        }
        catch (error) {
            console.log(error);
        }
    }    
    newItemRequest = async (newItemReq) => {
        try {
            const status = await apiAgent.retrunReplace.newItemRequest(newItemReq);
            return status
        }
        catch (error) {
            console.log(error);
        }
    }
    loadNewItemRequest = async () => {
        try {
            const newItems = await apiAgent.retrunReplace.loadNewItemRequest();
            return JSON.parse(newItems);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadPackingDistributionItem = async () => {
        try {
            const stocks = await apiAgent.retrunReplace.loadPackingDistributionItems();
            runInAction(() => {
                this.loadPackingDistributionItems = JSON.parse(stocks);
            });
            return this.loadPackingDistributionItems;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadPOSItems = async (posid) => {
        try {
            const items = await apiAgent.retrunReplace.loadPOSItems(posid);

            runInAction(() => {
                this.posItems = items;
            })
            return items;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadPOSItemBatch = async (posItemRequest) => {
        try {
            const batchs = await apiAgent.retrunReplace.loadPOSItemBatch(posItemRequest);

            runInAction(() => {
                this.posItemBatch = batchs;
            })
            return this.posItemBatch;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadReplacementItemBatch = async (itemId) => {
        try {
            const replacementBatchs = await apiAgent.retrunReplace.loadReplacementItemBatch(itemId);

            runInAction(() => {
                this.replacementBatchItems = replacementBatchs;
            })
            return this.replacementBatchItems;
        }
        catch (error) {
            console.log(error);
        }
    }
    saveSalesReplacement = async (replacereturn) => {
        try {
            const status = await apiAgent.retrunReplace.saveSalesReplacement(replacereturn);

            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getSalesRetrunDetails = async (id) => {
        try {
            const returnreplace = await apiAgent.retrunReplace.getSalesRetrunDetails(id);

            runInAction(() => {
                this.returnreplaceDetails = JSON.parse(returnreplace);
            })
            return this.returnreplaceDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteSalesRetrunDetails = async (rid) => {
        try {
            const status = await apiAgent.retrunReplace.deleteSalesRetrunDetails(rid);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }


    getSalesRetrunDetailsSearch = async (posId) => {
        try {
            const returnreplace = await apiAgent.retrunReplace.getSalesRetrunDetailsSearch(posId);

            runInAction(() => {
                this.returnreplaceDetails = returnreplace;
            })
            return this.returnreplaceDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    saveProductEntrySave = async (body) => {

        try {
            const returnreplace = await apiAgent.retrunReplace.saveProductEntrySave(body);

            runInAction(() => {
                this.returnreplaceDetails = returnreplace;
            })
            return this.returnreplaceDetails;
        }
        catch (error) {
            console.log(error);
        }
    }

    
    saveGrindProductEntrySave = async (body) => {

        try {
            const returnreplace = await apiAgent.retrunReplace.saveGrindProductEntrySave(body);

            runInAction(() => {
                this.returnreplaceDetails = returnreplace;
            })
            return this.returnreplaceDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getInvoceItems = async (InvoiceId) => {
        try {
            const invoiceItems = await apiAgent.retrunReplace.getInvoiceItems(InvoiceId);
            runInAction(() => {
                this.loadInvoiceItems = invoiceItems;
            });
            return invoiceItems;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadPackingPlanItem = async () => {
        try {
            const packingPlanItems = await apiAgent.retrunReplace.loadPackingPlanItems();
            runInAction(() => {
                this.loadPackingPlanItems = JSON.parse(packingPlanItems);
            });
            return JSON.parse(packingPlanItems)
        } catch (error) {
            console.log(error)
        }
    }
    getProductionEntryList = async () => {
        try {
            const productionEntryList = await apiAgent.retrunReplace.getProductionEntryList();
            runInAction(() => {
                this.loadProductionList = JSON.parse(productionEntryList);
            });
            return JSON.parse(productionEntryList)
        } catch (error) {
            console.log(error)
        }
    }

    getgrindingEntryList = async (mode,pid) => {
        try {
            const productionEntryList = await apiAgent.retrunReplace.getgrindEntryList(mode,pid);
            runInAction(() => {
                this.loadProductionList = JSON.parse(productionEntryList);
            });
            return JSON.parse(productionEntryList)
        } catch (error) {
            console.log(error)
        }
    }
    deletegrindingMaster = async (pid) => {
        try {
            const status = await apiAgent.retrunReplace.deletegrindingMaster(pid);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    

    getProductionEntryDetails = async (prdcEtyBody) => {
        try {
            const loadProductionEntryDetails = await apiAgent.retrunReplace.getProductionEntryDetails(prdcEtyBody);
            runInAction(() => {
                if (prdcEtyBody?.mode === 1) {
                    this.loadProductionDetails = JSON.parse(loadProductionEntryDetails);
                } else {
                    this.loadReturnWastageDetails = JSON.parse(loadProductionEntryDetails);
                }
            });
            return prdcEtyBody?.mode === 1 ? JSON.parse(loadProductionEntryDetails) : JSON.parse(loadProductionEntryDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
    
 
}


export default SalesReplaceRetrunStore;