import { observable } from "mobx";
import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export interface ISalesRtnRegister {
    posid?: number;
    billno?: string;
    billdate?: string;
    employeeId?: number;
    employeename?: string;
    outletName?: string;
    billvalue?: number;
    netvalue?: number;
    nos?: string;
}
export interface ISalesRtnRegisterDetails {
    posdid?: number;
    posid?: number;
    itemid?: number;
    itemname?: string;
    qty?: number;
    rate?: number;
}
export class ISalesRtnRegisterRequest {
    startDate?: string = getCurrentMonthFirstDate()?.firstDate;
    endDate?: string = getCurrentMonthFirstDate()?.currentDate;
    outletId?: number = 0;
}
export const salesRtnRegisterDetails = observable<ISalesRtnRegister>({
    posid: 0,
    billno: "",
    billdate: "",
    employeeId: 0,
    employeename: "",
    outletName: "",
    billvalue: 0,
    netvalue: 0,
    nos: "",
});


export default salesRtnRegisterDetails