import moment from "moment";

export class IDirectInward {
    
    inwardNo?: number;
    inwardDate?: string;
    Supplierid?: number;
    itemWarehouse?: string;
    billno?: string;
    billDate?: string;
    itemId?: number;
    itemName?: string;
    itemQuantity?: number;
    freeQuantiity?: string;
    itemRate?: number;
    totalValue?: number | undefined;
    itemvalue?: any;
    discountPercentage?: number;
    discountValue?: number;
    gstLedger?: string;
    gstPercentage?: string;
    gstValue?: number;
    warehouseId?: number;
    inwardDetailList?: IDirectInwardDetails[];
    Ntotalvalue?: number;
    NDiscountV?: number;
    NLedgerV?: number;
    Ngstvalue?: number;
    Netvalue?: number;
    SupplierName?: string;
    outletName?: string;
    discountType?: string;
    discount?: any;
    discountAmount?: number;
    ledgers?: IDirectInwardLedgers[];
    outletId?: number;
}
export class IDirectInwardDetails {
    inwardItemid?: number | null = 0;
    inwardItemName?: string | null = '';
    inwardItemQuantity?: number | null = 0;
    inwardFreeQuantiity?: number | null = 0;
    inwardbundleqty?: number | null = 0;
    inwardItemRate?: number | null = 0;
    inwardTotalValue?: number | null = 0;
    inwardItemvalue?: number | null = 0;
    inwardItemMrpRate?: number | null = 0;
    inwardDisPercentage?: number | null = 0;
    inwardDiscountvalue?: number | null = 0;
    inwardGstPercentage?: number | null = 0;
    inwardGstValue?: number | null = 0;
    inwardValue?: number | null = 0;
    mfg_Date?: string | null = new Date().toISOString().substr(0, 10);;
    exp_Date?: string | null = new Date().toISOString().substr(0, 10);;
    barcode?: string | null = '';
    batchNo?: string | null = '';
    gstLedger: string | null = '';
    b2BoldPrice?: number = 0
    b2ColdPrice?: number = 0
    b2BnewPrice?: number = 0
    b2CnewPrice?: number = 0
    b2Bper?: number = 0
    b2cper?: number = 0
    effdate?: string = moment().format('YYYY-MM-DD hh:mm A');
    pono?: number = 0
    expiryDays?: number = 0
}
export class IDirectInwardLedgers {
    Id?: number;
    ledgerId?: number;
    ledgerName?: string;
    ledgerAmount?: number;
    ledgerDetlId?: number;

}
export interface IBatchRequest {
    itemId?: number;
    terminalId?: number; S
}
export interface IItemInwardSearchoptions {
    searchOption?: string;
    searchValue?: string;
}



