import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt, faPrint } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import numberToWords from 'number-to-words';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';


const DamageTransferDetail = () => {

  const { damageEntryOutletStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const navigate = useNavigate()

  const [assignOrderSearchOptions, setAssignOrderSearchOptions] = useState<any>({})
  const [damageTransferDetail, setDamageTransferList] = useState<any[]>([])
  const [damageTransferItemList, setDamageTransferItemList] = useState<any[]>([])

  const [isLoading, setLoading] = useState(true);
  const [isDetail, setDetail] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleCreate = () => {
    navigate('/Admin/DamageTransfer');
  }

  async function loadDetails(id) {
    const damageEntryDetail = await damageEntryOutletStore.loadDamageTransferDetails(id)
    setDamageTransferItemList([])
    setDamageTransferItemList([...damageEntryDetail])
    setDetail(true)
  }

  const isInitialRender = useRef(true);

  async function fetchPreLoadingData() {
    const userRights = await userCreationStore?.getUserRight('Damage_transfter')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    const damageEntryDetail = await damageEntryOutletStore.loadDamageTransferDetails(0)
    setDamageTransferList([...damageEntryDetail])
    setLoading(false)
  }

  useEffect(() => {
    if (isInitialRender.current) {
      fetchPreLoadingData();
      isInitialRender.current = false;
    }
    return () => { };
  }, [])

  const objectRef = useRef<any>({});

  async function handlePrint(val: any) {
    // let getOutletDCData;
    const getOutletDCData = await damageEntryOutletStore.damageTransfterPrint(val?.damage_entry_master_id);
    objectRef.current = getOutletDCData;
    let logo = require('../../../../image/Ganga_Supermarket-1.png')
    const printWindow = window.open("", "_blank");

    console.log(getOutletDCData, 'getOutletDCData>>')
    if (printWindow) {
      printWindow.document.write(
        `
        <html>
          <head>
           <title style="text-align: center ; padding-right: 100px;"  class="centered-div">. </title>
            <style>
              /* Add any additional styles here */
              .table {
                /* Add table styles here */
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
              }
              th, td {
                /* Add th and td styles here */
                // border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
              }
              th {
                /* Add th styles here */
                background-color: #f2f2f2;
              }
              body {
                margin: 0;
                height: 100vh;
                // display: flex;
                // justify-content: center;
                // align-items: center;
              }
              .container {
               display:flex;
              }
          
              .centered-div {
                text-align: center;
              }
              
              .right-div {
  
              }
              .border-top {
                border-top: 1px solid #ddd; /* Add top border to the element with this class */
            }
            .border-bottom {
                border-bottom: 1px solid #ddd; /* Add bottom border to the element with this class */
            }
            .border-right {
              border-right: 1px solid #ddd; /* Add top border to the element with this class */
          }
          .border-left {
              border-left: 1px solid #ddd; /* Add bottom border to the element with this class */
          }
          .border{
            /* border: 1px solid #ddd;*/
          }
          .content {
            padding: 20px;
            page-break-before: always; /* Ensure Content 1 starts on a new page */
        }
        @page {
          size: auto;
          margin: 0mm;
          border: 2px solid #ddd;
      }
            </style>
          </head>
          <body >
          <div class = "border content">
          <!-- <div class="container"> -->
          <div style="height: 25px;"></div>
          <div  class="centered-div">Delivery Challan(Original)</div>
          <div style="height: 25px;"></div>
          <!-- <div  class="right-div">(Original)</div>
          </div> -->
            <table class="table table-striped">
              <thead>
               <tr>
                <td colspan="2" class="border-right border-top border-left border-bottom pad-left">
                    <div style="display: flex; align-items: center; justify-content: space-evenly;">
                        <img src=`+ logo + ` width="260" height="170" />
                    </div>
                </td>
                <td colspan="2" class="border-right border-top border-bottom pad-left">
                <div style="display: flex; align-items: center; justify-content: space-evenly;">
                    <div style="text-align: center;">
                        <div style="font-weight: 800; font-size: x-large;">Ganga Super Market</div>
                        <div>3/824 A, Thuraiyur Road, Near Ponni Gas Godown</div>
                        <div>Namakkal - 637002, Tamil Nadu</div>
                        <div>GSTIN/UIN: 33BZUPS6837H1ZL</div>
                        <div>FSSAI No: 10020042007183</div>
                        <div>Contact No: `+ getOutletDCData[0]?.contactno + `</div>
                        <div>Email: gsmsupermarket@gmail.com</div>
                    </div>
                </div>
            </td>
            </tr>
                <tr >
                  <th class="border-bottom border-top border-left" scope="col">Ganga Super Market</th>
                  <th class="border-bottom border-top  border-right border-left" scope="col">DC No</th>
                  <th class="border-bottom border-top  border-right" scope="col">Date</th>
                </tr>
              </thead>` +
        `
            <thead style="padding-bottom: 10px;">
            <tr>
              <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        ` <div>` + getOutletDCData[0]?.Warename + "," + ` </div> <div>` + getOutletDCData[0]?.doorno + "," + getOutletDCData[0]?.area + "," + getOutletDCData[0]?.city + "," + getOutletDCData[0]?.pincode + `</div>`


        + `</th>
              <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        val?.damage_entry_master_id +
        `</th>
              <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.damage_entry_date).format("DD-MMM-YYYY") +
        `</th>
            </tr>
          </thead>
          <thead style="margin-bottom: 20px;">
            <tr>
              <th class="border-bottom border-top border-left" scope="col">Despatch To</th>
              <th class="border-bottom border-top border-left border-right" scope="col">Request No</th>
              <th class="border-bottom border-top border-right" scope="col">Date</th>
            </tr>
          </thead>
          ` +
        `
          <thead>
          <tr>
          <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">`
        +
        ` <div>` + getOutletDCData[0]?.Outletname + "," + ` </div> <div>` + getOutletDCData[0]?.outdoorno + "," + getOutletDCData[0]?.outarea + "," + getOutletDCData[0]?.outcity + "," + getOutletDCData[0]?.outpincode + `</div>`


        +
        `</th>          
          <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        getOutletDCData[0]?.reqno +
        `</th>
          <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.reqdate).format("DD-MMM-YYYY") +
        `</th>
          </tr>
        </thead>
        </table>
        
        <table  class="border-top" style="width: 100%">
            <thead>
              <tr class="">
                <th  class="border-bottom border-left " scope="col">S.No</th>
                <th class="border-left border-right border-bottom " scope="col">Item Description</th>
                <th class=" border-right border-bottom " scope="col">UOM</th>
                <th class=" border-right border-bottom " scope="col">Qty</th>
                <th class=" border-right border-bottom " scope="col">Amount</th>
                <th class="border-bottom border-right" scope="col" style="padding-top: 25px;padding-left: 30px;">Value</th>
                </tr>          
            </thead>
            <thead>
              <tr class="border-bottom">`+
        `<tbody class="border-bottom border-left border-right">
                    ${getOutletDCData.map((each, index) => `
                      <tr class="">
                        <td class="  border-bottom border-left">${index + 1}</td>
                        <td class=" border-left border-right  border-bottom">${each?.itemname}</td>
                        <td class=" border-bottom border-right">${each?.Unitname}</td>
                        <td class=" border-bottom border-right">${each?.damage_stk}</td>
                        <td class=" border-bottom border-right">${each?.MRP}</td>
                        <td class=" border-bottom border-right" style="padding-top: 8px; padding-left: 30px; font-weight: 300;">${each?.damage_stk * each?.MRP}</td>
                      </tr>
                    `).join('')}
                </tbody>
              </tr>         
            </thead>
              <thead>
                <tr class="border-bottom border-left border-right">
                  <th class="border-bottom border-left" scope="col"></th>
                  <th class="border-bottom" scope="col"></th>
                  <th class="border-bottom" scope="col"></th>
                  <th class="border-bottom border-left" scope="col">Total</th>
                  <th class="border-bottom border-left border-right" scope="col"></th>
                  <th class="border-bottom border-right" scope="col">`+ getOutletDCData?.reduce((a, v) => a = a + Number(v?.amount), 0) + `</th>
                </tr>        
              </thead>
            </table>
            <table style="width: 100%">
            <thead>
            <tr style="height: 50px;"/>
            <tr>
              <th scope="col">
              <div style="font-weight: 300; text-transform: capitalize;">`+ numberToWords.toWords(getOutletDCData?.reduce((a, v) => a = a + Number(v?.amount), 0)) + `</div>
              <div>Amount (In words)</div>
              </th>
            </tr>
          </thead>
          <div style="height: 30px;"></div>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col" style="text-align: right;"> For Ganga Super Market</th>
            </tr>        
          </thead>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col" style="text-align: right;">  Authorised Signatory</th>
            </tr>       
          </thead>
          </table>
            <div style="height: 50px;"></div>
            <div class="centered-div">This is a Computer generated Document</div>
           </div>         
          </body>
        </html>
      `
      );
     

      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("Unable to open print window.");
    }
  };

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (damageTransferDetail?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(damageTransferDetail)
    }
  }, [damageTransferDetail])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(damageTransferDetail?.slice()?.sort((a, b) => b.damage_entry_no - a.damage_entry_no), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {isLoading ?
        <div className='SpinnerBox'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> :
        <div className='outletInputField inputFormBox LgInputField'>
          <div className='inputBoxLists'>
            <div className='ItemInwardInputBox'>
              <div className='container-fluid'>
                <div className='vertical-space-20'></div>
                <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={damageTransferDetail}
                  searchTitles={[{ key: "createdBy", value: "Assigned By" }, { key: "Outletname", value: "Outlet Name" }]}
                  emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                <div className='vertical-space-20'></div>
                <div className='row'>
                  <div className='col-sm-5'>
                    <div className='btnBox'>
                      <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{damageTransferDetail?.length} Records</button>
                    </div>
                  </div>
                  <div className=' col-sm-7' >
                    <Stack spacing={2}>
                      <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                    </Stack>
                  </div>
                </div>
                <div className='vertical-space-20'></div>
                <div className='tableListDetails'>
                  <table className="tables table-striped">
                    <thead>
                      <tr>
                        <th scope='col'>DC No</th>
                        <th scope='col'>Date  </th>
                        <th scope='col'>Outlet Name </th>
                        <th scope='col'>Moved to</th>
                        <th scope='col'>Create </th>
                        <th scope='col'>Update </th>
                        <th scope='col'>Delete </th>
                        <th scope="col">Print </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                        (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val?.damage_entry_no}</td>
                              <td>{moment(val.damage_entry_date).format('DD-MMM-YYYY')}</td>
                              <td>{val.Outletname}</td>
                              <td>{val.movedto}</td>
                              <td><button disabled={!userRights?.view} onClick={() => loadDetails(val?.damage_entry_master_id)}>Detail</button></td>
                              <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                              <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                              <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                              <td>
                                <button disabled={!userRights?.print} className="Edit" onClick={() => { handlePrint(val); }}>
                                  <FontAwesomeIcon icon={faPrint} />
                                </button>
                              </td>
                            </tr>
                          )
                        }
                        )}
                    </tbody>
                  </table>
                </div>
                <div className='vertical-space-20'></div>
                <Modal show={isDetail} onHide={() => setDetail(false)} className='SubmitModal'>
                  <Modal.Header closeButton>
                    <h4>Details</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Item Name</th>
                            <th scope="col">Damage Entry No</th>
                            <th scope="col">Barcode</th>
                            <th scope="col">Qty</th>
                            <th scope="col">UOM</th>
                            <th scope="col">Batch No</th>
                          </tr>
                        </thead>
                        <tbody style={{ verticalAlign: 'middle' }}>
                          {damageTransferItemList?.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{val?.itemname}</td>
                                <td>{val?.damage_entry_master_id}</td>
                                <td>{val?.Barcode}</td>
                                <td>{val?.damage_stk}</td>
                                <td>{val?.Unitname}</td>
                                <td>{val?.batch_no}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default DamageTransferDetail;