import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt, faImage } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { excelKeyupdate, IExcelSheetData, IIExceluploadModal, IItemMaster, IItemSearchoptions } from './model';
import moment from 'moment';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';
import { convertExcelToArrayOfObjects } from '../../common/shared/convertExcToarrObj/covertExctoArrObj';

interface ExcelSheetData {

  itemid: Number
  Gsmcode: string
  itemname: string
  Unitname: string
  hsncode: string
  extraCode: string
  Category: string
  netWeight: string
  grossWeight: string
  rackNo: string
  MRP: Number
  purchasePrice: string
  currentStock: string
  StocksLimit: string
  Comments: string
  cessname: string
  Commission_per: string
  expiryDays: string
  taxname: string
  taxper: string
  subcategoryname: string
  brandname: string
  BrandType: string
  convunitname: string
  ConvQty: string
  puunitname: string
  minsalqty: string
  coinchange: string
}

interface ExcelObject {
  [key: string]: any;
}

const ItemMasterDetail = observer((): JSX.Element => {

  const { itemMasterStore, unitOfMeasurementStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getSupplierDetails, itemSupplierDetails, getItemDetails, importExportFormSave, getItemMasterDetails, updateItemMaster, updateItemMasterStatus, getItemMasters } = itemMasterStore;

  const [itemMasterList, setItemMasterList] = useState<any[]>([])
  const [itemDetls, setItemDetls] = useState<any[]>([])
  const [itemDetailObj, setItemDetailObj] = useState<IItemMaster>(new IItemMaster());

  const [isLoading, setLoading] = useState(true);
  const [isSupplierShow, setSupplierModal] = useState(false);
  const [isCoversionModal, setConversionDetail] = useState({ bool: false, itemId: 0 })
  const [isItemDetlsShow, setItemDetlsModal] = useState(false);
  const [isUpload, setUploadModal] = useState(false);
  const [itemStatus, setItemStatus] = useState("");
  console.log(itemStatus, "setItemStatus");
  const navigate = useNavigate();
  const [image, setImage] = useState();

  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [fileName, setImageFile] = useState();
  const [itemId, setItemId] = useState();

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [isExcel, setExcel] = useState(false)
  const [data, setData] = useState<ExcelObject[]>([]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const result = await convertExcelToArrayOfObjects(file);
        setData(result);
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
    }
  };

  const handleGenerateExcel = async () => {
    const itemMasterDetails = await getItemMasterDetails()
    createExportExcelObj(itemMasterDetails)
  }

  function excelNewSheet() {
    let excelArr = new Array<IExcelSheetData>();
    let intitalObj = new IExcelSheetData();
    excelArr.push(intitalObj)
    const csvContent = [Object.keys(excelArr[0]).join(',')].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Item Master Details ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function createExportExcelObj(itemMasterDetails: any[]) {
    let excelArr: ExcelSheetData[] = itemMasterDetails?.map((itemMaster) => {
      const excelObj: ExcelSheetData = {

        itemid: itemMaster?.itemid,
        Gsmcode: itemMaster?.Gsmcode,
        itemname: itemMaster?.itemname,
        Unitname: itemMaster?.Unitname,
        hsncode: itemMaster?.hsncode,
        extraCode: itemMaster?.extraCode,
        Category: itemMaster?.Category,
        netWeight: itemMaster?.netWeight,
        grossWeight: itemMaster?.grossWeight,
        rackNo: itemMaster?.rackNo,
        MRP: itemMaster?.MRP,
        purchasePrice: itemMaster?.purchasePrice,
        currentStock: itemMaster?.currentStock,
        StocksLimit: itemMaster?.StocksLimit,
        Comments: itemMaster?.Comments,
        cessname: itemMaster?.cessname,
        Commission_per: itemMaster?.Commission_per,
        expiryDays: itemMaster?.expiryDays,
        taxname: itemMaster?.taxname,
        taxper: itemMaster?.taxper,
        subcategoryname: itemMaster?.subcategoryname,
        brandname: itemMaster?.brandname,
        BrandType: itemMaster?.BrandType,
        convunitname: itemMaster?.convunitname,
        ConvQty: itemMaster?.ConvQty,
        puunitname: itemMaster?.puunitname,
        minsalqty: itemMaster?.minsalqty,
        coinchange: itemMaster?.coinchange,
      }
      return excelObj;
    })
    exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Item Master Details", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Item Master Details ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function handleUploadExcel() {
    let excleMaster: IIExceluploadModal = {};
    let updatedArr = excelKeyupdate(data);
    excleMaster.impsno = 1;
    excleMaster.itemmaster_import = updatedArr;
    // let stringifiedData = JSON.stringify(data, null, 2)
    console.log(excleMaster, data, 'stringifiedData>>')
    const status = await importExportFormSave(excleMaster)
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const editItemDetials = (itemId) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    navigate('/Admin/ItemMaster/' + itemId);
  }

  const deleteItemDetails = (itemId) => {

  };

  const handleCreate = () => {
    navigate('/Admin/ItemMaster');
  }

  const handleSuppliersDetails = async (itemid) => {
    await getSupplierDetails(itemid);
    setSupplierModal(true);
  }

  async function handleConversionDetails(itemId) {
    const itemDetls = await getItemDetails(itemId);
    if (itemDetls?.length) {
      setItemDetls([...itemDetls])
    }
    await unitOfMeasurementStore.getUnitOfMeasurement();
    setConversionDetail({ bool: true, itemId: itemId })
  }

  async function handleUpdateStatus(itemId: any) {
    console.log(itemId, "itemId");
    const status = await updateItemMasterStatus(itemId, itemStatus);
    if (status === "Success") {
      setSuccessModal(true)
      fetchItemMaster();
    }
  }

  const handleImage = (itemId) => {
    setItemId(itemId)
    setUploadModal(true);
  }
  const [show, setShow] = useState(false);

  const showOverlay = () => {
    setShow(true);
  };

  const hideOverlay = () => {
    setShow(false);
  };

  const handleItemDetails = async (itemid) => {
    const itemDetls = await getItemDetails(itemid);
    console.log(itemDetls, 'itemDetls>>')

    if (itemDetls) {
      itemDetls.map((res: any) => {
        setItemDetailObj(res);
        console.log(res, "responsevalue");
      })
      setItemDetls([...itemDetls])
    }
    setItemDetlsModal(true);
  }

  const handleClose = () => {
    setSupplierModal(false);
    setConversionDetail({ bool: false, itemId: 0 })
    setItemDetlsModal(false);
  }

  async function fetchItemMaster() {
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Item_master'),
      // getItemMasters()
    ])
    // if (itemMasterList?.length) {
    //   console.log(itemMasterList?.filter((each) => each?.brandType != null), 'itemMasterList>>')
    // }
    const itemDetails = await getItemMasterDetails();
    console.log(itemDetails, "ssdetail");
    setItemMasterList([...itemDetails])
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    setLoading(false);
  }

  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
    setImageFile(e.target.files[0].name);
  }

  const handleUploadModalClose = async () => {
    const uploadStatus = await itemMasterStore.uploadItemImage(image, itemId);
    fetchItemMaster();
    setUploadModal(false);
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchItemMaster();
      isCurrentPage.current = false;
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (itemMasterList && itemMasterList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(itemMasterList)
    }
  }, [itemMasterList, filteredData])

  const goToPage = (value: number) => {
    let filterArray = [...itemMasterList];
    if (isSearch) {
      filterArray = filteredData;
    }
    const currentPageList = currentPageDatas(filterArray?.slice()?.sort((a, b) => b.itemId - a.itemId), value, 12)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <div className='container-fluid'>
            <div className='vertical-space-20'></div>
            <div className='row'>
              <div className='col-md-1'></div>
              <SelectSearchCriteria
                searchEnable={(bool) => setSearch(bool)}
                searchArray={itemMasterList}
                searchTitles={[{ key: "itemname", value: "Item Name" }, { key: "unittype", value: "Unit Type" }, { key: "brandname", value: "Brand Name" }, { key: "Category", value: "Category" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)}
                buttonChildren={
                  <>
                    <button className='searchclearBtn' onClick={handleGenerateExcel}>Excel</button>
                    <button className='searchclearBtn' onClick={excelNewSheet}>Export</button>
                    <button className='searchclearBtn' onClick={() => setExcel(true)}>Import</button>
                    <button className='searchclearBtn' type='submit' style={{ width: '20ch' }}>{itemMasterList?.length} Records</button>
                  </>
                }
              />
              <div className='ItemInwardInputBox col-md-3' style={{ display: 'flex', alignItems: 'center' }}>
                <Stack spacing={2}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
            </div>
            <div className='vertical-space-20'></div>
            <div className='tableListDetails' style={{ maxHeight: '900px' }}>
              <table className="table table-striped" style={{ overflow: 'auto' }}>
                <thead>
                  <tr>
                    <th scope='col'>Item ID</th>
                    <th scope='col'>Name</th>
                    <th scope='col'>Status</th>
                    <th scope='col'>Category</th>
                    <th scope='col'>GSM Code</th>
                    <th scope='col'>HSN Code</th>
                    <th scope='col'></th>
                    <th scope='col'></th>
                    <th></th>
                    <th></th>
                    <th scope='col'>Create</th>
                    <th scope='col'>Update</th>
                    <th scope='col'>Delete</th>
                    <th scope='col'></th>
                  </tr>
                </thead>
                <tbody>
                  {(currentPageData)?.length > 0 &&
                    (currentPageData)?.map((val, key) => {
                      console.log(val, "sdshgfdhds");
                      return (
                        <tr key={key}>
                          <td>{val.itemid}</td>
                          <td title={val.itemname} style={{ whiteSpace: 'nowrap', width: 'auto' }}>{val.itemname}</td>
                          <td>
                            <div className='inputBox'>
                              <select className="form-selected full-width"
                                name='status'
                                // value={itemDetails?.unittype}
                                onChange={(e) => setItemStatus(e.target.value)}
                              >
                                <option selected={val?.Activestatus === "Active"} value="Active">Active</option>
                                <option selected={val?.Activestatus === "inActive"} value="inActive">In Active</option>
                              </select>
                            </div>
                          </td>
                          <td>{val.Category}</td>
                          <td>{val.Gsmcode}</td>
                          <td>{val.hsncode}</td>
                          <td>
                            <div onMouseOver={showOverlay}
                              onMouseLeave={hideOverlay}>
                              <img alt='upload' src={`data:image/png;base64,${val.imageData}`}
                                style={{ width: '50px', height: '50px' }} />

                              {show && (
                                <Button style={{ backgroundColor: 'darkgreen' }}><FontAwesomeIcon icon={faImage} onClick={() => handleImage(val.itemId)} /></Button>
                              )}
                            </div>

                          </td>
                          <td><button disabled={!userRights?.view} className='changeBtn' onClick={() => handleItemDetails(val.itemid)}> Details</button></td>
                          <td><button disabled={!userRights?.view} className='changeBtn' onClick={() => handleSuppliersDetails(val.itemid)}>Suppliers</button></td>
                          <td><button disabled={!userRights?.view} className='changeBtn' onClick={() => handleConversionDetails(val.itemid)}>Conversions</button></td>
                          <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                          <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editItemDetials(val.itemid)} /></button></td>
                          <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteItemDetails(val.itemid)} /></button></td>
                          <td><button className='' onClick={() => handleUpdateStatus(val?.itemid)}>Update</button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>

            {
              isSupplierShow ?
                <Modal show={isSupplierShow} onHide={handleClose} className='PriceHistoryModel'>
                  <Modal.Header closeButton>
                    <Modal.Title>Supplier Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Supplier Name</th>
                            <th scope="col">Commission Percentage</th>
                            {/* <th scope='col'>Delete</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {itemSupplierDetails?.length > 0 ? itemSupplierDetails?.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{val.supplierName}</td>
                                <td>{val.commissionPercentage}</td>
                                {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteItemDetails(val.itemId)} /></button></td> */}
                              </tr>
                            )
                          }) : null
                          }
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal> : null
            }
            {
              isCoversionModal?.bool ?
                <Modal show={isCoversionModal?.bool} onHide={handleClose} className='PriceHistoryModel'>
                  <Modal.Header closeButton>
                    <Modal.Title>Coversion Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Purchase UOM</th>
                            <th scope="col">Coversion UOM</th>
                            <th scope='col'>Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemDetls?.length > 0 &&
                            itemDetls?.map((val, key) => {
                              return (val?.itemid === isCoversionModal?.itemId) ? (
                                <tr key={key}>
                                  <td>{unitOfMeasurementStore?.unitOfMeasurementList?.find((obj) => obj?.unitId === val?.purchaseuomid)?.unitName}</td>
                                  <td>{unitOfMeasurementStore?.unitOfMeasurementList?.find((obj) => obj?.unitId === val?.Convuomid)?.unitName}</td>
                                  <td>{val?.ConvQty}</td>
                                </tr>
                              ) : null
                            })

                          }
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal> : null
            }
            {
              isItemDetlsShow ?
                <Modal show={isItemDetlsShow} onHide={handleClose} className='PriceHistoryModel'>
                  <Modal.Header closeButton>
                    <Modal.Title>Item Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Item Name</th>
                            <th scope="col">Net Weight</th>
                            <th scope="col">Gross Weight</th>
                            <th scope="col">SubCategory</th>
                            <th scope="col">Brand</th>
                            <th scope='col'>Unit type</th>
                            {/* <th scope="col">MRP</th>
                            <th scope="col">Purchase Price</th> */}
                            <th scope="col">UOM</th>
                            <th scope="col">Min. Sale Qty</th>
                            <th scope="col">Coin Change</th>
                            <th scope="col">Stocks Limits</th>
                            <th scope="col">Expiry Days</th>
                            <th scope="col">Tax Name</th>
                            <th scope='col'>CESS</th>
                            <th scope="col">Comments</th>
                            {/* <th scope='col'>Delete</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {itemDetls?.map((obj, key) => {
                            return (
                              <tr key={key}>
                                <td>{obj.itemname}</td>
                                <td>{obj.netWeight}</td>
                                <td>{obj.grossWeight}</td>
                                <td>{obj.subcategoryname}</td>
                                <td>{obj.brandname}</td>
                                <td>{obj?.unittype}</td>
                                {/* <td>{obj.MRP}</td>
                                <td>{obj.purchasePrice}</td> */}
                                <td>{obj.Unitname}</td>
                                <td>{obj.minsalqty}</td>
                                <td>{obj.coinchange === 1 ? 'Yes' : 'No'}</td>
                                <td>{obj.StocksLimit}</td>
                                <td>{obj.expiryDays}</td>
                                <td>{obj?.taxname1}</td>
                                <td>{obj?.cessname1}</td>
                                <td>{obj.Comments}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal> : null
            }
            <Modal show={isUpload} onHide={handleUploadModalClose} className='PriceHistoryModel'>
              <Modal.Header closeButton>
                <Modal.Title>Item Upload Image</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='imgBox'>
                  {image ?
                    <div className='imgBox'>
                      <Image height="100px" width="100px" src={URL.createObjectURL(image)} />
                    </div> : null
                  }

                  <div className='col-sm-8'>
                    <div className='inputBox'>
                      <label>Item Image <span>*</span></label>
                      <input type="file" className='' style={{ width: "60%" }} accept='image/*'
                        name='itemImage' onChange={handleChangeImage} />
                    </div>
                  </div>

                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleUploadModalClose}>
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isExcel} onHide={() => { setExcel(false); setData([]) }} className='PriceHistoryModel'>
              <Modal.Header closeButton>
                <Modal.Title>Excel Import </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='imgBox'>
                  <div className='col-sm-8'>
                    <div className='inputBox'>
                      <label>Upload Excel <span>*</span></label>
                      <input type="file" accept=".xlsx, .xls, .csv" onChange={handleFileChange} />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleUploadExcel}>
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>
                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setSuccessModal(false)}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </div>
      }
    </>
  );

});

export default ItemMasterDetail;