
function DamageEntryWarehouseValidation(damageEntryWarehouse){
  let errors :any={};
  
    
    if(!damageEntryWarehouse.damageEntryDate){
      errors.damageEntryDate = "Enter Date";
    }
    if(!damageEntryWarehouse.warehouseId){
      errors.warehouseId = "Select Warehouse Name";
    }
    if(!damageEntryWarehouse?.damageItemId){
      errors.damageItemId = "Select Item Name";
    }
    if(!damageEntryWarehouse.damageBarcode){
      errors.damageBarcode = "Select Barcode ";
    }
    if(!damageEntryWarehouse.damageBatchNo){
      errors.damageBatchNo = "Select BatchNo ";
    }
    if(!damageEntryWarehouse.damageItemQty){
      errors.damageItemQty = "Damage Qty does not exist Stock Qty";
    }
    if(!damageEntryWarehouse.damageDescription){
      errors.damageDescription = "Enter Damage Description";
    }
  return errors;
};
  
  export default DamageEntryWarehouseValidation;