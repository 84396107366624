import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react'
import Barcode from 'react-barcode';
import useStores from '../../hooks';

function LabelGeneration() {

  const [date, setDate] = useState(true);
  const [weight, setWeight] = useState(true);
  const [mrp, setMrp] = useState(true);
  const [batch, setBatch] = useState(true);
  const [productName, setProductName] = useState(true);
  const [price, setPrice] = useState(true);

  const { commonStore } = useStores()
  const { loadOwnItemMasters } = commonStore;

  console.log(date, "datevalue");

  return (
    <div className=''>

      <div className='outletInputField inputFormBox'>
        <div className='hrBox'>
          <h3>Label Generation</h3>
        </div>
        <form autoComplete='nope'>
          <div className='inputBoxLists'>
            <div className='inputBox '>
              <label>Date<span>*</span></label>
              <input
                type="date"
                className=''
                style={{ width: "100%" }}
                autoComplete='nope'
                name="date"
              // value={outlet.outletName}
              // onChange={handleChangeInput} 
              />
              <div className='vertical-space-10' />
            </div>

            <div className='inputBox'>
              <label>Product Name<span>*</span></label>
              <input
                type="text"
                className='fullInput'
                style={{ width: "100%" }}
                placeholder='Prefix should be 3 characters'
                autoComplete='nope'
                // value={outlet.prefix}
                // onChange={handleChangeInput}
                name='productName'
              />
              {/* <div className=''>
                <AutoComplete
                  clsName='autoComplete full-width'
                  placeholder="Select Team.."
                  // value={localFields?.teamName}
                  // emitOption={(val) => handleMenuDropDowns('teamid', val)}
                  options={loadTeamDetails}
                  getOptionLabel={'teamMasterName'}
                />
                <input className="form-check-input" onClick={() => setProductName(!productName)} type="checkbox" checked={productName} id="weight" />
              </div> */}
              <div className='vertical-space-10' />
            </div>

            <div className='inputBox'>
              <label>Template</label>
              <input
                type='text'
                autoComplete='nope'
                className='fullInput'
                style={{ width: "100%" }}
                placeholder='Choose Template'
                // value={outlet.mobileNo}
                // onChange={handleChangeInput}
                name='batch' />
              <div className='vertical-space-10' />
            </div>

            <div className='inputBox'>
              <label>Barcode</label>
              <input
                type='text'
                autoComplete='nope'
                maxLength={10}
                className='fullInput'
                style={{ width: "100%" }}
                placeholder='Enter Batch no'
                // value={outlet.mobileNo}
                // onChange={handleChangeInput}
                name='batch' />
              <div className='vertical-space-10' />
            </div>

            <div className='inputBox'>
              <label>Count</label>
              <input
                type='text'
                autoComplete='nope'
                maxLength={10}
                className='fullInput'
                style={{ width: "100%" }}
                placeholder='Enter No of Count'
                // value={outlet.mobileNo}
                // onChange={handleChangeInput}
                name='batch' />
              <div className='vertical-space-10' />
            </div>

            {/* <div className='inputBox'>
              <label>Batch</label>
              <input
                type='text'
                autoComplete='nope'
                maxLength={10}
                className='fullInput'
                style={{ width: "80%" }}
                placeholder='Enter Batch no'
                // value={outlet.mobileNo}
                // onChange={handleChangeInput}
                name='batch' />
              <input className="form-check-input" onClick={() => setBatch(!batch)} type="checkbox" checked={batch} id="batch" />
              <div className='vertical-space-10' />
            </div> */}

            {/* <div className='inputBox'>
              <label>Price </label>
              <input type="text"
                className='fullInput'
                style={{ width: "80%" }}
                placeholder='Enter Price'
                autoComplete='nope'
                // value={outlet.bankAccount}
                // onChange={handleChangeInput}
                name='price' />
              <input className="form-check-input" onClick={() => setPrice(!price)} type="checkbox" checked={price} id="weight" />
              <div className='vertical-space-10' />
            </div> */}

            {/* <div className='inputBox'>
              <label>Weight <span>*</span></label>
              <input type="number"
                className='fullInput'
                style={{ width: "80%" }}
                placeholder='Enter Weight'
                autoComplete='nope'
                // value={outlet.fssainumber}
                // onChange={handleChangeInput}
                name='weight' />
              <input className="form-check-input" onClick={() => setWeight(!weight)} type="checkbox" checked={weight} id="weight" />
              <div className='vertical-space-10' />
            </div> */}

            {/* <div className='inputBox'>
                  <label >City <span>*</span></label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    // options={cityOptions}
                    getOptionLabel={(option: any) => option.name}
                    // onChange={getStateCountry}
                    // value={outlet.city}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '25ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select City'
                        name='city'
                      />}
                  />
                </div> */}


          </div>
          <div className='btnBox'>
            <button className="dfBtn" type="submit"
            // onClick={saveOutlet} disabled={!canAdd}
            // title={!canAdd ? 'You do not have permission to Add.' : 'Click to Add'}
            >
              Submit
            </button>

          </div>
        </form>
      </div >

      {/* This is for barcode UI section */}

      < div className='container' >
        <div className='row mt-5'>
          <div className='col-md-2' style={{ border: "1px solid" }}>
            {productName && <h5>Product 1</h5>}
            <Barcode value="123456789" />
            <div>
              <div className='row' style={{ position: "relative" }}>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                  {mrp && <p className='text-rotate'>MRP: Rs.Mrp1</p>}
                  <p className='mb-0 text-center'>01</p>
                  <p className='barcode-price-label text-center'>Ganga price :</p>
                  {price && <p className='barcode-price'>Rs. B2C / B2B Price</p>}
                  {date && <p className='mb-0'>Date of pack: {Date.now()}</p>}
                  {batch && <p>Batch No:</p>}
                  {weight && <p className='text-rotate2'>Weight1</p>}
                </div>
                <div className='col-sm-1' />
              </div>
            </div>
          </div>
          <div className='col-md-2' style={{ border: "1px solid" }}>
            <h5>Product 2</h5>
            <Barcode value="123456789" />
            <div>
              <div className='row' style={{ position: "relative" }}>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                  <p className='text-rotate'>MRP: Rs.Mrp1</p>
                  <p className='mb-0 text-center'>01</p>
                  <p className='barcode-price-label text-center'>Ganga price :</p>
                  <p className='barcode-price'>Rs. B2C / B2B Price</p>
                  <p className='mb-0'>Date of pack: {Date.now()}</p>
                  <p>Batch No:</p>
                  <p className='text-rotate2'>Weight1</p>
                </div>
                <div className='col-sm-1' />
              </div>
            </div>
          </div>
          <div className='col-md-2' style={{ border: "1px solid" }}>
            <h5>Product 3</h5>
            <Barcode value="123456789" />
            <div>
              <div className='row' style={{ position: "relative" }}>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                  <p className='text-rotate'>MRP: Rs.Mrp1</p>
                  <p className='mb-0 text-center'>01</p>
                  <p className='barcode-price-label text-center'>Ganga price :</p>
                  <p className='barcode-price'>Rs. B2C / B2B Price</p>
                  <p className='mb-0'>Date of pack: {Date.now()}</p>
                  <p>Batch No:</p>
                  <p className='text-rotate2'>Weight1</p>
                </div>
                <div className='col-sm-1' />
              </div>
            </div>
          </div>
          <div className='col-md-2' style={{ border: "1px solid" }}>
            <h5>Product 4</h5>
            <Barcode value="123456789" />
            <div>
              <div className='row' style={{ position: "relative" }}>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                  <p className='text-rotate'>MRP: Rs.Mrp1</p>
                  <p className='mb-0 text-center'>01</p>
                  <p className='barcode-price-label text-center'>Ganga price :</p>
                  <p className='barcode-price'>Rs. B2C / B2B Price</p>
                  <p className='mb-0'>Date of pack: {Date.now()}</p>
                  <p>Batch No:</p>
                  <p className='text-rotate2'>Weight1</p>
                </div>
                <div className='col-sm-1' />
              </div>
            </div>
          </div>
          <div className='col-md-2' style={{ border: "1px solid" }}>
            <h5>Product 5</h5>
            <Barcode value="123456789" />
            <div>
              <div className='row' style={{ position: "relative" }}>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                  <p className='text-rotate'>MRP: Rs.Mrp1</p>
                  <p className='mb-0 text-center'>01</p>
                  <p className='barcode-price-label text-center'>Ganga price :</p>
                  <p className='barcode-price'>Rs. B2C / B2B Price</p>
                  <p className='mb-0'>Date of pack: {Date.now()}</p>
                  <p>Batch No:</p>
                  <p className='text-rotate2'>Weight1</p>
                </div>
                <div className='col-sm-1' />
              </div>
            </div>
          </div>
          <div className='col-md-2' style={{ border: "1px solid" }}>
            <h5>Product 6</h5>
            <Barcode value="123456789" />
            <div>
              <div className='row' style={{ position: "relative" }}>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                  <p className='text-rotate'>MRP: Rs.Mrp1</p>
                  <p className='mb-0 text-center'>01</p>
                  <p className='barcode-price-label text-center'>Ganga price :</p>
                  <p className='barcode-price'>Rs. B2C / B2B Price</p>
                  <p className='mb-0'>Date of pack: {Date.now()}</p>
                  <p>Batch No:</p>
                  <p className='text-rotate2'>Weight1</p>
                </div>
                <div className='col-sm-1' />
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default LabelGeneration