 
export class IProductinDetails {
    pid?: number;
    proddate?: string;
    teamid?: number;
    teamname?: string;
    machineid?: number;
    machinename?: string;
    grindingentrydetails?: GrindingentryDetails[];

}

export class GrindingentryDetails {
    pid?: number;
    itemname?: string;
    packingdid: number = 0
    packingid?: number = 0 //optional
    itemid?: number;
    barcode?: string = '' // optional
    batchno?: string;
    qty?: number;
    mrp?: number;
}

