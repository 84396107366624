import React, { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IDamageEntryOutletSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';


function DamageEntryOutletDetail() {
  const { damageEntryOutletStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const { getDamageEntryOutletDetail, getDamageEntryOutletDetails } = damageEntryOutletStore;
  const [isDamageMasterShow, setDamageMasterModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const getDamageMasterDetail = async (damagentryMaterid) => {
    const data = await getDamageEntryOutletDetails(damagentryMaterid);
    if (data !== undefined || data !== null) {
      setDamageMasterModal(true);
    }
  }
  const handleCreate = () => {
    navigate('/Admin/DamageEntryOutlet');
  }

  const handleClose = () => {
    setDamageMasterModal(false);
  }
  const deleteDamageEntryOutlet = async (damageid) => {

    setLoading(true);
    const status = await damageEntryOutletStore.deleteDamageEntryOutlet(damageid);

    if (status === 'Success') {
      fetchDamageOutletMaster();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchDamageOutletMaster();
  }

  async function fetchDamageOutletMaster() {
    const userRights = await userCreationStore?.getUserRight('Damage_entry_outlet')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await damageEntryOutletStore.getDamageEntryOutlet();
    if (!damageEntryOutletStore.isTeamMasterLoading) {
      setLoading(false)
    }
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchDamageOutletMaster();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (damageEntryOutletStore?.getDamageEntryOutletList && damageEntryOutletStore?.getDamageEntryOutletList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(damageEntryOutletStore?.getDamageEntryOutletList)
    }
  }, [damageEntryOutletStore?.getDamageEntryOutletList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(damageEntryOutletStore?.getDamageEntryOutletList?.slice()?.sort((a, b) => b.damagentryMaterid - a.damagentryMaterid), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : null
      }
      <>
        <div className='container-fluid'>
          <div className='vertical-space-20'></div>
          <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={damageEntryOutletStore?.getDamageEntryOutletList}
            searchTitles={[{ key: "outletName", value: "Outlet Name" }]}
            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
          <div className='vertical-space-20'></div>
          <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className='btnBox'>
              <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{damageEntryOutletStore?.getDamageEntryOutletList?.length} Records</button>
            </div>
            <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
              <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
            </Stack>
          </div>
          <div className='vertical-space-20'></div>
          <div className='tableListDetails'>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope='col'>Entry No  </th>
                  <th scope='col'>Entry Date  </th>
                  <th scope='col'>Outlet Name  </th>
                  <th scope='col'></th>
                  <th scope='col'>Create </th>
                  <th scope='col'>Update </th>
                  <th scope='col'>Delete </th>
                </tr>
              </thead>
              <tbody>
                {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                  (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{val?.damagentryMaterid}</td>
                        <td>{val.damageEntryDate}</td>
                        <td>{val.outletName}</td>
                        <td><button disabled={!userRights?.view} onClick={() => getDamageMasterDetail(val.damagentryMaterid)}>Detail</button></td>
                        <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                        <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteDamageEntryOutlet(val.damageid)} /></button></td>
                      </tr>
                    )
                  }
                  )}

              </tbody>
            </table>
          </div>

          {
            isDamageMasterShow ?
              <Modal show={isDamageMasterShow} onHide={handleClose} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                  <Modal.Title>Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">S.NO</th>
                          <th scope="col"> Item Name</th>
                          <th scope="col"> Qty</th>
                          <th scope="col"> Batch No</th>
                          <th scope="col"> Damage Description</th>
                          {/* <th scope="col"> Delete</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          getDamageEntryOutletDetail?.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{val.damageItemName}</td>
                                <td>{val.damageItemQty}</td>
                                <td>{val.damageBatchNo}</td>
                                <td>{val.damageDescription}</td>
                                {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteDamageEntryOutletDetail(val.damagedetailid)} /></button></td> */}
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </Modal.Body>
              </Modal> : null
          }
        </div>

        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/checked.png')} />
              </div>
              <h4>Succesfully Deleted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );

};

export default DamageEntryOutletDetail;
