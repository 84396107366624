export class IProductionEntry {
    pid?: number;
    proddate?: string;
    teamid?: number;
    machineid?: number;
    productionentrydetails?: ProductionEntryDetails[]
}

export class ProductionEntryDetails {
    pid?: number;
    itemid?: number;
    batchno?: string;
    qty?: number;
    wastage?: number;
    wRemarks?: string;
    returnQty?: number;
    rremarks?: string;
    Mrprate?: number;
}

export class IProductinDetails {
    pid?: number;
    proddate?: string;
    teamid?: number;
    machineid?: number;
    productionentrydetails?: IProductionEntryDet[];
    productionwastedetails?: IProductionWastedDet[]
}

export class IProductionEntryDet {
    pid?: number;
    itemname?: string;
    packingdid: number = 0
    packingid?: number = 0 //optional
    itemid?: number;
    barcode?: string = '' // optional
    batchno?: string;
    qty?: number;
    balQty?: string = "";
    Mrprate?: number;
}

export class IProductionWastedDet {
    pid?: number = 0;
    itemid?: number = 0;
    batchno?: string = "";
    wastage?: number = 0;
    wRemarks?: string = "";
    returnQty?: number = 0;
    rremarks?: string = ""
}