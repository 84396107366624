// import { Autocomplete, TextField } from '@mui/material';
// import React, { useEffect, useRef, useState } from 'react'
// import Barcode from 'react-barcode';
// import useStores from '../../hooks';
// import AutoComplete from '../../common/shared/autoComplete';
// import { IBarcodeConfiguration } from './model';
// import { Button } from 'react-bootstrap';
// import moment from 'moment';
// import barcodeConfigurationValidation from './validation';

// function BarcodeConfiguration() {

//     const [date, setDate] = useState(true);
//     const [weight, setWeight] = useState(true);
//     const [mrp, setMrp] = useState(true);
//     const [batch, setBatch] = useState(true);
//     const [productName, setProductName] = useState(true);
//     const [price, setPrice] = useState(true);
//     const [itemName, setItemName] = useState([]);
//     const [barcodeNumber, setBarcodeNumber] = useState(0);
//     const [barcodeSection, setBarcodeSection] = useState(false);
//     const [errors, setErrors] = useState<any>({});
//     const [barcodeConfigurationDetails, setBarcodeConfigurationrDetails] = useState<IBarcodeConfiguration>({});
//     const { directInwardStore } = useStores();
//     const { loadInwardItemMaster, loadItemMasters } = directInwardStore;

//     function handleChangeInput(event: any) {
//         const { value, name } = event.target;
//         setBarcodeConfigurationrDetails({ ...barcodeConfigurationDetails, [name]: value })
//     }

//     const initialApiCall = async () => {
//         const data = await loadInwardItemMaster();
//         setItemName(data);
//     }

//     const handleGenerateBoarcode = () => {
//         let error: any = {};
//         error = barcodeConfigurationValidation(barcodeConfigurationDetails);
//         setErrors(error);
//         if (Object.keys(error).length === 0) {
//             setBarcodeSection(true)
//             setBarcodeNumber(new Date().getTime());
//         }
//     }

//     useEffect(() => {
//         initialApiCall();
//     }, [])

//     const dateFormat = moment(new Date()).format('MMMM Do YYYY');


//     return (
//         <div className='flex'>
//             <div className='outletInputField inputFormBox'>
//                 <div className='hrBox'>
//                     <h3>Barcode Configuration</h3>
//                 </div>
//                 <form autoComplete='nope'>
//                     <div className='inputBoxLists'>
//                         <div className='inputBox'>
//                             <label>Product Name<span>*</span></label>
//                             <div className='flex'>
//                                 <Autocomplete
//                                     size="small"
//                                     id="combo-box-demo"
//                                     options={itemName}
//                                     getOptionLabel={(option: any) => option?.itemName}
//                                     onChange={(event, val) => setBarcodeConfigurationrDetails({ ...barcodeConfigurationDetails, "productName": val?.itemName })}
//                                     style={{ width: "80%" }}
//                                     renderInput={(params: any) =>
//                                         <TextField  {...params}
//                                             id="outlined-size-small"
//                                             color='info'
//                                             size="small"
//                                             type="text"
//                                             placeholder='Select Ledger..'
//                                             name='productName'
//                                         />}
//                                 />
//                                 <input className="form-check-input" onClick={() => setProductName(!productName)} type="checkbox" checked={productName} id="MRP" />
//                             </div>
//                             {errors.productName && <p style={{ color: 'red' }}>{errors.productName}</p>}
//                             <div className='vertical-space-10' />
//                         </div>

//                         <div className='inputBox'>
//                             <label>Batch<span>*</span></label>
//                             <input
//                                 type='number'
//                                 autoComplete='nope'
//                                 className='fullInput'
//                                 style={{ width: "80%" }}
//                                 placeholder='Enter Batch no'
//                                 value={barcodeConfigurationDetails?.batch}
//                                 onChange={handleChangeInput}
//                                 name='batch' />
//                             <input className="form-check-input" onClick={() => setBatch(!batch)} type="checkbox" checked={batch} id="batch" />
//                             {errors.batch && <p style={{ color: 'red' }}>{errors.batch}</p>}
//                             <div className='vertical-space-10' />
//                         </div>

//                         <div className='inputBox'>
//                             <label>MRP<span>*</span></label>
//                             <input
//                                 type="number"
//                                 autoComplete="nope"
//                                 className='fullInput'
//                                 placeholder='Enter MRP'
//                                 value={barcodeConfigurationDetails?.mrp}
//                                 onChange={handleChangeInput}
//                                 name='mrp' />
//                             <input className="form-check-input" onClick={() => setMrp(!mrp)} type="checkbox" checked={mrp} id="MRP" />
//                             {errors.mrp && <p style={{ color: 'red' }}>{errors.mrp}</p>}
//                             <div className='vertical-space-10' />
//                         </div>

//                         <div className='inputBox'>
//                             <label>Ganga Price<span>*</span></label>
//                             <input
//                                 type='number'
//                                 autoComplete='nope'
//                                 className='fullInput'
//                                 placeholder='Enter Ganga price'
//                                 value={barcodeConfigurationDetails?.gangaPrice}
//                                 onChange={handleChangeInput}
//                                 name='gangaPrice' />
//                             <input className="form-check-input" onClick={() => setPrice(!price)} type="checkbox" checked={price} id="batch" />
//                             {errors.gangaPrice && <p style={{ color: 'red' }}>{errors.gangaPrice}</p>}
//                             <div className='vertical-space-10' />
//                         </div>

//                         <div className='inputBox '>
//                             <label>Date of Pack<span>*</span></label>
//                             <input
//                                 type="date"
//                                 className=''
//                                 autoComplete='nope'
//                                 name="dateOfPack"
//                                 value={barcodeConfigurationDetails?.dateOfPack ? barcodeConfigurationDetails?.dateOfPack : new Date().toISOString().substr(0, 10)}
//                                 placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"
//                                 onChange={handleChangeInput}
//                             />
//                             <input className="form-check-input" onClick={() => setDate(!date)} type="checkbox" checked={date} id="date" />
//                             {errors.dateOfPack && <p style={{ color: 'red' }}>{errors.dateOfPack}</p>}
//                             <div className='vertical-space-10' />
//                         </div>

//                         <div className='inputBox'>
//                             <label>Weight <span>*</span></label>
//                             <input
//                                 type="number"
//                                 className='fullInput'
//                                 placeholder='Enter Weight'
//                                 autoComplete='nope'
//                                 value={barcodeConfigurationDetails.weight}
//                                 onChange={handleChangeInput}
//                                 name='weight' />
//                             <input className="form-check-input" onClick={() => setWeight(!weight)} type="checkbox" checked={weight} id="weight" />
//                             {errors.weight && <p style={{ color: 'red' }}>{errors.weight}</p>}
//                             <div className='vertical-space-10' />
//                         </div>

//                         <div className='inputBox'>
//                             <Button className="" onClick={() => handleGenerateBoarcode()}>
//                                 Generate Barcode
//                             </Button>
//                             <div className='vertical-space-10' />
//                         </div>

//                         <div className='inputBox'>
//                             <label>Template Name</label>
//                             <input
//                                 type='text'
//                                 autoComplete='nope'
//                                 className='fullInput'
//                                 style={{ width: "80%" }}
//                                 placeholder='Enter Template Name'
//                                 // value={barcodeConfigurationDetails.batch}
//                                 onChange={handleChangeInput}
//                                 name='templateName'
//                             />
//                             <div className='vertical-space-10' />
//                         </div>

//                     </div>
//                     <div className='btnBox'>
//                         <button className="dfBtn" type="submit">
//                             Submit
//                         </button>
//                     </div>
//                 </form>
//             </div >

//             {barcodeSection &&
//                 <div className='outletInputField inputFormBox'>
//                     <div className='hrBox'>
//                         <h3>Generated Barcode</h3>
//                     </div>
//                     <div className='barcode-alignment' style={{ marginTop: "30px" }}>
//                         {productName && <h6 className='text-center'>{barcodeConfigurationDetails?.productName}</h6>}
//                         <div className='flex justifyContentCenter'>
//                             <Barcode value={barcodeNumber.toString()} />
//                         </div>
//                         <div>
//                             <div className='row' style={{ position: "relative" }}>
//                                 <div className='col-sm-1' />
//                                 <div className='col-sm-10'>
//                                     {mrp && <p className='text-rotate'>MRP: {barcodeConfigurationDetails?.mrp}</p>}
//                                     {/* <p className='mb-0 text-center'>01</p> */}
//                                     <p className='barcode-price-label'>Ganga price: {barcodeConfigurationDetails?.gangaPrice}</p>
//                                     {price && <p className='barcode-price'>Rs. B2C / B2B Price</p>}
//                                     {date && <p className='mb-0'>Date of pack: {barcodeConfigurationDetails.dateOfPack ? barcodeConfigurationDetails.dateOfPack : dateFormat.toLocaleString()}</p>}
//                                     {batch && <p>Batch No: {barcodeConfigurationDetails.batch}</p>}
//                                     {weight && <p className='text-rotate2'>Weight: {barcodeConfigurationDetails.weight}</p>}
//                                 </div>
//                                 <div className='col-sm-1' />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             }
//         </div >
//     )
// }

// export default BarcodeConfiguration;



/* New Code */

import React, { useState } from 'react'
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import barcodeConfigurationValidation from './validation';

function BarcodeConfiguration() {

    const [gsmCode, setGsmCode] = useState(false);
    const [extraCode, setExtraCode] = useState(false);
    const [itemName, setItemName] = useState(false);
    const [regionalItemName, setRegionalItemName] = useState(false);
    const [category, setCategory] = useState(false);
    const [hsnCode, setHsnCode] = useState(false);
    const [netWeight, setNetWeight] = useState(false);
    const [grossWeight, setGrossWeight] = useState(false);
    const [subCategory, setSubCategory] = useState(false);
    const [brand, setBrand] = useState(false);
    const [mrp, setMrp] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState(false);
    const [purchasePrice, setPurchasePrice] = useState(false);
    const [currentStock, setCurrentStock] = useState(false);
    const [unitOfMeasure, setUnitOfMeasure] = useState(false);
    const [unitType, setUnitType] = useState(false);
    const [barcode, setBarcode] = useState(false);
    const [batchNo, setBatchNo] = useState(false);
    const [file, setfile] = useState<any>();
    const [templateName, setTemplateName] = useState("");
    const navigate = useNavigate();
      const [errors, setErrors] = useState<any>({});

    // const { barcodeConfigStore } = useStores();
    // const { saveBarcodeConfiguration } = barcodeConfigStore;

    const handleChangeview = () => {
        navigate('/Admin/BarcodeConfiguration/List');
    }

    function templateNameValidation(value) {
        let error: any = {}
        if (!value?.productName) {
            error.templateNmae = 'Enter Template Name'
        }
        return error
    }

    const handleSave = async () => {
        debugger;
        const fieldNames = {
            gsmCode,
            extraCode,
            itemName,
            regionalItemName,
            category,
            hsnCode,
            netWeight,
            grossWeight,
            subCategory,
            brand,
            mrp,
            effectiveDate,
            purchasePrice,
            currentStock,
            unitOfMeasure,
            unitType,
            barcode,
            batchNo,
            templateName
        }
        let error: any = {};
        error = templateNameValidation(templateName);
        setErrors(error);

        const formData = new FormData();
        formData.append("obj",JSON.stringify(fieldNames));
        formData.append("file",file);
        
 //const res =  await fetch('http://localhost:49882/api/ItemMaster/BarcodeTemplatesave',{
  const res =  await fetch('https://api.varthagam.in/api/api/ItemMaster/BarcodeTemplatesave',{
        method:"POST",
        // headers:{   
        //     'Content-Type':'application/json'
        // },
        body:formData
    });
    console.log(res);
        //  const status = await saveBarcodeConfiguration(fieldNames);
        // console.log(status, "status");
    }
 
    const handleFilechange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        debugger;
        const selectedFile = e.target.files&& e.target.files[0];
        setfile(selectedFile);
    }
   

    return (
        <div className=''>
            <div className='barcode-config-fields inputFormBox'>
                <div className='hrBox'>
                    <h3>Barcode Configuration</h3>
                </div>
                <div className='row' style={{ margin: "20px" }}>
                    <div className='col-md-4'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setGsmCode(!gsmCode)} type="checkbox" checked={gsmCode} id="gsmCode" />
                            <label className='label-style'>GSM code</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setExtraCode(!extraCode)} type="checkbox" checked={extraCode} id="extraCode" />
                            <label className='label-style'>Extra Code</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setItemName(!itemName)} type="checkbox" checked={itemName} id="itemName" />
                            <label className='label-style'>Item Name</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setRegionalItemName(!regionalItemName)} type="checkbox" checked={regionalItemName} id="regionalItemName" />
                            <label className='label-style'>Regional Item Name</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setCategory(!category)} type="checkbox" checked={category} id="category" />
                            <label className='label-style'>Category</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setHsnCode(!hsnCode)} type="checkbox" checked={hsnCode} id="hsnCode" />
                            <label className='label-style'>HSN Code</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setNetWeight(!netWeight)} type="checkbox" checked={netWeight} id="netWeight" />
                            <label className='label-style'>Net Weight</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setGrossWeight(!grossWeight)} type="checkbox" checked={grossWeight} id="grossWeight" />
                            <label className='label-style'>Gross Weight</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setSubCategory(!subCategory)} type="checkbox" checked={subCategory} id="subCategory" />
                            <label className='label-style'>Sub Category</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setBrand(!brand)} type="checkbox" checked={brand} id="brand" />
                            <label className='label-style'>Brand</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setMrp(!mrp)} type="checkbox" checked={mrp} id="mrp" />
                            <label className='label-style'>MRP</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setEffectiveDate(!effectiveDate)} type="checkbox" checked={effectiveDate} id="effectiveDate" />
                            <label className='label-style'>Effective Date</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setPurchasePrice(!purchasePrice)} type="checkbox" checked={purchasePrice} id="purchasePrice" />
                            <label className='label-style'>Purchase Price</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setCurrentStock(!currentStock)} type="checkbox" checked={currentStock} id="currentStock" />
                            <label className='label-style'>Current Stock</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setUnitOfMeasure(!unitOfMeasure)} type="checkbox" checked={unitOfMeasure} id="unitOfMeasure" />
                            <label className='label-style'>Unit Of Measure</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setUnitType(!unitType)} type="checkbox" checked={unitType} id="unitType" />
                            <label className='label-style'>Unit Type</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setBarcode(!barcode)} type="checkbox" checked={barcode} id="barcode" />
                            <label className='label-style'>Barcode</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setBatchNo(!batchNo)} type="checkbox" checked={batchNo} id="batchNo" />
                            <label className='label-style'>Batch No</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>

                    <div className='inputBoxLists'>
                        <div className='inputBox'>
                            <label className='label-style'>Template Name</label>
                            <input
                                type='text'
                                autoComplete='nope'
                                className='fullInput'
                                style={{ width: "50%" }}
                                placeholder='Enter Template Name'
                                value={templateName}
                                onChange={(e) => setTemplateName(e.target.value)}
                                name='templateName'
                            />
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='inputBoxLists'>
                        <div className='inputBox'>
                            <label className='label-style'>Template Name</label>
                            <input
                                type='file'
                                autoComplete='nope'
                                className='fullInput'
                                style={{ width: "50%" }}
                                onChange={handleFilechange}
                                name='file'
                            />
                            <div className='vertical-space-10' />
                        </div>
                    </div>

                    <div className='btnBox mt-4'>
                        <button className='secondaryBtn' onClick={handleChangeview}>List</button>
                        <button className="dfBtn" type="submit" onClick={() => handleSave()}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BarcodeConfiguration;