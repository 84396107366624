import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IBrand } from './model';
import useStores from '../../hooks';
import './BrandStyle.css';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import brandValidation from './validation';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { startTransition } from 'react';
import Cookies from 'js-cookie';

const Brand = observer((): JSX.Element => {

  const [brand, setBrandModel] = useState<IBrand>({})
  const [brandList, setBrandList] = useState<any[]>([])
  const { supplierMasterStore, brandStore } = useStores();
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadModal, setLoadModal] = useState(false);
  const [isExists, setExists] = useState(false)
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const handleChangeview = () => {
    navigate('/Admin/brand/list');
  }

  const { brandId } = useParams();

  const saveBrand = async (e) => {
 
    e.preventDefault();

    let error: any = {};
    error = brandValidation(brand);
    setErrors(error);
    if (Object.keys(error).length === 0 && !isExists) {
      setLoading(true);

      let status: string = '';
      if (brandId !== undefined) {
        status = await brandStore.updateBrand(brand);

        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        const status = await brandStore.saveBrand(brand);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'brandCode') {
      // let isExists = brandList?.forEach((each)=>each)
      const isExists = brandList.some(brand => brand.brandCode === value);
      let error: any = {};
      if (isExists) {
        error.brand = 'Brand already exists'
      } else {
        error = {}
      }
      setExists(isExists)
      setErrors(error);
    }
    setBrandModel({ ...brand, [name]: value })
  }

  const handleSuccessModalClose = () => {
    navigate('/Admin/Brand/list');
  }

  async function getBrandDet() {
    setLoading(false)
    if (brandId !== undefined) {
      const data = await brandStore.BrandDetails(parseInt(brandId!));
      console.log(data, 'branddata');
      brand.brandId = data.brandId
      brand.brandCode = data.brandCode;
      brand.brandCompany = data.brandCompany;
      setBrandModel(brand);
      console.log(brand, 'brand');

    }
  }

  async function getBrandNo() {
    
    const [data, brandList] = await Promise.all([brandStore.getEntryNo('BrandMaster'), brandStore.getBrand()]);
    setBrandList([...brandList])
    if (brandId === undefined) {
      brand.brandId = data.entryNo + 1;
      setBrandModel(brand);
    }
  }

  const isCurrentPage = useRef(true);

  //   async function getBrandNoEdit() {
  //     const [data, brandList] = await Promise.all([brandStore.getEntryNo('BrandMaster'), brandStore.getBrand()]);
  //     setBrandList([...brandList])
  //     if (data !== undefined) {
  //       brand.brandId = data.entryNo;
  //       setBrandModel(brand);
  //   }
  // }

  async function getBrandNoEdit() {
    try {
      const [data, brandList] = await Promise.all([
        brandStore.getEntryNo('BrandMaster'),
        brandStore.getBrand()
      ]);
      startTransition(() => {
        setBrandList([...brandList]);
      });
      if (data && data.entryNo !== undefined) {
        const targetBrandId = brandId; //data.entryNo;           
        const selectedBrand = brandList.find(brand => brand.brandId === targetBrandId);
        if (selectedBrand) {
          console.log("Selected brand:", selectedBrand);
          startTransition(() => {
            setBrandModel(selectedBrand);
          });
        } else {
          console.error(`Brand with ID ${targetBrandId} not found in the brand list.`);
        }
      } else {
        console.error("No entry number found in data:", data);
      }
    } catch (error) {
      console.error("Error fetching brand data:", error);
    }
  }

  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     getBrandDet(); 
  //     if (brandId !== undefined){
  //       getBrandNoEdit();  
  //     }else{
  //       getBrandNo();       
  //     }      
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, [])
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Brand';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              getBrandDet();
              getBrandNo();

              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/brand/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <>
        {

          <>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>

              <form onSubmit={saveBrand}>
                <div className='hrBox'>
                  <h3>Brand</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='inputBox'>
                    <label>Code</label>
                    <input type="text" style={{ width: "50%" }}
                      name='brandId' disabled
                      value={brand.brandId}
                      className='brandinput' placeholder='Code..'></input>
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='inputBox'>
                    <label>Brand <span>*</span></label>
                    <input type="text"
                      name='brandCode'
                      value={brand.brandCode} onChange={handleChangeInput}
                      className='brandinput' placeholder='Enter Brand..'></input>
                    {errors.brandCode && <p style={{ color: 'red' }}>{errors.brandCode}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='inputBox'>
                    <label>Company <span>*</span></label>
                    <input type="text"
                      name='brandCompany'
                      value={brand.brandCompany} onChange={handleChangeInput}
                      className='brandinput' placeholder='Enter Company..'></input>
                    {errors.brandCompany && <p style={{ color: 'red' }}>{errors.brandCompany}</p>}
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleChangeview}>List</button>
                  <button className='dfBtn' type='submit' onClick={saveBrand}>Submit</button>
                </div>
              </form>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
        }

      </>
    );
  }
});

export default Brand;
