import { observer } from 'mobx-react'
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Accordion, Button, Image, Modal, Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import ProducationEntryValidation, { ProductionEntryDetValidation, ProductionItemsValidation } from './validation';
import AutoComplete from '../../common/shared/autoComplete';
import { useTranslation } from 'react-i18next';
import useStores from '../../hooks';
import { IProductinDetails, GrindingentryDetails } from './model';
import ProductionEntryValidation from './validation';
import { QRCode } from 'react-qrcode-logo';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { removeDuplicates } from '../../common/shared/utils';
import { Autocomplete } from '@mui/material';
import Cookies from 'js-cookie';
import { debug } from 'console';
import { each } from 'chart.js/dist/helpers/helpers.core';

const GrindingProduction = observer((): JSX.Element => {

    const { supplierMasterStore, outletManagerStore, commonStore, salesReplaceRetrunStore, machineMasterStore, inwardStore } = useStores();
    const { loadTeamDetail, loadTeamDetails, loadItemMaster, loadItemMasters, } = commonStore;
    const { loadPackingPlanItem, saveGrindProductEntrySave, loadPackingDistributionItem, loadPackingDistributionItems } = salesReplaceRetrunStore;

    const { t } = useTranslation()
    const sideNavAccordian: any = t("sideNavAccordian");

    console.log(sideNavAccordian, "sideNavAccordian");

    const navigate = useNavigate();
    const { Packingid } = useParams()

    const [itemMasterJSON, setItemMasterJSON] = useState<any[]>([])

    console.log(itemMasterJSON, "itemMasterJSONaa");
    const [loadBarcode, setLoadBarcode] = useState<any>([])

    console.log(loadBarcode, "loadBarcode");

    const [tempPackingPlanItems, setTempPackingItems] = useState<any[]>([])

    const [isLoadModal, setLoadModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    const [productionDet, setProductionDet] = useState<IProductinDetails>(new IProductinDetails())
    const [productionEntryDet, setProductionEntryDet] = useState<GrindingentryDetails>(new GrindingentryDetails())

    console.log(productionEntryDet, 'productionEntryDet');
    console.log(productionDet, 'filterloadproductionDet');

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [errors, setError] = useState<any>({})


    function handleAutoCompletes(name: string, value: any) {

        console.log(name, value, "datttattatatattata");

        if (name === "teamid" && value !== null) {
            setProductionDet({ ...productionDet, [name]: value?.teamMasterId, ["teamname"]: value?.teamMasterName })

        } else if (name === "machineid" && value !== null) {
            setProductionDet({ ...productionDet, [name]: value?.Mcode, ["machinename"]: value?.MachineName })

        } else if (name === "itemid" && value !== null) {
            setProductionEntryDet({ ...productionEntryDet, [name]: value?.Productid, itemname: value?.itemname, packingdid: value?.packingdid })

        } else if (name === 'barcode') {
            setProductionEntryDet({ ...productionEntryDet, [name]: value?.barcode })
        } else if (name === 'batchno') {
            setProductionEntryDet({ ...productionEntryDet, [name]: value?.Batchno })

        } else if (name === 'packingid') {
            setProductionEntryDet({ ...productionEntryDet, [name]: value?.packingid })
        }
    }

    // function handleQtyChange(e) {
    //     const { name, value } = e?.target;
    //     if (name === "qty" && value !== undefined) {
    //         let error: any = {}
    //         const assignQty = Number(value);

    //         setError(error);
    //         setProductionEntryDet({ ...productionEntryDet, [name]: Number(value) })
    //     }
    // }

    function handleEntryAdd() {
        let error: any = {};
        console.log(productionEntryDet, 'productionEntryDet<<<')
        error = ProductionEntryDetValidation(productionEntryDet);
        if (Object.keys(error)?.length === 0) {
            let addedItemSum = 0;
            if (!productionDet?.grindingentrydetails) {
                productionDet.grindingentrydetails = Array<GrindingentryDetails>()
            }
            if (productionDet?.grindingentrydetails?.length) {
                addedItemSum = productionDet?.grindingentrydetails?.reduce((a, v) => a = a +
                    Number(v?.itemid === productionEntryDet?.itemid &&
                        v?.batchno == productionEntryDet?.batchno ?
                        (v?.qty ?? 0) : 0), 0) + productionEntryDet?.qty!;
            }
            let findItem = itemMasterJSON?.find((each) =>
                each?.Machineid == productionDet?.machineid &&
                each?.Teamid === productionDet?.teamid &&
                each?.Productid === productionEntryDet?.itemid &&
                each?.Batchno == productionEntryDet?.batchno)
            if (findItem && addedItemSum > findItem?.qty) {
                error.qty = `Packing Qty is ${findItem?.qty}. Total Qty cannot be added more the Packing Qty`;
            } else {
                productionDet?.grindingentrydetails?.push(productionEntryDet)
                setProductionDet(productionDet)
                setProductionEntryDet({ ...productionEntryDet, itemid: 0, itemname: '', barcode: '', batchno: '',mrp: 0, qty: 0, packingdid: 0 })
            }
        }
        setError(error)
    }

    useEffect(() => {
        // handleOutRequestItemList()
    }, [productionDet?.grindingentrydetails?.length])

    // function handleOutRequestItemList() {
    //     let tempWPackingItems = [...tempPackingPlanItems];
    //     if (tempWPackingItems?.length) {
    //         let updatedList: any[] = [];
    //         tempWPackingItems?.map((obj, index) => {
    //             let filterItems = productionDet?.grindingentrydetails?.filter((item) => item?.itemid === obj?.Productid && item?.batchno === obj?.Batchno)
    //             let findBalQty = filterItems?.reduce((acc, valu) => acc = acc + Number(valu?.qty ?? 0), 0)
    //             if (findBalQty! < obj?.qty) {
    //                 updatedList?.push(obj)
    //             }
    //         })
    //         setItemMasterJSON([...updatedList])
    //     }
    // }

    function handleRemove(index, type: 'Entry' | 'Waste') {

        if (index >= 0 && type === 'Entry') {
            productionDet?.grindingentrydetails?.splice(index, 1)
            setProductionDet(_.cloneDeep(productionDet))
        }
    }



    async function handleSubmit() {
        let error: any = {};
        console.log(productionDet, 'productionDet777');


        error = ProductionEntryValidation(productionDet)
        setError(error)

        if (Object.keys(error)?.length === 0) {
            const sumbitArr = productionDet?.grindingentrydetails?.map((obj) => {
                let newObj = {
                    ...obj,
                    pid: productionDet?.pid
                }
                return newObj
            })

            productionDet.grindingentrydetails = sumbitArr


            // const status = "Success";
            const status = await saveGrindProductEntrySave(productionDet);

            if (status === "Success") {
                setSuccessModal(true);
                setLoading(false)
            } else {
                setFailureModal(true)
                setLoading(false)
            }
        }
    }

    function handleDetails() {
        navigate('/Admin/GrindingProduction/GrindingProductionDetail')
    }

    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }

    const isInitialRender = useRef(true);

    async function initialFetchApi() {

        setLoading(false)


    }

    async function getPakingPlanDet() {
        let body = {
            mode: 1,
            pid: Number(Packingid),
        }
        if (Packingid) {

            const [loadPackingPlanItems] = await Promise.all([
                loadPackingPlanItem(), loadTeamDetail(),
                loadPackingDistributionItem(), machineMasterStore.getMachineMasterList()
            ]);
            const itemdata = await loadItemMaster();

            setItemMasterJSON([...itemdata])
            setTempPackingItems([...loadPackingPlanItems])

            const loaddata = await salesReplaceRetrunStore.getProductionEntryList();
            let filterData = loaddata.filter((item) => Number(item.Pid) === Number(Packingid));
            console.log(filterData, 'filterData>>');

            const productionDet2 = await salesReplaceRetrunStore.getProductionEntryDetails(body);
            console.log(productionDet2, 'productionDet2');

            setProductionDet((prev) => ({
                ...prev,
                teamid: Number(filterData[0]?.Teamid) || 0,
                pid: Number(Packingid) || 0,
                machineid: Number(filterData[0]?.machineid) || 0,
                productionentrydetails: [
                    ...(prev.grindingentrydetails || []),
                    ...productionDet2.map((item) => ({
                        itemid: item?.itemid || '',
                        qty: item?.qty || '',
                        barcode: item?.barcode || '',
                        batchno: item?.batchno || '',
                        itemname: item?.itemname || '',
                        packingdid: Number(item?.Packingdid) || '',
                        packingid: Number(item?.packingid) || '',
                    })),
                ],
            }));

        } else {

            const [entryNos, loadPackingPlanItems] = await Promise.all([
                outletManagerStore.getEntryNo('GrindProduction'), loadPackingPlanItem(), loadTeamDetail(),
                loadItemMaster(), loadPackingDistributionItem(), machineMasterStore.getMachineMasterList()
            ]);
            const [itemMasterJSON] = await Promise.all([inwardStore.loadInwardItemMasterJSON()]);
            console.log(itemMasterJSON, "itemMasterJSON");
            setItemMasterJSON([...itemMasterJSON])

            setProductionDet({ ...productionDet, pid: (entryNos?.entryNo + 1) })
            setTempPackingItems([...loadPackingPlanItems])
            // if (paymentList) {

            //     mapToData(paymentList)
            //     setStckToWrhsSave({ ...stckToWrhsSave, stocktoWId: Number(Pid) })
            // }
        }


    }

    const handleConItemValue = (name: any, val: any) => {
        console.log(name, val, 'nameandvalue');
        if (val != null) {
            setProductionEntryDet({ ...productionEntryDet, ["itemid"]: Number(val?.itemid), ["itemname"]: val.itemname });
            handleGetBarcodeInward(val);
        }
        else {
            productionEntryDet.itemname = '';

        }
    }

    function handleMenuDropDowns(name: string, val: any) {
        console.log(val, 'valValues');
        if (val !== null) {
            if (name == "teamid" && Packingid) {

                // // setSubmitArr((prevArr) =>
                //     prevArr.map(item =>
                //         item.packingid === productionDet.pid 
                //             ? {
                //                 ...item,
                //                 teamid: val?.teamMasterId,
                //             }
                //             : item
                //     )
                // );


            }
            if (name == "machineid" && Packingid) {
                // setSubmitArr((prevArr) =>
                //     prevArr.map(item =>
                //         item.packingid === localFields.packingid
                //             ? {
                //                 ...item,
                //                 machineid: val?.Mcode
                //             }
                //             : item
                //     )
                // );


            }

            // setLocalFiedls({ ...localFields, [name]: name == "teamid" ? val?.teamMasterId : name === "machineid" ? val?.Mcode : val?.id })
        } else {
            // setLocalFiedls({ ...localFields, [name]: "" })
        }
    }

    const handleGetBarcodeInward = async (val: any) => {

        try {
            const itemInwardList = await inwardStore.getBarcodeInward(val?.itemid);
            console.log(itemInwardList, 'itemInwardList');
            setLoadBarcode([...itemInwardList]);

            console.log(loadBarcode, 'loadBarcode');
        } catch (error) {
            console.error('Error fetching barcode data:', error);
        }
    };

    useEffect(() => {
        console.log(productionDet, 'useEffectproductionDet');

    }, [productionDet])

    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Production_entry';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            initialFetchApi();
                            getPakingPlanDet()
                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/ProductionEntry/ProductionEntryDetail')
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    const handleFailureModalClose = () => setFailureModal(false);

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {


        return (
            <>
                {/* {isLoading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> : */}
                <div>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField' style={{ width: '700px' }}>
                        <div className='hrBox' >
                            <h3>{sideNavAccordian?.GrindingProduction}</h3>
                        </div>
                        <div className='inputBoxLists accordionLists'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Production Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='ItemInwardInputBox'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='inputBox'>
                                                        <label>No <span>*</span></label>
                                                        <input type="text" className=''
                                                            name='pid'
                                                            value={productionDet?.pid}
                                                            disabled
                                                            style={{ width: "100%" }} placeholder='Code...'></input>
                                                        {errors.pid && <p style={{ color: 'red' }}>{errors.pid}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Date <span>*</span></label>
                                                        <input type="date" style={{ width: '100%' }}
                                                            name="proddate"
                                                            onChange={(e) => setProductionDet({ ...productionDet, proddate: e.target.value })}
                                                            value={productionDet.proddate = productionDet?.proddate ?? new Date().toISOString().substr(0, 10)}
                                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                        ></input>
                                                        {errors.proddate && <p style={{ color: 'red' }}>{errors.proddate}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Team <span>*</span></label>
                                                        <AutoComplete
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Team.."
                                                            value={productionDet?.teamname}
                                                            emitOption={(val) => handleAutoCompletes('teamid', val)}
                                                            options={loadTeamDetails}
                                                            getOptionLabel={'teamMasterName'}
                                                        />
                                                        {errors.teamid && <p style={{ color: 'red' }}>{errors.teamid}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Machine <span>*</span></label>
                                                        <AutoComplete
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Machine/Manual.."
                                                            value={productionDet?.machinename}
                                                            emitOption={(val) => handleAutoCompletes('machineid', val)}
                                                            options={machineMasterStore?.machineMasterList}
                                                            getOptionLabel='MachineName'
                                                        />
                                                        {errors.machineid && <p style={{ color: 'red' }}>{errors.machineid}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>

                                                <div className='col-md-12'>
                                                    <div className='inputBox'>
                                                        <label>Item Name <span>*</span></label>
                                                        <AutoComplete
                                                            options={itemMasterJSON}
                                                            value={productionEntryDet?.itemname}
                                                            emitOption={(val) => handleConItemValue('itemid', val)}
                                                            placeholder={'Select Item..'}
                                                            clsName='full-width'
                                                            getOptionLabel='itemname'

                                                        />
                                                        {errors.itemid && <p style={{ color: 'red' }}>{errors.itemid}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Barcode <span>*</span></label>
                                                        {/* <input type="text" className=''
                                                            name='Barcode'
                                                            value={productionEntryDet?.barcode}
                                                            onChange={handleQtyChange}
                                                            style={{ width: "100%" }} placeholder='Batchno..'></input> */}

                                                        <AutoComplete
                                                            // value={productionEntryDet?.barcode}
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Item.."
                                                            disabled={productionEntryDet?.itemid === 0}
                                                            options={loadBarcode}
                                                            value={productionEntryDet?.barcode}
                                                            emitOption={(val) => handleAutoCompletes('barcode', val)}
                                                            getOptionLabel='barcode'
                                                        />
                                                        {errors.batchno && <p style={{ color: 'red' }}>{errors.batchno}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Batch No <span>*</span></label>
                                                        <input type="text" className=''
                                                            name='Batchno'
                                                            value={productionEntryDet?.batchno}
                                                            onChange={(e) => setProductionEntryDet({ ...productionEntryDet, ["batchno"]: e.target.value })}
                                                            style={{ width: "100%" }} placeholder='Batchno..'></input>

                                                        {/* <AutoComplete
                                                            value={productionEntryDet?.batchno}
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Item.."
                                                            disabled={productionEntryDet?.itemid === 0}
                                                            emitOption={(val) => handleAutoCompletes('batchno', val)}
                                                            options={loadPackingPlanItems?.length ?
                                                                loadPackingPlanItems?.filter((each) => each?.packingid == productionEntryDet?.packingid && each?.Machineid == productionDet?.machineid && each?.Teamid === productionDet?.teamid && each?.Productid === productionEntryDet?.itemid && each?.barcode === productionEntryDet?.barcode) : []}
                                                            getOptionLabel='Batchno'
                                                        /> */}
                                                        {errors.batchno && <p style={{ color: 'red' }}>{errors.batchno}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>MRP <span>*</span></label>
                                                        <input type="number" style={{ width: '100%' }}
                                                            step="0.0001"
                                                            min="0"
                                                            name="mrp"
                                                            value={productionEntryDet?.mrp}
                                                            onChange={(e) => setProductionEntryDet({ ...productionEntryDet, ["mrp"]: Number(e.target.value) })}
                                                            placeholder='MRP..'></input>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Qty <span>*</span></label>
                                                        <input type="number" style={{ width: '100%' }}
                                                            step="0.0001"
                                                            min="0"
                                                            name='qty'
                                                            value={productionEntryDet?.qty}
                                                            onChange={(e) => setProductionEntryDet({ ...productionEntryDet, ["qty"]: Number(e.target.value) })}
                                                            placeholder='Qty..'></input>
                                                        {errors.qty && <p style={{ color: 'red' }}>{errors.qty}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-4">
                                                    <button className='dfBtn' type='submit' onClick={handleEntryAdd}>Add</button>
                                                </div>
                                            </div>
                                            <div className='vertical-space-20'></div>
                                            <div className="tableListDetails">
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Item</th>
                                                                <th scope="col">Qty</th>
                                                                <th scope="col">Barcode</th>
                                                                <th scope="col">Batch No</th>
                                                                <th scope="col">MRP</th>
                                                                <th scope='col'></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {productionDet?.grindingentrydetails?.length ?
                                                                productionDet?.grindingentrydetails?.map((itemsObj, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{loadItemMasters?.find((item) => item?.itemid === itemsObj?.itemid)?.itemName}</td>
                                                                            <td>{itemsObj?.qty}</td>
                                                                            <td>{itemsObj?.barcode} </td>
                                                                            <td>{itemsObj?.batchno} </td>
                                                                            <td>{itemsObj?.mrp} </td>
                                                                            <td><button className='delete' onClick={() => handleRemove(index, 'Entry')}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                <div>
                                                                    No data
                                                                </div>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {errors.lengthnon && <p style={{ color: 'red' }}>{errors.lengthnon}</p>}
                                            </div>
                                            <div className='vertical-space-20'></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                        <div className='inputBoxLists pd-b-0'>
                            <div className='LgInputListsBox mr-left-0'>
                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={handleDetails}>List</button>
                                    <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>

                        <Modal show={isSuccessModal} onHide={handleDetails} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleDetails}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div >

            </>
        )
    }
})

export default GrindingProduction;

