import React, { useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { IInwardOutlet, InwardOutletDetails } from './model';
import ProgressBar from '../../common/shared/progressbar';
import { Autocomplete, TextField } from '@mui/material';
import inwardOutletValidation from './validation';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { QRCode } from 'react-qrcode-logo';
import AutoComplete from '../../common/shared/autoComplete';
import { removeDuplicates } from '../../common/shared/utils';
import { log } from 'util';


const InwardOutlet = observer(() => {
  const navigate = useNavigate();

  const { supplierMasterStore, inwardOultetStore, commonStore } = useStores();

  const [inwardOutlet, setInwardOutlet] = useState<IInwardOutlet>({});
  console.log(inwardOutlet, 'inwardOutletdetailsfiter');
  const [inwardOutletDetails, setInwardOutletDetails] = useState<InwardOutletDetails[]>([]);
  console.log(inwardOutletDetails, 'inwardOutletDetailsfff');
  const [inwardItemList, setInwardItemList] = useState<any[]>([])
  console.log(inwardItemList, 'inwardItemList');

  const [errors, setErrors] = useState<any>({});

  const [isLoading, setLoading] = useState(true);
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const { inwardNo } = useParams<{ inwardNo: any }>();

  const handleInputChange = (e) => {
    
    const { name, value } = e.target;
    setInwardOutlet({ ...inwardOutlet, [name]: value });
  }

  const handleOutwardValue = async (name, event, val) => {
    setLoading(true)
    if (val != null) {
      setInwardOutlet({ ...inwardOutlet, [name]: val.outwardOutletId });
      const loadOutwardDetails = await inwardOultetStore.getLoadOutwardDetails(val.outwardOutletId);
      if (loadOutwardDetails?.length) {
        setInwardItemList([...loadOutwardDetails])
      } else {
        setInwardItemList([])
      }
    } else {
      inwardOutlet.inwardOutletId = 0;
    }
    setLoading(false)
  }

  const handleOutwardDetailsValue = (name, event, val) => {
    if (name === "itemId" && val != null) {
      setInwardOutlet({
        ...inwardOutlet, [name]: val.itemId, itemName: val.itemName,
      });
    } else if (name === 'batchNo' && val != null) {
      setInwardOutlet({
        ...inwardOutlet, barcode: val?.barcode, batchId: val.batchId, batchNo: val.batchNo,Mrprate: val.mrprate
         , quantity: val.quanitity, warehouseStock: val.warehouseStock,
        issuedBy: val.issuedBy, issuedByName: val.issuedByName,
      });
    } else {
      inwardOutlet.itemName = '';
    }
  }

  const filterItemList = useMemo((): any => {
    if (inwardOutletDetails?.length) {
      const filteredItems = inwardItemList.filter(item => {
        return !inwardOutletDetails.some(
          excludedItem =>
            excludedItem.inwardOutletItemId === item.itemId && excludedItem.InwardOutletBatchNo === item.batchNo
        );
      });
      return filteredItems;
      // setInwardItemList(filteredItems);
    } else if (inwardItemList?.length) {
      return inwardItemList;
      // setInwardItemList(inwardItemList);
    }
  }, [inwardItemList, inwardOutletDetails])

  const addInwardDetails = async () => {
    let newObject: InwardOutletDetails = {};
    let error: any = {};
    error = inwardOutletValidation(inwardOutlet);
    setErrors(error);
    console.log(Object.keys(error).length, 'ggdgdgddawaw');
    if (Object.keys(error).length === 0) {
      newObject.inwardOutletItemId = inwardOutlet.itemId;
      newObject.inwardOutletItemName = inwardOutlet.itemName;
      newObject.InwardOutletBatchId = inwardOutlet.batchId;
      newObject.InwardOutletBatchNo = inwardOutlet.batchNo;
      newObject.inwardOutletquantity = inwardOutlet.quantity;
      newObject.inwardOutletBarcode = inwardOutlet.barcode;
      newObject.inwardOutletMrprate = inwardOutlet.Mrprate;

      await setInwardOutletDetails([...inwardOutletDetails, newObject]);
      let updatedList = inwardItemList?.map((each) => {
        if (each?.outwardOutletId === inwardOutlet?.outwardOutletNo && each?.itemId === inwardOutlet?.itemId &&
          each?.batchNo === inwardOutlet?.batchNo && each?.barcode === inwardOutlet?.barcode
        ) {
          return each
        }
        return each
      })
      console.log(updatedList, 'updatedList');

      setInwardOutlet({
        ...inwardOutlet, itemId: 0, itemName: '', barcode: '',
        batchId: 0, batchNo: '', quantity: 0, warehouseStock: 0, Mrprate: 0
      })
      setSubmitbtn(true);
    }
  }

  const handleOutlet = async (val, outletId) => {
    
    setLoading(true);
     setInwardOutlet({ ...inwardOutlet, outletId: val.outletid});
     await inwardOultetStore.getLoadOutward(val.outletid);
    setLoading(false);
  }

  const deleteInwardOutletDetls = async (index) => {
    if (inwardOutletDetails?.some((obj, i) => i === index)) {
      inwardOutletDetails?.splice(index, 1)
      setInwardOutletDetails([...inwardOutletDetails])
    }
  }

  const handleSuccessModalClose = () => {
    navigate('/Admin/InwardOutlet/InwardOutletDetail');
  }

  const handleCancel = () => {
    navigate('/Admin/InwardOutlet/InwardOutletDetail');
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const handleFailureModalClose = () => setFailureModal(false);

  async function saveInwardOutlet(e) {
    console.log(inwardOutlet,"inwardOutlet555");
    
    setLoading(true);
    e.preventDefault();
    inwardOutlet.inwardOutletDetails = inwardOutletDetails;
    inwardOutlet.receivedBy = Number(Cookies.get('employeeId'));
    const status = await inwardOultetStore.saveInwardOutlet(inwardOutlet);

    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  async function getPrelOadingData() {
    if (inwardNo) {
      const inwardOutletList = await inwardOultetStore.getInwardOutlet(inwardNo);
      const inwardOutletDetails = await inwardOultetStore?.getInwardOutletDetails(inwardNo);
      const filteredData = inwardOutletList?.filter((item: any) => item?.inwardOutletId === Number(inwardNo));
      console.log(inwardOutletDetails,'filteredData333');
      
      await inwardOultetStore.getLoadOutward(filteredData[0]?.outletId);
      inwardOutlet.inwardOutletId = filteredData[0]?.inwardOutletId;
      inwardOutlet.outletName = filteredData[0]?.outletname;
      inwardOutlet.inwardOutletDate = filteredData[0].inwardOutletDate;
      inwardOutlet.outwardOutletNo = filteredData[0].outwardOutletNo;
     

     
      console.log(inwardOutletDetails, "inwardOutletDetails");

      setInwardOutletDetails(inwardOutletDetails);
      await commonStore.loadOutlet();
      setLoading(false);
      setSubmitbtn(true);
    }
    else
    {
      const entryNos = await inwardOultetStore.getEntryNo('InwardOutlet');

      const defaultDate = new Date().toISOString().substr(0, 10);

      await commonStore.loadOutlet();
      setInwardOutlet({ ...inwardOutlet,  inwardOutletId:  (entryNos?.entryNo ), inwardOutletDate: defaultDate });
  
      setLoading(false);

    }

  }

  const isInitialRender = useRef(true);

  // useEffect(() => {
  //   if (isInitialRender.current) {
  //     getPrelOadingData();
  //     isInitialRender.current = false;
  //   } else {
  //   }
  //   return () => { };
  // }, []);

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Inward_outlet';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              getPrelOadingData();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/InwardOutlet/InwardOutletDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (inwardOutlet?.outletName && Array.isArray(commonStore?.loadOutlets) && inwardNo) {
    console.log('ifconditionworking');
  } else {
    console.log('elseconditionworking');
  }

  const outletName = inwardOutlet?.outletName && Array.isArray(commonStore?.loadOutlets) && inwardNo


  const date = new Date(inwardOutlet.inwardOutletDate || '');
  const options: any = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };
  const [day, month, year] = date.toLocaleDateString('en-GB', options).split('/');
  const formattedDate = `${year}-${month}-${day}`;

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <>

        <div className='container'>
          <div className='vertical-space-20'></div>
          <div className='outletInputField inputFormBox'>
            <div className='hrBox'>
              <h3>Inward-Outlet</h3>
            </div>
            <div className='inputBoxLists pd-b-0'>
              <div className='LgInputListsBox mr-left-0'>
                <div className='row'>
                  {
                    isLoading ? <ProgressBar /> : null
                  }
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Entry No </label>
                      <input type="text" className='' style={{ width: "100%" }}
                        value={ inwardOutlet.inwardOutletId}
                        placeholder='Entry No..' disabled></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Date <span>*</span></label>
                      <input
                        defaultValue={new Date().toISOString().substr(0, 10)}
                        type="date"
                        style={{ width: "100%" }}
                        value={inwardOutlet?.inwardOutletDate ? formattedDate : new Date().toISOString().substr(0, 10)} name='inwardOutletDate'
                        onChange={handleInputChange}
                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                      {errors.inwardOutletDate && <p style={{ color: 'red' }}>{errors.inwardOutletDate}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-12'>
                    <div className='inputBox'>
                      <label>Outlet Name <span>*</span></label>
                      <Autocomplete size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={commonStore.loadOutlets}
                        getOptionLabel={(option: any) => option?.outletName}
                        onChange={(event, val) => handleOutlet(val, '')}
                        value={
                          Array.isArray(commonStore?.loadOutlets) && inwardOutlet?.outletName && inwardNo
                            ? commonStore?.loadOutlets?.find((option: any) => option?.outletName === inwardOutlet?.outletName)
                            : inwardOutlet?.outletName
                        }
                        renderInput={(params: any) =>
                          <TextField  {...params} style={{ width: '30ch' }}
                            id="outlined-size-small"
                            color='info'
                            size="small"
                            type="text"
                            placeholder='Select Outlet'
                            name='outletId'
                          />}
                      />
                      {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Outward To OutletNo <span>*</span></label>
                      <Autocomplete size="small"
                        disablePortal
                        id="combo-box-demo"
                        loading={true}
                        loadingText="No Data, Try another outlet"
                        options={inwardOultetStore?.loadOutward?.length ? inwardOultetStore?.loadOutward : []}
                        getOptionLabel={(option: any) => option?.outwardOutletId ? option.outwardOutletId : null}
                        onChange={(event, val) => handleOutwardValue('outwardOutletNo', event, val)}
                        // value={}
                        renderInput={(params: any) =>
                          <TextField  {...params}
                            id="outlined-size-small"
                            color='info'
                            size="small"
                            type="text"
                            placeholder='Select Outlet Name..'
                            name='outwardOutletNo'
                          />}
                      />
                      {errors.outwardOutletNo && <p style={{ color: 'red' }}>{errors.outwardOutletNo}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  {inwardItemList?.length ?
                    <div className='col-md-12'>
                      <div className='inputBox'>
                        <label>Item Name <span>*</span></label>
                        <AutoComplete
                          options={removeDuplicates(filterItemList, 'itemName')}
                          value={inwardOutlet?.itemName}
                          emitOption={(option) => handleOutwardDetailsValue('itemId', {}, option)}
                          placeholder='Select Item Name..'
                          getOptionLabel='itemName'
                          clsName='full-width'
                        />
                        {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div> : null}
                  {(inwardItemList?.length && inwardOutlet?.itemId) ?
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Batch No <span>*</span></label>
                        <AutoComplete options={inwardItemList?.filter((each) => (each?.itemId === inwardOutlet?.itemId) && !inwardOutletDetails?.some((obj) => obj?.inwardOutletItemId == each?.itemId && obj?.InwardOutletBatchNo == each?.batchNo))}
                          getOptionLabel='batchNo' emitOption={(option) => handleOutwardDetailsValue('batchNo', {}, option)}
                          value={inwardOutlet?.batchNo} placeholder='Select Batch No..' clsName='full-width' />
                      </div>
                      <div className='vertical-space-10'></div>
                    </div> : null}
                  <div className='col-md-3'>
                    <div className='inputBox'>
                      <label>Qty </label>
                      <input type="text" className='' style={{ width: "100%" }}
                        value={inwardOutlet?.quantity}
                        defaultValue={inwardOutlet.quantity} name='quantity'
                        placeholder='Batch No..' disabled></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Warehouse Qty<span>*</span></label>
                      <input type="text" className=''
                        value={inwardOutlet.warehouseStock} name='totalNumItems' disabled
                        style={{ width: "100%" }} placeholder='Total No Of Items..'></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Issued By <span>*</span></label>
                      <input type="text" className='' style={{ width: "100%" }}
                        value={inwardOutlet.issuedByName} name='issuedByName'
                        placeholder='Issued By..' disabled></input>

                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Received By <span>*</span></label>
                      <input type="text" className='' style={{ width: "100%" }}
                        value={Cookies.get('employee_name')}
                        disabled></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-2'>
                    <button className='dfBtn' onClick={addInwardDetails}>Add</button>
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='col-md-12'>
                    <div className='tableBox' style={{ overflow: 'scroll', maxHeight: '400px' }}>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Item Name</th>
                            <th scope="col">Barcode</th>
                            <th scope="col">Batch No</th>
                            <th scope="col">Qty</th>
                            <th scope="col">MRP</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {inwardOutletDetails.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{val.inwardRowId = key + 1}</td>
                                <td>{val.inwardOutletItemName}</td>
                                <td>{val.inwardOutletBarcode}</td>
                                {/* {val.InwardOutletBatchNo ?
                                  <td><QRCode value={val.InwardOutletBatchNo} size={100} /></td> :
                                  <td>{val.InwardOutletBatchNo}</td>
                                } */}
                                {val.InwardOutletBatchNo ?
                                  <td>{val.InwardOutletBatchNo}</td> :
                                  <td>{val.InwardOutletBatchNo}</td>
                                }
                                <td>{val.inwardOutletquantity}</td>
                                <td>{val.inwardOutletMrprate}</td>
                                <td><button className='delete'><FontAwesomeIcon icon={faTrash} onClick={() => deleteInwardOutletDetls(key)} /></button></td>
                                <td><button className=''><FontAwesomeIcon icon={faEdit} /></button></td>
                              </tr>
                            )
                          })
                          }
                          <tr>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>

                </div>
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='btnBox'>
              <button className='secondaryBtn' onClick={handleCancel}>List</button>
              <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveInwardOutlet}>Submit</button>
            </div>
          </div>
          <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../gsmecom/images/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../gsmecom/images/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFailureModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

      </>

    );
  }
});

export default InwardOutlet;
