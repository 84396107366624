import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { IGsmCodeDetails, IitemMaster, IItemMaster, ISupplier } from './model';
import { Autocomplete, TextField } from '@mui/material';
//import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import itemMasterValidation from './validation';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AutoComplete from '../../common/shared/autoComplete';
import { removeDuplicates } from '../../common/shared/utils';

const ItemMaster1 = observer((): JSX.Element => {

  const { commonStore, itemMasterStore, unitOfMeasurementStore, subCategoryStore } = useStores();

  const { getGsmCode, getTaxMaster, gsmCode, updateItemMaster, uploadItemImage, getEntryNo, getItemDetails, getSupplierDetails, getItemMasters } = itemMasterStore;
  const [itemDetails, setItemDetail] = useState<IItemMaster>(new IItemMaster());

  const [supplier, setSupplier] = useState<ISupplier>({});
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);

  const [isLoading, setLoading] = useState(true);
  const [image, setImage] = useState();
  const [fileName, setImageFile] = useState();
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [supplierName, setLoadSuppliers] = useState("");

  const [tempCategoryList, setCategoryList] = useState<any[]>([]);
  const [isNewCat, setNewCategory] = useState({ bool: false, itemCategory: '' })
  const { supplierMasterStore,loadBrands, loadbrands, loadSuppliers, loadSupplierMastes, loadSubcategory, loadSubcategories } = commonStore;

  const navigate = useNavigate();
  const { itemId } = useParams();

  const handleInputchange = (event: any) => {
    const { name, value } = event.target;
    setItemDetail({ ...itemDetails, [name]: name == 'msqty' ? Number(value) : value })
  }

  const handleChangeImage = (e) => {
    itemDetails.imageData = '';
    e.preventDefault();
    setImage(e.target.files[0]);
    setImageFile(e.target.files[0].name);
  }

  const handleSubcategoryValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setItemDetail({ ...itemDetails, [name]: val.subCategoryId, ['subcategoryName']: val.subCategoryName });
    } else {
      itemDetails.subcategoryName = '';
    }
  }

  const handleSupplierValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setLoadSuppliers(val?.supplierName)
      setSupplier({ ...supplier, [name]: val?.supplierId, ['supplierName']: val?.supplierName, ['supplier']: val?.supplierName });
    } else {
      supplier.supplierName = '';
    }
  }

  const handlebrandValue = async (name: any, event: object, val: any) => {
    if (val != null) {
      if (itemDetails.itemCategory !== undefined && itemDetails.subcategoryName && val.brandCompany) {
        let gsmdateils: IGsmCodeDetails = {};
        gsmdateils.category = itemDetails.itemCategory;
        gsmdateils.subCategory = itemDetails.subcategoryName;
        gsmdateils.brand = val.brandCompany;
        await getGsmCode(gsmdateils);
        itemDetails.gsmCode = gsmCode;

      }
      setItemDetail({ ...itemDetails, [name]: val.brandId });
    }
    else {
      itemDetails.brnd = '';
    }
  }
  const handleUOM = (name: any, event: object, val: any) => {
    if (val != null) {
      setItemDetail({ ...itemDetails, unitId: val.unitId, unitName: val.unitName });
    }
    else {
      itemDetails.taxName = '';
    }
  }
  const handleTaxValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setItemDetail({ ...itemDetails, [name]: val.ledgerId, taxName: val.ledgerName });
    } else {
      itemDetails.taxName = '';
    }
  }

  const handleCessValue = (name, event, val) => {
    if (val != null) {
      setItemDetail({ ...itemDetails, [name]: val.ledgerId, cessName: val.ledgerName });
    } else {
      itemDetails.cessName = '';
    }
  }

  const handleSupplier = (event: any) => {
    const { name, value } = event.target;
    setSupplier({ ...supplier, [name]: value })
  }

  function addSupplier(e) {
    e.preventDefault();
    let error: any = {};
    error = itemMasterValidation(itemDetails, suppliers, supplier);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      const data = suppliers?.findIndex(d => d.supplierId === supplier.supplierId);
      if (data !== -1) {
        suppliers.splice(data, 1);
        let supplierObje: ISupplier = {};
        supplierObje.supplierId = supplier.supplierId
        supplierObje.supplierName = supplier.supplierName
        supplierObje.commissionPercentage = supplier.commissionPercentage
        setSuppliers([...suppliers, supplierObje])
        setLoadSuppliers("");
      } else {
        setSuppliers([...suppliers, supplier]);
      }
    }
  }

  const handleSellingInput = (itemDetail) => {
    let b2bamount: any = 0;
    let b2camount: any = 0;
    b2bamount = (Number(itemDetail?.mrpp) - (Number(itemDetail?.mrpp) * 0.1))?.toFixed(2);
    b2camount = (Number(itemDetail.mrpp) - (Number(itemDetail?.mrpp) * 0.12))?.toFixed(2);
    itemDetail.b2CNewPrice = Number(b2camount);
    itemDetail.b2BnewPrice = Number(b2bamount)
    itemDetail.oldmrp = "0"
    itemDetail.b2BoldPrice = 0
    itemDetail.b2ColdPrice = 0
    return itemDetail
  }

  async function saveItemMaster(e) {    
    e.preventDefault();
    if (itemDetails?.brandType === "OWNBRAND" && !suppliers?.length) {
      let suppliers: ISupplier[] = [];
      let supplierObje: ISupplier = {};
      supplierObje.supplierId = 0
      supplierObje.supplierName = ''
      supplierObje.commissionPercentage = '';
      suppliers?.push(supplierObje);
      itemDetails.suppliers = suppliers;
    } else {
      itemDetails.suppliers = suppliers;
    }
    itemDetails.fileName = fileName;
    let error: any = {};
    error = itemMasterValidation(itemDetails, suppliers, supplier);
    setErrors(error)
    console.log(error, 'error>>')   
    if (Object.keys(error).length === 0) {   
      setLoading(true);
      itemDetails.imageData = null;
      itemDetails.mrpp = itemDetails?.mrpp ?? "0";
      itemDetails.expiryDays = itemDetails.expiryDays ? Number(itemDetails.expiryDays) : 0;      
      if (!itemDetails?.unittype) {
        itemDetails.unittype = "loosed"
      } else if (itemDetails?.unittype === 'packed') {
        itemDetails.purchaseuomid = 0;
        itemDetails.convuomid = 0;
        itemDetails.convQty = 0;
      }         
      if (itemId !== undefined) {        
        let b2bamount: any = 0;
        let b2camount: any = 0;
        b2bamount = (Number(itemDetails?.mrpp) - (Number(itemDetails?.mrpp) * 0.1))?.toFixed(2);
        b2camount = (Number(itemDetails.mrpp) - (Number(itemDetails?.mrpp) * 0.12))?.toFixed(2);
        itemDetails.b2Bper! = 10;
        itemDetails.b2Cper! = 12;
        itemDetails.b2CNewPrice = Number(b2camount);
        itemDetails.b2BnewPrice = Number(b2bamount)
        console.log(itemDetails, "itemDetailsaa");
        let status = await updateItemMaster(itemDetails);        
        if (status === "Success") {
          if (image !== undefined) {
            status = await uploadItemImage(image, itemDetails.itemId);
          }
          if (status === "Success") {
            setLoading(false);
            setSuccessModal(true)
          } else {
            setLoading(false);
            setFailureModal(true);
          }
        } else {
          setLoading(false);
          setFailureModal(true);
        }
      } else {
        const entryNo = await getEntryNo('ItemMaster');
        itemDetails.itemCode = entryNo.entryNo + 1;
        let itemDetail = handleSellingInput(itemDetails)
        itemDetails.b2Bper! = 10;
        itemDetails.b2Cper! = 12;
        const status = await itemMasterStore.saveItemMaster(itemDetail);
        if (status !== undefined && status.responseMessage === "Success") {
          itemDetails.itemCode = status.id;
          if (image !== undefined) {
            const saveimageStatus = await uploadItemImage(image, itemDetails.itemCode);
            if (saveimageStatus === "Success") {
              setLoading(false);
              setSuccessModal(true);
            } else {
              setLoading(false);
              setFailureModal(true);
            }
          } else if (status.responseMessage === "Success") {
            setLoading(false);
            setSuccessModal(true);
          } else {
            setLoading(false);
            setFailureModal(true);
          }
        } else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleCancel = () => {
    navigate('/Admin/itemmaster/itemmasterdetails');
  }
  const handleSuccessModalClose = () => {
    navigate('/Admin/itemmaster/itemmasterdetails');
  }

  async function mapItemData(itemDels, supplierDetls) {
    console.log(itemDels, "itemDelsaaa");
    let arrObjet: ISupplier[] = [];
    arrObjet = supplierDetls;
    itemDetails.itemCode = itemDels[0]!.itemId;
    itemDetails.extraCode = itemDels[0]!.extraCode ? itemDels[0]!.extraCode?.toString() : '';
    itemDetails.extraCode = itemDels[0]!.extraCode ? itemDels[0]!.extraCode?.toString() : '';
    itemDetails.gsmCode = itemDels[0]!.Gsmcode ?? ""; // check
    itemDetails.comments = itemDels[0]!.Comments ?? ""; // check
    itemDetails.taxName = itemDels[0]!.taxname1 ?? "";
    itemDetails.taxId = Number(itemDels[0]!.taxname) ?? 0; // check
    itemDetails.fileName = itemDels[0]!.fileName ?? ""; // check
    itemDetails.imageData = itemDels[0]!.imageData; // check
    itemDetails.subcategoryName = itemDels[0]!.subcategoryname ?? ""; // check
    itemDetails.brandCompany = itemDels[0]!.brandname ?? "";//check
    itemDetails.brandType = itemDels[0]!?.BrandType ?? '';
    itemDetails.cessName = itemDels[0]!.cessname1 ?? "";//check
    itemDetails.itemName = itemDels[0]!.itemname;
    itemDetails.itemId = itemDels[0]!.itemid;
    itemDetails.itemCategory = itemDels[0]!.Category;
    itemDetails.hsnCode = itemDels[0]!.Hsncode ?? '';
    itemDetails.subCategoryId = Number(itemDels[0]!.subCategory);
    itemDetails.unitId = itemDels[0]!.unitId ?? 0;
    itemDetails.purchaseuomid = itemDels[0]!.purchaseuomid ?? 0;
    itemDetails.convuomid = itemDels[0]!.Convuomid ?? 0;
    itemDetails.convQty = itemDels[0]!.ConvQty ?? 0;
    itemDetails.unitName = itemDels[0]!.Unitname;
    itemDetails.brand = Number(itemDels[0]!.brand);
    itemDetails.cessId = itemDels[0]!.cessId;
    itemDetails.expiryDays = itemDels[0]!.expiryDays;
    itemDetails.unittype = itemDels[0]!.unittype;

    // number
    itemDetails.purchasePrice = itemDels[0]!.purchasePrice ? itemDels[0]!.purchasePrice?.toString() : '0';
    itemDetails.currentStock = itemDels[0]!.currentStock ? itemDels[0]!.currentStock?.toString() : '0';
    itemDetails.stocksLimit = itemDels[0]!.StocksLimit ? itemDels[0]!.StocksLimit?.toString() : '0';
    itemDetails.netWeight = itemDels[0]!.netWeight ? itemDels[0]!.netWeight?.toString() : '0';
    itemDetails.grossWeight = itemDels[0]!.grossWeight ? itemDels[0]!.grossWeight?.toString() : '0';
    itemDetails.mrpp = itemDels[0]!.MRP! ? (itemDels[0]!.MRP)?.toString() : "0";
    itemDetails.unitId = itemDels[0]!.unitOfMeasureId ?? 0;
    debugger
    console.log(itemDels[0]!, 'itemDels>>')
    itemDetails.oldmrp = itemDels[0]!.MRP! ? (itemDels[0]!.MRP)?.toString() : "0";
    itemDetails.b2BoldPrice = itemDels[0]!.B2BNewPrice! ?? 0;
    itemDetails.b2ColdPrice = itemDels[0]!.B2CNewPrice! ?? 0;
    itemDetails.b2BnewPrice = itemDels[0]!.B2BNewPrice! ?? 0;
    itemDetails.b2CNewPrice = itemDels[0]!.B2CNewPrice! ?? 0;
    itemDetails.b2Bper = 10
    itemDetails.b2Cper = 12
    itemDetails.effectdate = itemDels[0]!.Effectdate ? new Date(itemDels[0]!.Effectdate)?.toISOString()?.substr(0, 10) : new Date()?.toISOString()?.substr(0, 10);
    itemDetails.batchNo = ""
    itemDetails.coinqty = itemDels[0]!?.coinchange ?? 0;
    itemDetails.msqty = itemDels[0]!?.minsalqty ?? 0;
    itemDetails.ismsqty = (itemDetails.msqty != 0) ? true : false;
    setItemDetail(itemDetails);
    setSuppliers(supplierDetls);
  }
  const editSuppliers = (supplier, e) => {
    e.preventDefault();
    setSupplier(supplier!);
  }
  const deleteSupplier = (supplierId, e) => {
    e.preventDefault();
    setSuppliers(suppliers.filter(d => d.supplierId !== supplierId));
  }

  async function updateApiCall() {
    if (itemId !== undefined) {
      const [itemData, supplierData] = await Promise.all([getItemDetails(parseInt(itemId!)), getSupplierDetails(parseInt(itemId!))])
      console.log(itemData, 'itemData>>')
      mapItemData(itemData, supplierData);
    }
  }

  async function fetchPreLoadngData() {
    updateApiCall()
    const [itemmasterList, tempCategoryList] = await Promise.all([getItemMasters(), subCategoryStore?.loadCatergoryMaster(),
    loadBrands(), getTaxMaster(''), loadSupplierMastes(), loadSubcategory(), unitOfMeasurementStore.getUnitOfMeasurement()])
    setLoading(false)
    let updatedCategoryList = [...tempCategoryList];
    removeDuplicates(itemmasterList, 'itemCategory')?.map((each: any) => {
      if (each?.itemCategory && !tempCategoryList?.some((obj) => obj?.MC_Name === each?.itemCategory)) {
        let category = {
          MC_ID: tempCategoryList?.length + 1,
          MC_Name: each?.itemCategory
        }
        return updatedCategoryList?.push(category)
      }
    })
    console.log(updatedCategoryList, 'updatedCategoryList>>')
    setCategoryList([...updatedCategoryList])
  }

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      fetchPreLoadngData();
      isInitialRender.current = false;
    }
    return () => {
      // Cleanup logic (if needed)
    };
  }, [])

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>
              <div className='hrBox'>
                <h3>Item Master</h3>
              </div>
              <div>
                <div className='inputBoxLists accordionLists'>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Data</Accordion.Header>
                      <Accordion.Body>
                        <div className='row'>
                          <div className='col-sm-5'>
                            <div className='inputBox'>
                              <label>GSM Code</label>
                              <input type="text" name='gsmCode' value={itemDetails.gsmCode}
                                onChange={handleInputchange} 
                                className='' style={{ width: "100%" }} ></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-5'>
                            <div className='inputBox'>
                              <label>Extra Code</label>
                              <input type="text" name='extraCode' value={itemDetails.extraCode}
                                onChange={handleInputchange} 
                                className='' style={{ width: "100%" }} ></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-12'>
                            <div className='inputBox'>
                              <label>Item Name <span>*</span></label>
                              <input type="text" value={itemDetails.itemName} name='itemName'
                                onChange={handleInputchange}
                                className='' style={{ width: "100%" }} placeholder='Enter Name..'></input>
                              {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-12'>
                            <div className='inputBox'>
                              <label>Regional Item Name <span>*</span></label>
                              <input type="text" value={itemDetails.itemName} name='itemName'
                                onChange={handleInputchange}
                                className='' style={{ width: "100%" }} placeholder='Enter Name..'></input>
                              {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-12'>
                            <div className='inputBox'>
                              <label>Category <span>*</span></label>
                              <select className="form-selected" style={{ width: "100%" }} id="Category" name="itemCategory"
                                onChange={handleInputchange} value={itemDetails?.itemCategory}>
                                <option value="" selected>Select</option>
                                {tempCategoryList?.map((category: any) => {
                                  return (
                                    <option key={category?.MC_ID} value={category?.MC_Name}>{category?.MC_Name}</option>
                                  )
                                })}
                              </select>
                              {errors.itemCategory && <p style={{ color: 'red' }}>{errors.itemCategory}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          {/* <div className='col-sm-4'>
                            <div className='vertical-space-20'></div>
                            <button className='searchclearBtn' onClick={() => setNewCategory({ ...isNewCat, bool: true })} style={{ height: '44px', minWidth: 'fit-content', whiteSpace: 'nowrap' }} type='button'>New Category</button>
                          </div> */}
                          <Modal show={isNewCat?.bool} onHide={() => setNewCategory({ bool: false, itemCategory: '' })} className='SubmitModal inputFormBox'>
                            <Modal.Header closeButton><h4>Add Category</h4></Modal.Header>
                            <Modal.Body>
                              <div className='inputBoxLists'>
                                <div className='row'>
                                  <div className='col-sm-8'>
                                    <div className='inputBox'>
                                      <label>New Category<span>*</span></label>
                                      <input type="text" value={isNewCat?.itemCategory} name='itemCategory'
                                        onChange={(event) => setNewCategory({ ...isNewCat, itemCategory: event?.target?.value })}
                                        className='' style={{ width: "100%" }} placeholder='Enter Category..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                  </div>
                                  <div className='col-sm-4'>
                                    <div className='vertical-space-20'></div>
                                    <button className='searchclearBtn' onClick={() => {
                                      if (!tempCategoryList?.some((obj) => obj === isNewCat?.itemCategory)) {
                                        tempCategoryList?.push(isNewCat?.itemCategory);
                                        setCategoryList([...tempCategoryList]);
                                      }
                                      setNewCategory({ ...isNewCat, bool: false, itemCategory: '' });
                                    }} style={{ height: '44px', minWidth: 'fit-content', whiteSpace: 'nowrap' }} type='button'>ADD</button>
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                          <div className='col-sm-6'>
                            <div className='inputBox'>
                              <label>HSN Code <span>*</span></label>
                              <input type="text" name='hsnCode' value={itemDetails.hsnCode}
                                onChange={handleInputchange}
                                className='' style={{ width: "100%" }} placeholder='HSN Code..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-6'>
                            <div className='inputBox'>
                              <label>Net Weight </label>
                              <input type="number" name='netWeight' value={itemDetails.netWeight}
                                onChange={(e) => setItemDetail({ ...itemDetails, netWeight: e.target.value?.toString() })}
                                className='' style={{ width: "100%" }} placeholder='Net Weight..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-6'>
                            <div className='inputBox'>
                              <label>Gross Weight</label>
                              <input type="number" name='grossWeight' value={itemDetails.grossWeight}
                                onChange={(e) => setItemDetail({ ...itemDetails, grossWeight: e.target.value?.toString() })}
                                className='' style={{ width: "100%" }} placeholder='Gross Weight..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-6'>
                            <div className='inputBox'>
                              <label>Sub Category <span>*</span></label>
                              <Autocomplete size="small"
                                disablePortal
                                disabled={!loadSubcategories?.length}
                                id="combo-box-demo"
                                options={loadSubcategories}
                                getOptionLabel={(option: any) => option.subCategoryName}
                                onChange={(event, val) => handleSubcategoryValue('subCategoryId', event, val)}
                                value={loadSubcategories?.find((option) => option.subCategoryName === itemDetails.subcategoryName)}
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '21ch' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select Subcategory..'
                                    name='subcategoryName'
                                  />}
                              />
                              {errors.subcategoryName && <p style={{ color: 'red' }}>{errors.subcategoryName}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='inputBox'>
                              <label>Brand <span>*</span></label>
                              <Autocomplete size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={loadbrands}
                                getOptionLabel={(option: any) => option.brandCompany}
                                value={loadbrands.find((option) => option.brandCompany === itemDetails.brandCompany)}
                                onChange={(event, val) => handlebrandValue('brand', event, val)}
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '21ch' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select Brand..'
                                    name='brand'
                                  />}
                              />
                              {errors.brand && <p style={{ color: 'red' }}>{errors.brand}</p>}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className='inputradio mt-3'>
                              <input type='checkbox' onChange={(e) => setItemDetail({ ...itemDetails, brandType: e.target.checked ? "OWNBRAND" : "" })}
                                className='mt-4 justifyContentStart' checked={itemDetails?.brandType === "OWNBRAND"} name='bundleQty' ></input>
                              <label className="mx-2">Own Brand</label>
                            </div>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        {itemDetails?.brandType === 'Own Brand' &&
                          <div className='row'>
                            <div className='col-sm-12'>
                              <div className='inputBox'>
                                <label>Bar Code <span>*</span></label>
                                <input type="text" name='extraCode' value={itemDetails.extraCode}
                                  onChange={handleInputchange}
                                  className='' style={{ width: "100%" }} placeholder='Bar Code..'></input>
                                {errors.extraCode && <p style={{ color: 'red' }}>{errors.extraCode}</p>}
                              </div>
                              <div className='vertical-space-10'></div>
                            </div>
                          </div>}
                        <div className='row'>
                          <div className='col-sm-4'>
                            <div className='inputBox'>
                              <label>MRP </label>
                              <input type="number" name='mrpp' value={itemDetails.mrpp} className=''
                                onChange={(e) => setItemDetail({ ...itemDetails, mrpp: e.target.value })}
                                style={{ width: "100%" }} placeholder='MRP..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='inputBox'>
                              <label>Effective Date <span>*</span></label>
                              <input type="date" name='effectdate' value={itemDetails?.effectdate}
                                onChange={handleInputchange}
                                className='' style={{ width: "100%" }}
                                placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                              {errors.effectdate && <p style={{ color: 'red' }}>{errors.effectdate}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='inputBox'>
                              <label>Purchase Price </label>
                              <input type="number" name='purchasePrice'
                                onChange={(e) => setItemDetail({ ...itemDetails, purchasePrice: e.target.value.toString() })}
                                value={itemDetails.purchasePrice} className=''
                                style={{ width: "100%" }} placeholder='Pur Price..'
                                step="any"
                                >                                 
                                </input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className="col-sm-4">
                            <div className='inputradio mt-3'>
                              <input type='checkbox' onChange={(e) => setItemDetail({ ...itemDetails, coinqty: e.target.checked ? 1 : 0 })}
                                className='mt-4 justifyContentStart' checked={itemDetails?.coinqty == 1} name='coinqty' ></input>
                              <label className="mx-2">Coin Change</label>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className='inputradio mt-3'>
                              <input type='checkbox' onChange={(e) => setItemDetail({ ...itemDetails, ismsqty: e.target.checked ? true : false })}
                                className='mt-4 justifyContentStart' checked={itemDetails?.ismsqty} name='ismsqty' ></input>
                              <label className="mx-2">Min Sale</label>
                            </div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='inputBox'>
                              <label>Min Selling Qty </label>
                              <input value={itemDetails?.msqty} name='msqty' disabled={!itemDetails?.ismsqty}
                                onChange={handleInputchange}
                                className='' style={{ width: "100%" }}
                                placeholder="Enter Minimum Selling Qty"></input>
                              {errors.msqty && <p style={{ color: 'red' }}>{errors.msqty}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='inputBox'>
                              <label>Current Stock </label>
                              <input type="number" name='currentStock' value={itemDetails.currentStock}
                                onChange={(e) => setItemDetail({ ...itemDetails, currentStock: e.target.value?.toString() })}
                                className='' style={{ width: "100%" }} placeholder='Stock..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='inputBox'>
                              <label>Unit Of Measure <span>*</span></label>
                              <Autocomplete size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={unitOfMeasurementStore.unitOfMeasurementList}
                                getOptionLabel={(option: any) => option.unitName}
                                value={unitOfMeasurementStore.unitOfMeasurementList.find((option) => option.unitName === itemDetails.unitName)}
                                onChange={(event, val) => handleUOM('unitId', event, val)}
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '15ch' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select UOM..'
                                    name='unitId'
                                  />}
                              />
                              {errors.unitOfMeasure && <p style={{ color: 'red' }}>{errors.unitOfMeasure}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='inputBox'>
                              <label>Unit Type<span>*</span></label>
                              <select className="form-selected full-width"
                                name='unittype'
                                value={itemDetails?.unittype}
                                onChange={handleInputchange}
                              >
                                <option selected value="loosed">Loosed</option>
                                <option value="packed">Packed</option>
                              </select>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='inputBox'>
                              <label>Stocks Limit <span>*</span></label>
                              <input type="text" className='' style={{ width: "100%" }}
                                onChange={handleInputchange} placeholder='Stocks Limit..'
                                name='stocksLimit' value={itemDetails.stocksLimit}></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='inputBox'>
                              <label>Expiry Days <span>*</span></label>
                              <input type="number" className='' style={{ width: "100%" }}
                                onChange={handleInputchange} placeholder='Expiry Days..'
                                name='expiryDays' value={itemDetails.expiryDays}></input>
                              {errors.expiryDays && <p style={{ color: 'red' }}>{errors.expiryDays}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                        </div>
                        <div className='inputBox'>
                          <label>Comments <span>*</span></label>
                          <textarea className='' style={{ width: "100%" }} onChange={handleInputchange} placeholder='Comments..'
                            name='comments' value={itemDetails.comments}></textarea>
                        </div>
                        <div className='vertical-space-10'></div>
                        <div className='col-sm-8'>
                          <div className='inputBox'>
                            <label>Item Image <span>*</span></label>
                            <input type="file" className='' style={{ width: "60%" }} accept='image/*'
                              name='itemImage' onChange={handleChangeImage} />
                            {errors.itemImage && <p style={{ color: 'red' }}>{errors.itemImage}</p>}
                          </div>
                        </div>
                        {
                          image ? <div className='col-sm-2'>
                            <div className='inputBox'>
                              <Image style={{ width: '50px', height: '50px' }} src={itemDetails.imageData = URL.createObjectURL(image)} />
                            </div>

                          </div> : null
                        }{
                          itemDetails.imageData ? <div className='col-sm-2'>
                            <div className='inputBox'>
                              <Image alt='upload' src={`data:image/png;base64,${itemDetails.imageData}`}
                                style={{ width: '50px', height: '50px' }} />
                            </div>

                          </div> : null
                        }

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Tax</Accordion.Header>
                      <Accordion.Body>

                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='inputBox'>
                              <label>Tax Name <span>*</span></label>
                              <Autocomplete size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={itemMasterStore.taxMasters}
                                getOptionLabel={(option: any) => option.ledgerName}
                                onChange={(event, val) => handleTaxValue('taxId', event, val)}
                                value={itemMasterStore.taxMasters.find((option) => option.ledgerName === itemDetails?.taxName)}
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '21ch' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select Tax Name..'
                                    name='taxId'
                                  />}
                              />
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-6'>
                            <div className='inputBox'>
                              <label>Cess <span>*</span></label>
                              <Autocomplete size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={itemMasterStore.taxMasters}
                                getOptionLabel={(option: any) => option.ledgerName}
                                value={itemMasterStore.taxMasters.find((option) => option.ledgerName === itemDetails.cessName)}
                                onChange={(event, val) => handleCessValue('cessId', event, val)}
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '21ch' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select Cess..'
                                    name='cessId'
                                  />}
                              />
                            </div>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {itemDetails?.brandType !== "OWNBRAND" && <Accordion.Item eventKey="2">
                      <Accordion.Header>Suppliers</Accordion.Header>
                      <Accordion.Body>
                        <div className='inputBox'>
                          <label>Suppliers name <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadSuppliers}
                            value={supplierName}
                            onChange={(event, val) => handleSupplierValue('supplierId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '21ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Tax..'
                                name='supplierId'
                              />}
                          />
                          {errors.supplierId && <p style={{ color: 'red' }}>{errors.supplierId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                        <div className='inputBox'>
                          <label>Commission % </label>
                          <input type="text" name='commissionPercentage'
                            onChange={handleSupplier} value={supplier.commissionPercentage}
                            className='' style={{ width: "20%" }} placeholder='%'></input>
                          {errors.commissionPercentage && <p style={{ color: 'red' }}>{errors.commissionPercentage}</p>}
                        </div>
                        <div className='btnBox'>
                          <button className='dfBtn' type='button' onClick={addSupplier}>ADD</button>
                        </div>
                        <div className='tableBox'>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Supplier Id</th>
                                <th scope="col">Supplier Name</th>
                                <th scope="col">Commission %</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {suppliers.map((val, key) => {
                                return (
                                  <tr key={val.supplierId}>
                                    <td>{val.supplierId}</td>
                                    <td>{val.supplierName}</td>
                                    <td>{val.commissionPercentage}</td>
                                    <td><button className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={(e) => editSuppliers(val, e)} /></button></td>
                                    <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={(e) => deleteSupplier(val.supplierId, e)} /></button></td>
                                  </tr>
                                )
                              })
                              }

                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>}
                    {itemDetails?.unittype !== 'packed' &&
                      <Accordion.Item eventKey={`${itemDetails?.brandType !== "OWNBRAND" ? "3" : "2"}`}>
                        <Accordion.Header>UOM Conversion</Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className='col-sm-6'>
                              <div className='inputBox'>
                                <label>Purchase UOM <span>*</span></label>
                                <AutoComplete options={unitOfMeasurementStore?.unitOfMeasurementList?.length ?
                                  unitOfMeasurementStore?.unitOfMeasurementList : []} placeholder={'Select Purchase UOM...'}
                                  value={unitOfMeasurementStore?.unitOfMeasurementList?.find((obj) => obj?.unitId === itemDetails.purchaseuomid)?.unitName}
                                  emitOption={(option: any) => setItemDetail({ ...itemDetails, purchaseuomid: option?.unitId })}
                                  getOptionLabel='unitName' />
                                {errors.itemUom && <p style={{ color: 'red' }}>{errors.itemUom}</p>}
                              </div>
                              <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-6'>
                              <div className='inputBox'>
                                <label>Stock UOM <span>*</span></label>
                                <AutoComplete options={unitOfMeasurementStore?.unitOfMeasurementList?.length ?
                                  unitOfMeasurementStore?.unitOfMeasurementList : []} placeholder={'Select Stock UOM...'}
                                  value={unitOfMeasurementStore?.unitOfMeasurementList?.find((obj) => obj?.unitId === itemDetails.convuomid)?.unitName}
                                  emitOption={(option: any) => setItemDetail({ ...itemDetails, convuomid: option?.unitId })}
                                  getOptionLabel='unitName' />
                                {errors.coversionUom && <p style={{ color: 'red' }}>{errors.coversionUom}</p>}
                              </div>
                              <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-4'>
                              <div className='inputBox'>
                                <label>Qty <span>*</span></label>
                                <input type="number" name='qty'
                                  className=''
                                  value={itemDetails.convQty}
                                  onChange={(e) => setItemDetail({ ...itemDetails, convQty: Number(e.target.value) })}
                                  style={{ width: "100%" }} placeholder='Qty..'></input>
                              </div>
                              <div className='vertical-space-10'></div>
                            </div>
                          </div>
                          <div className='vertical-space-10'></div>
                        </Accordion.Body>
                      </Accordion.Item>}
                  </Accordion>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit' onClick={saveItemMaster}>Submit</button>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }

    </>
  );

});

export default ItemMaster1;
