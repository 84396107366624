import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { IPaymentInfo } from '../model/posmodal';
import { Autocomplete } from '@mui/material';
import { IPosSalescoinchange } from '../model';
import { Image, Button } from 'react-bootstrap';
import AutoComplete from '../../../common/shared/autoComplete';
import { removeDuplicates } from '../../../common/shared/utils';
import { each } from 'chart.js/dist/helpers/helpers.core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

interface PyamentOptionProps {
  filteredOptions: any,
  posDetail: any,
  show: boolean,
  returnAmt?: number,
  payabaleAmount: number,
  modalClose: () => void,
  handleRadioChange: (e) => void,
  errors: any,
  posProductList: Array<any>;
  processtoBill: (paymentAmount: IPaymentInfo, posSalescoinchange: IPosSalescoinchange[] | null) => void,
  sudexoAmt: number,
}

const PaymentOptionModal: React.FC<PyamentOptionProps> = ({ filteredOptions, posDetail, show, returnAmt, payabaleAmount, modalClose, handleRadioChange, posProductList, errors, processtoBill, sudexoAmt }) => {
  console.log(posProductList, 'posProductList>>')
  const [changeOpt, setChaneOpt] = useState('')

  console.log(changeOpt, "changeOptvalue");

  const [paymentAmount, setPaymentAmount] = useState<IPaymentInfo>(new IPaymentInfo())

  const [posSalescoinchange, setPosSalescoinchange] = useState<IPosSalescoinchange[]>([])

  const [error, setError] = useState<any>({})

  console.log(paymentAmount, "paymentAmountaasaasas");

  const [sumAmountPaid, setSumAmountPaid] = useState<number>(0)
  const [coinAmount, setcoinAmount] = useState<number>(0)
  const [balanceAmount, setBalanceAmount] = useState<number>(0)
  const [balanceAmountDisplay, setBalanceAmountDisplay] = useState<number>(0)
  const [isCheckQtyModal, setCheckQtyModal] = useState(false);
  const [productBatchDetails, setProductBatchDetails] = useState<any[]>([]);
  const [productDetails, setProductDetails] = useState<any[]>([]);
  const [tempBatchList, setTempBatchList] = useState(false);
  const [coinChangeAmout, setCoinChangeAmount] = useState<number>(0);
  const [barcodeNew, setBarcodeNew] = useState<string>("")
  const [coinChangeModal, setCoinChangeModal] = useState(false)

  function handleChangeOpt(event) {
    setCoinChangeModal(true);
    // setChaneOpt(event?.target?.value)
    // if(event.target.value === "CoinChange"){
    //   setCoinChangeModal(true);
    // }
  }

  const handleCheckQtyModalClose = () => setCheckQtyModal(false);

  function hanleCoinchange(item) {
    let posSaleChange = new IPosSalescoinchange()
    if (item?.itemid) {
      let itemQty = Math.floor(Number(Math.abs(balanceAmount) ?? 0) / Number(item?.BTCSellingRate));
      if (itemQty <= 0) {
        setCheckQtyModal(true)
      }
      posSaleChange = {
        amount: (itemQty * Number(item?.BTCSellingRate ?? 0)),
        barcode: item?.Barcode,
        batchcode: item?.batchno,
        itemid: item?.itemid,
        qty: itemQty,
        rate: Number(item?.BTCSellingRate ?? 0),
      }
      if (posSaleChange?.amount !== undefined && itemQty > 0) {
        setcoinAmount(posSaleChange?.amount)
        setBalanceAmountDisplay(0)
        setPaymentAmount({ ...paymentAmount, coinamount: Number(posSaleChange?.amount) })
      }
      console.log(posSaleChange, 'posSaleChange>>')
      let arrCoinChange = new Array<IPosSalescoinchange>();
      arrCoinChange?.push(posSaleChange);
      setPosSalescoinchange([...arrCoinChange])
      console.log(arrCoinChange, 'arrCoinChange');

    }
  }

  useEffect(() => {
    console.log(posSalescoinchange, 'posSalescoinchangeueeffect')
  }, [posSalescoinchange])

  function updatePayment() {
    let sumOfAmount: number = 0;
    sumOfAmount = (paymentAmount?.cardAmount! ?? 0) + (paymentAmount?.cashAmount! ?? 0) + (paymentAmount?.onlinebankTR! ?? 0) + (paymentAmount?.onlinestoreorder! ?? 0) +
      (paymentAmount?.qRcodeAmount! ?? 0) + (paymentAmount?.redeemAmount! ?? 0) + (paymentAmount?.salesReturn! ?? 0) + (paymentAmount?.sodexo! ?? 0);

    let amountReturn: number = payabaleAmount - (sumOfAmount - (paymentAmount?.coinChange! ?? 0));
    setBalanceAmount(amountReturn)
    setBalanceAmountDisplay(amountReturn)
    setSumAmountPaid(sumOfAmount)
  }

  useEffect(() => {
    updatePayment()
  }, [paymentAmount, returnAmt, posDetail, sudexoAmt])

  function handleAmountPaid(event) {
    const { name, value } = event?.target;
    setPaymentAmount({ ...paymentAmount, [name]: Number(value) })
  }


  function confirmOrder(event) {
    let error: any = {}
    console.log(paymentAmount, Number(coinAmount), 'paymentAmount555');
    if ((sumAmountPaid + coinAmount) >= payabaleAmount) {
      if (changeOpt === 'Cash' || changeOpt === 'CoinChange') {
        processtoBill(paymentAmount, productDetails)
      } else if (Math.abs(balanceAmount) <= 0) {
        processtoBill(paymentAmount, productDetails)
      } else {
        processtoBill(paymentAmount, productDetails)
      }
    } else {
      error.amountShortage = 'Paid amount is not eqaul to Payable Amount'
    }
    setError({ ...error })
  }

  async function handleItemDetails(val: any) {
    const itemObj = posProductList?.find((obj) => obj?.itemid === val?.itemid)
    let itemBatchList: any[] = []
    itemBatchList = posProductList?.filter((obj) => obj?.itemid === Number(itemObj.itemid))

    if (itemBatchList.length === 1) {
      handlebatchNo(itemBatchList[0]);
    } else if (itemBatchList.length > 1) {
      setProductBatchDetails(itemBatchList)
      setTempBatchList(true);
    }
  }

  const existingItem = (obj: any) => {
    const updateQty = productDetails.map((each) => {
      if (each.batchno === obj.batchno) {
        each.itemQty = Number(each?.itemQty) + 1
        each.toAmount = Number(each?.itemQty) * Number(each?.BTCSellingRate)
      }
      return each;
    })
    setProductDetails(updateQty);
  }

  const notExistingItem = (value: any) => {
    value.itemQty = 1
    value.toAmount = 1 * value?.BTCSellingRate
    const data = value;
    console.log(data, "datacomminggdarar");
    setProductDetails([...productDetails, value]);
  }

  const handlebatchNo = async (value: any) => {
    // setCoinChangeAmount(coinChangeAmout + Number(value?.BTCSellingRate))
    // setPaymentAmount({ ...paymentAmount, coinChange: coinChangeAmout + Number(value?.BTCSellingRate) })

    let isexistsItem = productDetails?.some((items) => {
      return items?.itemid === value?.itemid && items?.batchno === value?.batchno
    })
    if (isexistsItem) {
      existingItem(value);
    } else {
      notExistingItem(value);
    }
    setTempBatchList(false);
  }

  useEffect(() => {
    const totalPrice = productDetails.reduce((acc, product) => acc + product?.toAmount, 0);
    setPaymentAmount({ ...paymentAmount, coinChange: totalPrice })
  }, [productDetails])

  const handleRemove = (id: number) => {
    const removedProduct = productDetails.filter((item) => item.itemid !== id)
    setProductDetails(removedProduct);
  }

  console.log(posProductList, "posProductList");

  const handleBarcode = (event: any) => {
    if (event.key === "Enter") {
      const itemObj = posProductList?.find((obj) => obj?.Barcode === barcodeNew)
      let itemBatchList: any[] = []
      itemBatchList = posProductList?.filter((obj) => obj?.itemid === Number(itemObj.itemid))

      if (itemBatchList.length === 1) {
        handlebatchNo(itemBatchList[0]);
      } else if (itemBatchList.length > 1) {
        setProductBatchDetails(itemBatchList)
        setTempBatchList(true);
      }
    }
  }

  const filteredData = posProductList?.filter((each) => each?.coinchange === 1)

  return (
    <>
      <Modal show={show} onHide={modalClose} className='PriceHistoryModel paymentOptionModel' style={{ marginTop: '73px' }}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Cash  :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.cashAmount} onChange={handleAmountPaid} name="cashAmount" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Redeem Amount:</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount.redeemAmount = (posDetail?.isReedem && posDetail?.redeemPointsamt) ? Number(posDetail?.redeemPointsamt) : 0}
                aria-readonly name="redeemAmount" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Sales Return  :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount.salesReturn = Number(returnAmt! ?? 0)} name="salesReturn" aria-readonly />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Card  :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.cardAmount} onChange={handleAmountPaid} name="cardAmount" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>QR Code:</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.qRcodeAmount} onChange={handleAmountPaid} name="qRcodeAmount" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Online Store Order :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.onlinestoreorder} onChange={handleAmountPaid} name="onlinestoreorder" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Online Bank Transfer :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.onlinebankTR} onChange={handleAmountPaid} name='onlinebankTR' />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>SODEXO:</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount.sodexo = Number(Math.round(sudexoAmt) ?? 0)} onChange={handleAmountPaid} name="sodexo" />
            </div>

            {/* New */}
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Coin Change:</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.coinChange} onChange={handleAmountPaid} name='coinChange' />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Payable Amount :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined" value={(payabaleAmount)?.toFixed(0)} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Total Amount :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined" value={sumAmountPaid} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: balanceAmountDisplay > 0 ? 'red' : 'green', }}>{balanceAmountDisplay < 0 ? "Amount should be Return" : "Amount Should be Paid"} :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined" value={Math.abs(balanceAmountDisplay)?.toFixed(0)} />
            </div>
            {error?.amountShortage && <p style={{ color: 'red' }}>{error?.amountShortage}</p>}
          </div>
          <div style={{ border: '1px dashed gray', borderTop: '1px', borderRight: '1px', borderLeft: '1px', padding: '4px 0px' }}></div>
          <div>
            <div className='vertical-space-10'></div>
            <div className='btnBox'>
            <button className='dfBtn width-auto' type='submit'
            style={{ backgroundColor: 'green', color: 'white', float: 'right'}} 
            onClick={handleChangeOpt}>Coin Change</button>
            </div>
            {/* <RadioButtonsGroup paymentMode={changeOpt} handleChange={handleChangeOpt} radioArray={[{ key: 'Cash', value: 'Cash' }, { key: 'Coin Change', value: 'CoinChange' }]} />
            {/* <div>
              <Autocomplete size="small"
                disablePortal
                id="combo-box-demo"
                options={posProductList?.filter((each) => each?.coinchange === 1)}
                disabled={changeOpt !== 'CoinChange'}
                getOptionLabel={(option: any) => option.itemname}
                onChange={(event, val) => hanleCoinchange(val)}
                renderInput={(params: any) =>
                  <TextField  {...params}
                    id="outlined-size-small"
                    color='info'
                    size="small"
                    type="text"
                    placeholder='Coin change..'
                    name='coinChange'
                  />}
              />
            </div> */} 
          </div>
          <div style={{ border: '1px dashed gray', borderTop: '1px', borderRight: '1px', borderLeft: '1px', padding: '4px 0px' }}></div>
          <div>
            <div className='vertical-space-10'></div>
            <div className='btnBox'>
              <button className='dfBtn width-auto' type='submit' onClick={confirmOrder}>Confirm Bill</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isCheckQtyModal} onHide={handleCheckQtyModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../../gsmecom/images/warning.png')} />
            </div>
            <h4>Please Select Correct Item</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCheckQtyModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={coinChangeModal} size='xl' onHide={() => setCoinChangeModal(false)} className='PriceHistoryModel' style={{ marginTop: '15px' }}>
        <Modal.Header closeButton>
          <Modal.Title>Item Batch Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className='vertical-space-20'></div>
            <div className="flex">
              <div className='inputFormBox'>
                <div className='flex-center-between'>
                  <div className='col-md-6'>
                    <AutoComplete
                      placeholder="Select Product / Item"
                      emitOption={(val) => handleItemDetails(val)}
                      // value={itemInward?.inwardItemName}
                      // options={posProductList?.filter((each) => each?.coinchange === 1)}
                      options={removeDuplicates(filteredData, 'itemname')}
                      getOptionLabel="itemname"
                      clsName="full-width"
                    />
                    <div className='vertical-space-20'></div>
                  </div>
                  <div className='col-md-6'>
                    <TextField size="small" placeholder="Enter Barcode"
                      autoFocus={true}
                      value={barcodeNew}
                      onChange={(e) => setBarcodeNew(e.target.value)}
                      onKeyDown={handleBarcode}
                      // className="full-width"
                      style={{ marginLeft: "10px", width: "90%" }}
                    />
                    <div className='vertical-space-20'></div>
                  </div>
                </div>
                <div className='col-md-12'>
                  {tempBatchList === true &&
                    <div className='tableBox'>
                      <table style={{ overflow: 'auto' }}>
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"> Item Name</th>
                            <th scope="col"> Batch No</th>
                            <th scope="col"> MRP</th>
                            <th scope="col">Available Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productBatchDetails?.map((val, key) => {
                            return val.stock > 0 && (
                              <tr key={key} className='self-center'>
                                <td>
                                  <input type="checkbox" className='itemCheckbox' onChange={(e) => handlebatchNo(val)}></input>
                                </td>
                                
                                <td>{val.itemname}</td>
                                <td>{val.batchno}</td>
                                <td>{val.Mrprate}</td>
                                <td>{val?.stock}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                  <div className='vertical-space-20'></div>

                  <div className='tableBox mt-6'>
                    <table style={{ overflow: 'auto' }}>
                      <thead>
                        <tr>
                          {/* <th scope="col"></th> */}
                          <th scope="col"> Item Name</th>
                          <th scope="col"> Batch No</th>
                          <th scope="col"> MRP</th>
                          <th scope="col"> Qty</th>
                          <th scope="col"> Selling Rate</th>
                          <th scope="col"> Amount</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {productDetails?.map((val, key) => {
                          return val.stock > 0 && (
                            <tr key={key} className='self-center'>
                              {/* <td>
                                <input type="checkbox" className='itemCheckbox'></input>
                              </td> */}
                              <td>{val.itemname}</td>
                              <td>{val.batchno}</td>
                              <td>{val.Mrprate}</td>
                              <td>{val?.itemQty || 1}</td>
                              <td>{val?.BTCSellingRate}</td>
                              <td>{val?.toAmount}</td>
                              <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => handleRemove(val.itemid)} /></button></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='vertical-space-10'></div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>

  )
}
export default PaymentOptionModal;

function RadioButtonsGroup({ paymentMode, handleChange, radioArray }: { paymentMode: string, handleChange: (event: any) => void, radioArray: Array<{ key: string, value: string }> }) {
  return (
    <FormControl style={{ width: '100%' }}>
      <FormLabel id="demo-radio-buttons-group-label">Change Option</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={paymentMode}
        onChange={handleChange}
      >
        {radioArray?.map((each, index) => {
          return (
            <FormControlLabel key={index} id={each?.key} value={each?.value} control={<Radio />} label={each?.key} />
          )
        })}
      </RadioGroup>
    </FormControl>
  );
}
