import React, { useEffect, useRef, useState } from "react"
import { Spinner } from "react-bootstrap";
import useStores from "../../hooks";
import SelectSearchCriteria from "../../common/shared/selectSearchCriteria";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPenToSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Pagination, Stack } from "@mui/material";
import { currentPageDatas } from "../../common/shared/utils";

const RejectionConversionList = () => {

    const navigate = useNavigate()

    const { warehouseStores, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const [rejecConvJson, setRejecConvJson] = useState<any[]>([]);

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [loading, setLoading] = useState(true);

    function handleCreate() {
        navigate('/Admin/RejectionConversion')
    }

    async function fetchInitialLoad() {
        const userRights = await userCreationStore?.getUserRight('Rejection_conversions')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const rejeConvJSON = await warehouseStores.loadRejectionconversionjson()
        setRejecConvJson([...rejeConvJSON])
        setLoading(false)
    }
    const isCurrenPage = useRef(true)
    useEffect(() => {
        if (isCurrenPage.current) {
            fetchInitialLoad()
            isCurrenPage.current = false;
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (rejecConvJson?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(rejecConvJson)
        }
    }, [rejecConvJson])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(rejecConvJson, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className="" style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='full-width'>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>Rejection Conversion List</h3>
                            </div>
                            <div className='vertical-space-20'></div>

                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={rejecConvJson}
                                searchTitles={[{ key: "itemname", value: "Item Name" }, { key: "convitemname", value: "Conversion Item" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{rejecConvJson?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>

                            <div className='inputBoxLists'>
                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Barcode</th>
                                                <th scope="col">Batch No</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Conversion Item</th>
                                                <th scope="col">Rejection Stock</th>
                                                <th scope="col">Batch No</th>
                                                <th scope="col">Reason</th>
                                                <th scope="col">Create</th>
                                                <th scope="col">Update</th>
                                                <th scope="col">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                                (isSearch ? filteredData : currentPageData)?.map((items, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{items?.itemname}</td>
                                                            <td>{items?.Barcode}</td>
                                                            <td>{items?.Batchno}</td>
                                                            <td>{items?.Qty}</td>
                                                            <td>{items?.convitemname}</td>
                                                            <td>{items?.ConvQty}</td>
                                                            <td>{items?.ConvBatchno}</td>
                                                            <td></td>
                                                            <td><button disabled={!userRights?.add} className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                                                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default RejectionConversionList;