import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const DamageEntryApprovalDetail = () => {

    const { warehouseStores, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const navigate = useNavigate()

    const [loosedConversionDetail, setLoosedConversinDetail] = useState<any[]>([])
    const [loosedConversionItems, setLoosedConversionItems] = useState<any[]>([])

    const [isLoading, setLoading] = useState(true);
    const [isDetail, setDetail] = useState(false);

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [isSuccessModal, setSuccessModal] = useState(false)
    const [isFailureModal, setFailureModal] = useState(false)

    const handleCreate = () => {
        navigate('/Admin/DamageEntryApproval');
    }

    const deletedamageappMaster = async (inwardNo) => {
        setLoading(true);
        const status = await warehouseStores.deletedamageappMaster(inwardNo);
    
        if (status === 'Success') {
          fetchPreLoadingData();
          setLoading(false);
          setSuccessModal(true);
        } else {
          setLoading(false);
          setFailureModal(true);
        }
      }

    async function loadDetails(id) {
        const loosedConversionItems = await warehouseStores.loadDamageEntryApprovalDetails(id)
        setLoosedConversionItems([])
        setLoosedConversionItems([...loosedConversionItems])
        setDetail(true)
    }

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
      setSuccessModal(false);
      fetchPreLoadingData();
    }

    const isInitialRender = useRef(true);

    async function fetchPreLoadingData() {
        const userRights = await userCreationStore?.getUserRight('Damage_entry_approval')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const loosedConversionDetail = await warehouseStores.loadDamageEntryApprovalDetails()
        setLoosedConversinDetail([...loosedConversionDetail])
        setLoading(false)
    }

    useEffect(() => {
        if (isInitialRender.current) {
            fetchPreLoadingData();
            isInitialRender.current = false;
        }
        return () => { };
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (loosedConversionDetail?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(loosedConversionDetail)
        }
    }, [loosedConversionDetail])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(removeDuplicates(loosedConversionDetail, 'EntryNo')?.slice()?.sort((a, b) => b.EntryNo - a.EntryNo), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {isLoading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> :
                <div className='container-fluid'>
                    <div className='outletInputField inputFormBox LgInputField'>
                        <div className='inputBoxLists'>
                            <div className='ItemInwardInputBox'>
                                <div className='vertical-space-20'></div>
                                <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={loosedConversionDetail}
                                    searchTitles={[{ key: "Warename", value: "Warehouse Name" }]}
                                    emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                                <div className='vertical-space-20'></div>
                                <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='btnBox'>
                                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{loosedConversionDetail?.length} Records</button>
                                    </div>
                                    <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                    </Stack>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='tableListDetails'>
                                    <table className="tables table-striped">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Entry No</th>
                                                <th scope='col'>Entry Date</th>
                                                {/* <th scope='col'>Warehouse / Outlet name</th> */}
                                                <th scope='col'></th>
                                                <th scope='col'>Create </th>
                                                {/* <th scope='col'>Update </th> */}
                                                <th scope='col'>Delete </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                                (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{val?.EntryNo}</td>
                                                            <td>{moment(val.EntryDate).format('DD-MMM-YYYY')}</td>
                                                            {/* <td>{val?.outletid ? val?.outletName : val.Warename}</td> */}
                                                            <td><button disabled={!userRights?.view} onClick={() => loadDetails(val?.EntryNo)}>Detail</button></td>
                                                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                            {/* <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td> */}
                                                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deletedamageappMaster(val?.EntryNo)} /></button></td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                        </tbody>
                                    </table>
                                </div>

                                <Modal show={isDetail} onHide={() => setDetail(false)} className='PriceHistoryModel'>
                                    <Modal.Header closeButton>
                                        <h4>Details</h4>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='tableBox'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Barcode</th>
                                                        <th scope="col">Batch No</th>
                                                        <th scope="col">Qty</th>
                                                        <th scope="col">UOM</th>
                                                        <th scope='col'>Rejection Qty</th>
                                                        <th scope='col'>Return Qty</th>
                                                        <th scope='col'>Reuse Qty</th>
                                                        <th scope='col'>Damage From</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ verticalAlign: 'middle' }}>
                                                    {loosedConversionItems?.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{val?.itemname}</td>
                                                                <td>{val?.Barcode}</td>
                                                                <td>{val?.batchno}</td>
                                                                <td>{val?.qty}</td>
                                                                <td>{val?.Unitname}</td>
                                                                <td>{val?.RejectionQty}</td>
                                                                <td>{val?.ReturnQty}</td>
                                                                <td>{val?.ReuseQty}</td>
                                                                <td>{val?.damagefrom === 'Warehouse' ? val?.Warename : val?.outletName}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                        <Modal.Body>
                                          <div className='Details Success'>
                                            <div className='imgBox'>
                                              <Image src={require('../../../gsmecom/images/checked.png')} />
                                            </div>
                                            <h4>Succesfully Deleted</h4>
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button variant="secondary" onClick={handleSuccessModalClose}>
                                            Ok
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                      <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                        <Modal.Body>
                                          <div className='Details Success'>
                                            <div className='imgBox'>
                                              <Image src={require('../../../gsmecom/images/warning.png')} />
                                            </div>
                                            <h4>Failed</h4>
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button variant="secondary" onClick={handleFailureModalClose}>
                                            Ok
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default DamageEntryApprovalDetail;