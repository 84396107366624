import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { Container, Modal, Button, Image } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { CoponOffermasterList, IOfferDisount, Qtyofferdetail, ValueOffermasterList } from './model';
import useStores from '../../hooks';
import { manageDiscountValidation, saveValidation } from './validation';
import ProgressBar from '../../common/shared/progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { dblClick } from '@testing-library/user-event/dist/click';
import { useParams } from 'react-router-dom';
import plans from 'razorpay/dist/types/plans';
import Cookies from 'js-cookie';

const ManageOffersOrDiscounts = observer((): JSX.Element => {
  const [offerDiscount, setOfferDiscount] = useState<IOfferDisount>({});
  const [qtyofferdetail, setQtyofferdetail] = useState<Qtyofferdetail>({});
  const [qtyofferdetails, setQtyofferdetails] = useState<Qtyofferdetail[]>([]);
  const { offerId } = useParams<{ offerId: string }>();
  const [valueOffermasterList, setValueOffermasterList] = useState<ValueOffermasterList>({});
  const [valueOffermasterLists, setValueOffermasterLists] = useState<ValueOffermasterList[]>([]);

  const [coponOffermasterList, setCoponOffermasterList] = useState<CoponOffermasterList>({});
  const [coponOffermasterLists, setCoponOffermasterLists] = useState<CoponOffermasterList[]>([]);
  const [errors, setErrors] = useState<any>({});
  const { supplierMasterStore,offerDiscountStore, purchaseBillStore } = useStores();
  const { getOfferDiscountsDetails, getOfferDiscounts } = offerDiscountStore;
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isQtyoffer, setQtyOffer] = useState(false);
  const [isCoponOffer, setcoponOffer] = useState(false);
  const [isValueOffer, setValueOffer] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  console.log(qtyofferdetails, 'qtyofferdetails');
  const handleChangeview = () => {
    navigate('/Admin/ManageOffersOrDiscounts/list');
  }
  const handleSuccessModalClose = () => {
    navigate('/Admin/ManageOffersOrDiscounts/list');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const handleValueOffer = (event: any) => {
    const { name, value } = event.target;
    if (name === 'to_QV' && value !== undefined) {
      if (value > qtyofferdetail.from_QV!) {
        errors.from_QV = 'from value added more from the to value';
      }
    }
    setValueOffermasterList({ ...valueOffermasterList, [name]: value })
  }
  const addValueOffer = () => {
    error = manageDiscountValidation(offerDiscount, qtyofferdetail, valueOffermasterList, coponOffermasterList);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setValueOffermasterLists([...valueOffermasterLists, valueOffermasterList]);
      setValueOffermasterList({ ...valueOffermasterList, ['from_QV']: '', ['to_QV']: '', ['discount_PV']: '', ['dis_Mode']: 'none' });
    }
  }

  async function getManageOfferDet() {
    if (offerId !== undefined) {
      try {
        const offdata = await offerDiscountStore.getOfferDiscounts();
        console.log(offdata, 'offdata');

        const filteredOffData = offdata.filter(item => item.offerId == offerId);
        console.log(filteredOffData, 'filteredOffData');        
 
        const offDetdata = await offerDiscountStore.getOfferDiscountsDetails(offerId);
        console.log(offDetdata, 'offDetdata');
        console.log(offerId, 'offerId');

        if (Array.isArray(offDetdata) && offDetdata.length > 0) {

          const updatedQtyoffArray = offDetdata.map(plan => ({
            ...qtyofferdetail,
            rowId: Number(plan.offerId), 
            from_QV: plan.from_QV ?? '',
            to_QV: plan.to_QV ?? '',
            discount_PV: plan.discount_PV ?? '',
            dis_Mode: plan.dis_Mode ?? '',
            couponName: plan.copponName ?? ''
          }));
        
          setQtyofferdetails(updatedQtyoffArray);
        
          setValueOffermasterLists(updatedQtyoffArray);

          setCoponOffermasterLists(updatedQtyoffArray);
          
        const updatedMasters = {
          ...offerDiscount,
          offerId: Number(filteredOffData[0]?.offerId),
          offerName: filteredOffData[0]?.offerName,
          offerType: filteredOffData[0]?.offerType,
          qtyofferdetail: [
            ...(offerDiscount.qtyofferdetail || []), 
            ...updatedQtyoffArray
          ],
          valueOffermasterList: [
            ...(offerDiscount.valueOffermasterList || []), 
            ...updatedQtyoffArray
          ],
          coponOffermasterList: [
            ...(offerDiscount.coponOffermasterList || []), 
            ...updatedQtyoffArray
          ]
        };

        handleChangeInput({
          target: { name: 'offerType', value: filteredOffData[0]?.offerType },
        });

        setOfferDiscount(updatedMasters);
        console.log(updatedMasters, 'updatedMasters');
        }

      } catch (error) {

      }
    }
  }

  const handleQtyOffer = (event: any) => {
    const { name, value } = event.target;
    if (name === 'to_QV' && value !== undefined) {
      if (Number(value) < Number(qtyofferdetail.from_QV)!) {
        errors.from_QV = 'from quantity added more from the to quantity';
      } else {
        errors.from_QV = null;
      }
    }
    setQtyofferdetail({ ...qtyofferdetail, [name]: value })
  }
  const addQtyOffer = async (e) => {
    error = manageDiscountValidation(offerDiscount, qtyofferdetail, valueOffermasterList, coponOffermasterList);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      await setQtyofferdetails([...qtyofferdetails, qtyofferdetail]);
      setQtyofferdetail({ ...qtyofferdetail, ['from_QV']: '', ['to_QV']: '', ['discount_PV']: '', ['dis_Mode']: 'none' });
    }
  }

  const handleCoponOffer = (event: any) => {
    const { name, value } = event.target;

    setCoponOffermasterList({ ...coponOffermasterList, [name]: value })
  }
  const addCoponOffer = () => {
    error = manageDiscountValidation(offerDiscount, qtyofferdetail, valueOffermasterList, coponOffermasterList);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setCoponOffermasterLists([...coponOffermasterLists, coponOffermasterList]);
      setCoponOffermasterList({ ...coponOffermasterList, couponName: '', dis_Mode: '', discount_PV: '' })
    }
  }
  let error: any = {};
  const saveOfferDiscounts = async () => {

    offerDiscount.valueOffermasterList = valueOffermasterLists;
    offerDiscount.qtyofferdetail = qtyofferdetails;
    offerDiscount.coponOffermasterList = coponOffermasterLists;
    error = saveValidation(offerDiscount);
    setErrors(error);

    if (Object.keys(error).length === 0) {
      setLoading(true);
      if (offerId !== undefined) {
        const status = await offerDiscountStore.saveOfferDiscounts(offerDiscount);
        if (status === "Success") {
            setLoading(false);
            setSuccessModal(true);
        } else {
            setLoading(false);
            setFailureModal(true);
        }
      }
      else
      {
        const entryNo = await purchaseBillStore.getPurchaseBillEntryNo('OfferMaster');
        offerDiscount.offerId = entryNo.entryNo + 1;
        const status = await offerDiscountStore.saveOfferDiscounts(offerDiscount);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true)
        }
      } 
    }
  }
  const handleQtyDelete = (rowId) => {
    setQtyofferdetails(qtyofferdetails.filter(m => m.rowId !== rowId));
    const updateDetails = (offDetdata: Qtyofferdetail[]) => {
    const updatedQtyoffDelete = offDetdata.map(plan => ({
      ...qtyofferdetail,
      rowId: Number(plan.rowId), 
      from_QV: plan.from_QV,
      to_QV: plan.to_QV,
      discount_PV: plan.discount_PV,
      dis_Mode: plan.dis_Mode,
    }));
    setQtyofferdetail(qtyofferdetail)
  }
  
  }
  const handleCouponDelete = (rowId) => {
    setCoponOffermasterLists(coponOffermasterLists.filter(m => m.rowId !== rowId))
    const updateDetails = (offDetdata: Qtyofferdetail[]) => {
      const updatedQtyoffDelete = offDetdata.map(plan => ({
        ...coponOffermasterList,
        rowId: Number(plan.rowId), 
        from_QV: plan.from_QV,
        to_QV: plan.to_QV,
        discount_PV: plan.discount_PV,
        dis_Mode: plan.dis_Mode,
      }));
      setCoponOffermasterList(coponOffermasterList)
  }
}

  const handleValueDelete = (rowId) => {
    setValueOffermasterLists(valueOffermasterLists.filter(m => m.rowId !== rowId))
    const updateDetails = (offDetdata: Qtyofferdetail[]) => {
      const updatedQtyoffDelete = offDetdata.map(plan => ({
        ...valueOffermasterList,
        rowId: Number(plan.rowId), 
        from_QV: plan.from_QV,
        to_QV: plan.to_QV,
        discount_PV: plan.discount_PV,
        dis_Mode: plan.dis_Mode,
      }));
      setValueOffermasterList(valueOffermasterList)
  }
}

  function handleChangeInput(event: any) {

    const { name, value } = event.target;
    if (name === 'offerType' && value === '') {
      setQtyOffer(false);
      setcoponOffer(false);
      setValueOffer(false);
    }
    if (value === 'Qty Discount') {
      setQtyOffer(true);
      setcoponOffer(false);
      setValueOffer(false);
    }
    if (value === 'Value Discount') {
      setValueOffer(true);
      setQtyOffer(false);
      setcoponOffer(false);
    }
    if (value === 'Coupon Discount') {
      setcoponOffer(true);
      setQtyOffer(false);
      setValueOffer(false);
    }
    setOfferDiscount({ ...offerDiscount, [name]: value })
  }
  const handleFailureModalClose = () => setFailureModal(false);

  useEffect(() => {

  }, [qtyofferdetails])


  // useEffect(() => {
  //   getManageOfferDet();
  //   return () => { }
  // }, []);

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Manage_offer_discounts';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);
 
        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              getManageOfferDet();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/ManageOffersOrDiscounts/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }
       else {
        setLoadModal(true)

       }
      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

  return (
    <Container>
      <>
        {
          isLoading ? <ProgressBar />
            :
            <>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Manage Offers /Discounts</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Offer Name <span>*</span></label>
                        <input type="text" className=''
                          name='offerName'
                          value={offerDiscount.offerName}
                          onChange={handleChangeInput}
                          style={{ width: "100%" }} placeholder='Offer Name..'></input>
                        {errors.offerName && <p style={{ color: 'red' }}>{errors.offerName}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-3'>
                      <div className='inputBox'>
                        <label>Offer type <span>*</span></label>
                        <select className="form-selected"
                          name='offerType'
                          value={offerDiscount.offerType}
                          onChange={handleChangeInput}
                          style={{ width: "100%" }} id="OfferType">
                          <option value="" selected>Not Select</option>
                          <option value="Qty Discount">Qty Discount</option>
                          <option value="Value Discount">Value Discount</option>
                          <option value="Coupon Discount">Coupon Discount</option>
                        </select>
                        {errors.offerType && <p style={{ color: 'red' }}>{errors.offerType}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    {
                      isQtyoffer ?
                        <><div className='col-md-12'>
                          <div className='inputBorderBottom'></div>
                          <div className='vertical-space-10'></div>
                          <h5>Qty Discount</h5>
                          <div className='vertical-space-10'></div>
                        </div>
                          <div className='col-md-2'>
                            <div className='inputBox'>
                              <label>From qty<span>*</span></label>
                              <input type="text" className='fullInput'
                                name='from_QV'
                                value={qtyofferdetail.from_QV}
                                onChange={handleQtyOffer}
                                style={{ width: "100%" }} placeholder='qty..'></input>
                              {errors.from_QV && <p style={{ color: 'red' }}>{errors.from_QV}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-2'>
                            <div className='inputBox'>
                              <label>To qty<span>*</span></label>
                              <input type="text"
                                name='to_QV'
                                value={qtyofferdetail.to_QV}
                                onChange={handleQtyOffer}
                                className='fullInput' style={{ width: "100%" }} placeholder='qty..'></input>
                              {errors.to_QV && <p style={{ color: 'red' }}>{errors.to_QV}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-4'>
                            <div className='inputBox'>
                              <label>Discount<span>*</span></label>
                              <input type="text"
                                name='discount_PV'
                                value={qtyofferdetail.discount_PV}
                                onChange={handleQtyOffer}
                                className='form-control fullInput' placeholder='Discount..'></input>
                              {errors.discount_PV && <p style={{ color: 'red' }}>{errors.discount_PV}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-2'>
                            <div className='inputBox'>
                              <label>Mode<span>*</span></label>
                              <select className="form-selected full-width"
                                name='dis_Mode'
                                value={qtyofferdetail.dis_Mode}
                                onChange={handleQtyOffer}
                                id="Mode">
                                <option selected>None</option>
                                <option value="Dis%">Dis %</option>
                                <option value="Value">Value</option>
                              </select>
                              {errors.dis_Mode && <p style={{ color: 'red' }}>{errors.dis_Mode}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-1'>
                            <div className='btnBox justify-flex-start pd-t-0'>
                              <button className='dfBtn' type='submit' onClick={addQtyOffer}>ADD</button>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='tableBox w-max-content'>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th scope="col">From qty</th>
                                    <th scope="col">To qty</th>
                                    <th scope="col">Discount</th>
                                    <th scope="col">Mode</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    qtyofferdetails.map((val, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{val.rowId = key + 1}</td>
                                          <td>{val.from_QV}</td>
                                          <td>{val.to_QV}</td>
                                          <td>{val.discount_PV}</td>
                                          <td>{val.dis_Mode}</td>
                                          <td><button className='delete'><FontAwesomeIcon icon={faTrash} onClick={() => handleQtyDelete(val.rowId)} /></button></td>
                                        </tr>
                                      )
                                    })
                                  }

                                </tbody>
                              </table>
                              {errors?.addOffers && <p style={{ color: 'red' }}>{errors?.addOffers}</p>}
                            </div>
                            <div className='vertical-space-15'></div>
                          </div></>
                        : null
                    }

                    {
                      isValueOffer ?
                        <>
                          <div className='col-md-12'>
                            <div className='inputBorderBottom'></div>
                            <div className='vertical-space-10'></div>
                            <h5>Value Discount </h5>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-3'>
                            <div className='inputBox'>
                              <label>From Value<span>*</span></label>
                              <input type="text" className='fullInput'
                                name='from_QV'
                                value={valueOffermasterList.from_QV}
                                onChange={handleValueOffer}
                                style={{ width: "100%" }} placeholder='Value..'></input>
                              {errors.from_QV && <p style={{ color: 'red' }}>{errors.from_QV}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-3'>
                            <div className='inputBox'>
                              <label>To Value<span>*</span></label>
                              <input type="text" className='fullInput'
                                name='to_QV'
                                value={valueOffermasterList.to_QV}
                                onChange={handleValueOffer}
                                style={{ width: "100%" }} placeholder='Value..'></input>
                              {errors.to_QV && <p style={{ color: 'red' }}>{errors.to_QV}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-5'>
                            <div className='inputBox'>
                              <label>Discount<span>*</span></label>
                              <div className='row'>
                                <div className='col-md-8'>
                                  <input type="text"
                                    name='discount_PV'
                                    value={valueOffermasterList.discount_PV}
                                    onChange={handleValueOffer}
                                    className='form-control fullInput' placeholder='Discount..'></input>
                                  {errors.discount_PV && <p style={{ color: 'red' }}>{errors.discount_PV}</p>}
                                  <div className='vertical-space-5'></div>
                                </div>
                                <div className='col-md-4'>
                                  <select className="form-selected full-width"
                                    name='dis_Mode'
                                    value={valueOffermasterList.dis_Mode}
                                    onChange={handleValueOffer}
                                    id="Discount" >
                                    <option selected>None</option>
                                    <option value="Dis%">Dis %</option>
                                    <option value="Value">Value</option>
                                  </select>
                                  {errors.dis_Mode && <p style={{ color: 'red' }}>{errors.dis_Mode}</p>}
                                  <div className='vertical-space-10'></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-1'>
                            <div className='btnBox pd-t-0'>
                              <button className='dfBtn' type='submit' onClick={addValueOffer}>ADD</button>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='tableBox w-max-content'>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th scope="col">From Value</th>
                                    <th scope="col">To Value</th>
                                    <th scope="col">Discount</th>
                                    <th scope="col">Mode</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    valueOffermasterLists.map((val, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{val.rowId = key + 1}</td>
                                          <td>{val.from_QV}</td>
                                          <td>{val.to_QV}</td>
                                          <td>{val.discount_PV}</td>
                                          <td>{val.dis_Mode}</td>
                                          <td><button className='delete'><FontAwesomeIcon icon={faTrash} onClick={() => handleValueDelete(val.rowId)} /></button></td>
                                        </tr>
                                      )
                                    })
                                  }

                                </tbody>
                              </table>
                              {errors?.addOffers && <p style={{ color: 'red' }}>{errors?.addOffers}</p>}
                            </div>
                            <div className='vertical-space-15'></div>
                          </div>
                        </> : null
                    }

                    {
                      isCoponOffer ?
                        <>
                          <div className='col-md-12'>
                            <div className='inputBorderBottom'></div>
                            <div className='vertical-space-15'></div>
                            <h5>Coupon Discount </h5>
                            <div className='vertical-space-15'></div>
                          </div>
                          <div className='col-md-6'>
                            <div className='inputBox'>
                              <label>Coupon Name</label>
                              <input type="text"
                                name='couponName'
                                value={coponOffermasterList.couponName}
                                onChange={handleCoponOffer}
                                className='fullInput' style={{ width: "100%" }} placeholder='Coupon Name..'></input>
                              {errors.couponName && <p style={{ color: 'red' }}>{errors.couponName}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-5'>
                            <div className='inputBox'>
                              <label>Discount</label>
                              <div className='row'>
                                <div className='col-md-8'>
                                  <input type="text"
                                    name='discount_PV'
                                    value={coponOffermasterList.discount_PV}
                                    onChange={handleCoponOffer}
                                    className='form-control fullInput' placeholder='Discount..'></input>
                                  {errors.discount_PV && <p style={{ color: 'red' }}>{errors.discount_PV}</p>}
                                  <div className='vertical-space-5'></div>
                                </div>
                                <div className='col-md-4'>
                                  <select className="form-selected full-width"
                                    name='dis_Mode'
                                    value={coponOffermasterList.dis_Mode}
                                    onChange={handleCoponOffer}
                                    id="Discount" >
                                    <option selected>None</option>
                                    <option value="Dis%">Dis %</option>
                                    <option value="Value">Value</option>
                                  </select>
                                  {errors.dis_Mode && <p style={{ color: 'red' }}>{errors.dis_Mode}</p>}
                                  <div className='vertical-space-10'></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-1'>
                            <div className='btnBox pd-t-0'>
                              <button className='dfBtn' type='submit' onClick={addCoponOffer}>ADD</button>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='tableBox w-max-content'>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th scope="col">Coupon Name</th>
                                    <th scope="col">Discount</th>
                                    <th scope="col">Mode</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    coponOffermasterLists.map((val, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{val.rowId = key + 1}</td>
                                          <td>{val.couponName}</td>
                                          <td>{val.discount_PV}</td>
                                          <td>{val.dis_Mode}</td>
                                          <td><button className='delete'><FontAwesomeIcon icon={faTrash} onClick={() => handleCouponDelete(val.rowId)} /></button></td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                              </table>
                              {errors?.addOffers && <p style={{ color: 'red' }}>{errors?.addOffers}</p>}
                            </div>
                          </div>
                        </> : null
                    }
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleChangeview} >List</button>
                  <button className='dfBtn' type='submit' onClick={saveOfferDiscounts}>Submit</button>
                </div>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>

                      <h4>Succesfully Submitted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </>
        }
      </>

    </Container>
  );
  }
});

export default ManageOffersOrDiscounts;
