import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IOutwardOutlet, IOutwardOutletDetails } from './model';
import { QRCode } from 'react-qrcode-logo';
import outwardoutletValidation from './validation';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import { removeDuplicates } from '../../common/shared/utils';
import outletItemRequest from '../../Stores/outletItemRequest/model';
import OutletItemRequest from '../../Stores/outletItemRequest/OutletItemRequest';
import Cookies from 'js-cookie';

const OutwardToOutlet = observer((): JSX.Element => {
  const [outwardOutlet, setOutwardOutlet] = useState<IOutwardOutlet>({});
  const [outItemRequestItem, setOutItemRequestItem] = useState<any[]>([])
  const [outItemFilter, setOutItemFilter] = useState<any[]>([])
  const [batchnoList, setBatchNoList] = useState<any[]>([])
  const [tempOutItemRequest, setTempOutItemRequest] = useState<any[]>([])
  const [outwardOutletDetails, setOutwardOutletDetails] = useState<IOutwardOutletDetails[]>([]);

  console.log(outItemRequestItem, 'outItemRequestItem>')
  console.log(outwardOutlet, 'outwardOutlet');
  console.log(batchnoList, 'batchnoList');

  const { supplierMasterStore, outwardtoOutletStore, commonStore } = useStores();

  const [isLoading, setLoading] = useState(false);
  const [submitbtn, setSubmitbtn] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isSpinner, setSpinner] = useState(false);
  const [isCheckQtyModal, setCheckQtyModal] = useState(false);
  const [isReqQtyModal, setReqQtyModal] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [totalQty, setTotalQty] = useState(0);
  const [requestTotQty, setRequestTotQty] = useState(0);
  const [outletQty, setOutletQty] = useState(0);
  const [reqQty, setReqQty] = useState(0);
  const [isLoadModal, setLoadModal] = useState(false);
  const { loadWarehouse, loadWarehouses } = commonStore;
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOutletQty(value.outletQty)
    setOutwardOutlet({ ...outwardOutlet, [name]: value });
  }

  const handleEmployees = (name, event, val) => {
    if (val != null) {
      setOutwardOutlet({ ...outwardOutlet, createdBy: val.employeeId })
    }
    else {
      outwardOutlet.createdBy = 0;
    }
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const handleOutletItems = async (name, event, val) => {
    if (val != null) {
      setOutwardOutlet({ ...outwardOutlet, [name]: val.outletid });
      setSpinner(true);
      if (name === 'Barcode') {
        const getBarcodeBatch = await outwardtoOutletStore.loadOutletItemRequestnew(3, outwardOutlet?.outletId, outwardOutlet?.warehouseId, outwardOutlet?.outRequestNo, outwardOutlet?.itemId, val.Barcode)
        setBatchNoList([...getBarcodeBatch])
      }
      else {
        const outletrequestItem = await outwardtoOutletStore.loadOutletItemRequestnew(1, val.outletid, outwardOutlet?.warehouseId, outwardOutlet?.outRequestNo, outwardOutlet?.itemId)
        let assignTempReqQty = outletrequestItem?.map((each) => {
          if (each?.requestedQty) {
            each.tempReqQty = each?.requestedQty;
            each.tempBatchQty = each?.Batchqty;
            return each
          }
          return each
        })

        setOutItemRequestItem([...assignTempReqQty])
        setTempOutItemRequest([...assignTempReqQty])
        setSpinner(false);
      }
      setSpinner(false);
      //  const outletrequestItem = await outwardtoOutletStore.loadOutletItemRequest(val?.outletid, outwardOutlet?.warehouseId);

    }
    else {
      outwardOutlet.outletId = 0;
    }
  }

  const handleoutletRequest = async (name, event, val) => {
    if (val != null) {
      setOutwardOutlet({
        ...outwardOutlet, [name]: val.outletReqno, outRequestNo: val.outletReqno
      });
      const outletItemfilter = await outwardtoOutletStore.loadOutletItemRequestnew(4, outwardOutlet?.outletId, outwardOutlet?.warehouseId, val.outletReqno)
      console.log(outletItemfilter, 'outletItemfilter');
      let assignTempRQty = outletItemfilter?.map((each) => {
        if (each?.requestedQty) {
          each.tempReqQty = each?.requestedQty;
          each.tempBatchQty = each?.Batchqty;
          return each
        }
        return each
      })
      setOutItemFilter([...assignTempRQty])
    }
    else {
      outwardOutlet.outRequestNo = 0;
    }
  }

  async function fetchOultetItems() {
    setLoading(false);
    await Promise.all([outwardtoOutletStore.getEntryNo('OutwardtoOutlet'), loadWarehouse(),
    outwardtoOutletStore.loadOutletItems(), outwardtoOutletStore.getLoadEmployess()
    ]);
  }

  const handleItems = async (name, val) => {
    if (val != null) {
      let chkexitsval = 0;
      // && obj?.wid === outwardOutlet?.warehouseId brandtype: val?.BrandType
      let itemFilter = outItemFilter?.filter((obj) => obj?.itemid === val.itemid && obj?.outletReqno === outwardOutlet?.outRequestNo)
      const batchnoExists = outwardOutletDetails.some((each) => each.OutwardItemId === val.itemid);
      console.log(batchnoExists, 'batchnoExists');
      if (batchnoExists === true) {
        const totalReqQty = Number(outwardOutlet.outletQty) + (outwardOutletDetails.filter(m => m.OutwardItemId === val.itemid && m.outwardRequestNo === outwardOutlet.outRequestNo).reduce((a, v) => a = a + v.outwardOutletQty!, 0));
        if (totalReqQty) {
          chkexitsval = 1;
          if (requestTotQty === 0) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        }
      } else {
        setDisabled(false);
      }
      setOutwardOutlet({
        ...outwardOutlet,
        itemId: val.itemid, itemName: val.itemname,
        requestedQty: chkexitsval ? requestTotQty : val?.requestedQty, warehosueStock: itemFilter?.reduce((a, v) => a = a + v?.Batchqty!, 0),
      });
      setReqQty(val?.requestedQty)
      const getBarcodeBatch = await outwardtoOutletStore.loadOutletItemRequestnew(2, outwardOutlet?.outletId, outwardOutlet?.warehouseId, outwardOutlet?.outRequestNo, val.itemid)
      console.log(getBarcodeBatch, 'getBarcodeBatch');
      setOutItemRequestItem([...getBarcodeBatch])
    }
    else {
      outwardOutlet.itemId = 0;
      outwardOutlet.itemName = '';
    }
  }

  const handleBatch = (name, event, val) => {
    if (name === 'barcode' && val != null) {
      //  const totalBatchQty = outwardOutletDetails.filter(m => m.outwardBatchNo === val.batchno && m.OutwardItemId === outwardOutlet?.itemId && m.outwardRequestNo === outwardOutlet?.outRequestNo).reduce((a, v) => a = a + v.outwardOutletQty!, 0);
      setOutwardOutlet({ ...outwardOutlet, [name]: val.Barcode })
      // if (Number(totalBatchQty) - Number(val.Batchqty) === 0) {
      //   batchnoList.filter((each) => each?.batchno !== val.batchno)
      // }
    } else if (name === 'batchId' && val != null) {
      // setBatchNo(val.batchNo);
      const totalBatchQty = outwardOutletDetails.filter(m => m.outwardBatchNo === val.batchno && m.OutwardItemId === outwardOutlet?.itemId && m.outwardRequestNo === outwardOutlet?.outRequestNo).reduce((a, v) => a = a + v.outwardOutletQty!, 0);
      setOutwardOutlet({ ...outwardOutlet, [name]: val.batchId, barcode: val?.Barcode, batchNo: val.batchno, tempBatchQty: val?.tempBatchQty,Mrprate: Number(val.Mrprate) , batchQty: Number(val.Batchqty) - Number(totalBatchQty) })
    } else {
      outwardOutlet.batchNo = 0;
      outwardOutlet.batchQty = 0;
    }
  }

  const handleWarehouse = (name: any, event: object, val: any) => {

    if (val != null) {
      setOutwardOutlet({ ...outwardOutlet, [name]: val.warehouseId, ['wareHouse']: val.warehouseName });
    }
    else {
      outwardOutlet.wareHouse = '';
    }
  }

  async function addOutwardDetails(e) {
    console.log(outwardOutletDetails,'outwardOutlet55');
    
    const getBarcodeItem = await outwardtoOutletStore.loadOutletItemRequestnew(1, outwardOutlet?.outletId, outwardOutlet?.warehouseId)
    setBatchNoList([...getBarcodeItem])
    console.log(getBarcodeItem, 'outletItemADDLIST');
    let qty: any = 0;
    let reqqty: any = 0;
    let batchQty: any = 0;
    e.preventDefault();
    let error: any = {};
    error = outwardoutletValidation(outwardOutlet);
    setErrors(error);

    if (Object.keys(error).length === 0) {
      let outwardOutletDetail: IOutwardOutletDetails = {};
      setOutletQty(0);
      if (outwardOutletDetails.length > 0) {
        const checkQty = outwardOutletDetails.filter(m => m.outwardBatchNo === outwardOutlet.batchNo).reduce((a, v) => a = a + v.outwardOutletQty!, 0);
        const totalWareQty = Number(outwardOutlet.outletQty) + (outwardOutletDetails.filter(m => m.OutwardItemId === outwardOutlet.itemId && m.outwardRequestNo === outwardOutlet.outRequestNo).reduce((a, v) => a = a + v.outwardOutletQty!, 0));
        console.log(checkQty,totalWareQty,'totalWareQty');
        debugger
        // qty = Number(outwardOutlet.outletQty) + checkQty;
        reqqty = Number(reqQty);
        batchQty= Number(batchQty);

        if (totalWareQty > reqqty) {
          setTotalQty(totalWareQty);
          setReqQtyModal(true);
          return;
        }
       else if (outwardOutlet.outletQty! > Number(reqQty)) {
          setTotalQty(outwardOutlet.outletQty!);
          setReqQtyModal(true);
          return;
        }
        else  if (outwardOutlet.outletQty! > outwardOutlet.batchQty! ) {
            setTotalQty(outwardOutlet.outletQty!);
            setReqQtyModal(true);
            return;
        } 
        else {
          outwardOutletDetail.outwardRequestNo = outwardOutlet.outRequestNo
          outwardOutletDetail.OutwardItemId = outwardOutlet.itemId;
          outwardOutletDetail.outwardItemName = outwardOutlet.itemName
          outwardOutletDetail.outwardWarehosueStock = Number(outwardOutlet.warehosueStock! - (Number(outwardOutlet.outletQty)));
          outwardOutletDetail.outwardOutletQty = Number(outwardOutlet.outletQty);
          outwardOutletDetail.outwardrequestedQty = Number(outwardOutlet.requestedQty);
          outwardOutletDetail.outwardBatchNo = outwardOutlet.batchNo;
          outwardOutletDetail.outwardBatchId = outwardOutlet.batchId;
          outwardOutletDetail.barcode = outwardOutlet.barcode ? outwardOutlet.barcode! : '';
          outwardOutletDetail.batchno = outwardOutlet.batchNo;
          outwardOutletDetail.Mrprate = outwardOutlet.Mrprate;
          outwardOutletDetail.itemName = outwardOutlet.itemName!;
          setOutwardOutletDetails([...outwardOutletDetails, outwardOutletDetail]);
          const totalReqQty = Number(outwardOutlet.outletQty) + (outwardOutletDetails.filter(m => m.OutwardItemId === outwardOutlet.itemId && m.outwardRequestNo === outwardOutlet.outRequestNo).reduce((a, v) => a = a + v.outwardOutletQty!, 0));
          setRequestTotQty(Number(reqQty) - Number(totalReqQty))

          let updatedList = outItemRequestItem?.map((each) => {
            if (each?.outletReqno === outwardOutlet?.outRequestNo && each?.itemid === outwardOutlet?.itemId) {
              each.requestedQty = each?.requestedQty - Number(outwardOutlet?.outletQty);
              if (each?.Barcode === outwardOutlet?.barcode && each?.batchno === outwardOutlet?.batchNo) {
                each.Batchqty = each?.Batchqty - Number(outwardOutlet?.outletQty!);
                each.Mrprate = each?.Mrprate - Number(outwardOutlet?.Mrprate!);
              }
              return each
            }
            return each
          })
          console.log(outwardOutletDetail, 'outwardOutletDetail');
          console.log(updatedList, outItemRequestItem, 'updatedList>>')
          setOutItemRequestItem([...updatedList])
          setOutwardOutlet({ ...outwardOutlet, requestedQty: Number(outwardOutlet?.requestedQty) - Number(totalReqQty) });
        }
      }

      else {
        if (outwardOutlet.outletQty! > Number(reqQty)) {
          setTotalQty(outwardOutlet.outletQty!);
          setReqQtyModal(true);
          return;
        }
        else  if (outwardOutlet.outletQty! > outwardOutlet.batchQty! ) {
            setTotalQty(outwardOutlet.outletQty!);
            setReqQtyModal(true);
            return;
          
        } else {
          outwardOutletDetail.outwardRequestNo = outwardOutlet.outRequestNo
          outwardOutletDetail.OutwardItemId = outwardOutlet.itemId;
          outwardOutletDetail.outwardItemName = outwardOutlet.itemName
          outwardOutletDetail.outwardWarehosueStock = Number(outwardOutlet.warehosueStock! - outwardOutlet.outletQty!);
          outwardOutletDetail.outwardOutletQty = Number(outwardOutlet.outletQty);
          outwardOutletDetail.outwardrequestedQty = Number(outwardOutlet.requestedQty);
          outwardOutletDetail.outwardBatchNo = outwardOutlet.batchNo;
          outwardOutletDetail.outwardBatchId = outwardOutlet.batchId;
          outwardOutletDetail.barcode = outwardOutlet.barcode ? outwardOutlet.barcode! : '';
          outwardOutletDetail.batchno = outwardOutlet.batchNo;
          outwardOutletDetail.Mrprate = outwardOutlet.Mrprate;
          outwardOutletDetail.itemName = outwardOutlet.itemName!;
          setOutwardOutletDetails([...outwardOutletDetails, outwardOutletDetail]);

          const totalReqQty = Number(outwardOutlet.outletQty);
          setRequestTotQty(Number(outwardOutlet?.requestedQty) - Number(totalReqQty))

          let updatedList = outItemRequestItem?.map((each) => {
            if (each?.outletReqno === outwardOutlet?.outRequestNo && each?.itemid === outwardOutlet?.itemId) {
              let obj = each;
              obj.requestedQty = obj?.requestedQty - Number(outwardOutlet?.outletQty);
              if (obj?.Barcode === outwardOutlet?.barcode && obj?.batchno === outwardOutlet?.batchNo) {
                obj.Batchqty = obj?.Batchqty - Number(outwardOutlet?.outletQty!);
                obj = each;
              }
              return obj
            }
            return each
          })
          setOutItemRequestItem([...updatedList])
          setOutwardOutlet({ ...outwardOutlet, tempBatchQty: Number(outwardOutlet.outletQty), requestedQty: Number(outwardOutlet?.requestedQty) - Number(totalReqQty) });
        }
      }
      setSubmitbtn(true);
      setOutwardOutlet({ ...outwardOutlet, itemId: 0, itemName: '', barcode : '', batchNo: '', requestedQty: 0, batchQty: 0, warehosueStock: 0, outletQty: 0, batchId: 0 });
    }
  }

  useEffect(() => {
    handleOutRequestItemList()
  }, [outwardOutletDetails?.length])

  function handleOutRequestItemList() {
    let tempOutReqeustItems = [...tempOutItemRequest];
    if (tempOutReqeustItems?.length) {
      let updatedList: any[] = [];
      tempOutReqeustItems?.map((obj, index) => {
        let filterItems = outwardOutletDetails?.filter((item) => item?.OutwardItemId === obj?.itemid)
        let findBalQty = filterItems?.reduce((acc, valu) => acc = acc + Number(valu?.outwardOutletQty ?? 0), 0)
        if (findBalQty < obj?.tempReqQty) {
          updatedList?.push(obj)
        }
      })
      setOutItemRequestItem([...updatedList])
    }
  }

  function handleRemove(index) {
    let findObj = outwardOutletDetails![index];
    let updatedList = outItemRequestItem?.map((each) => {
      if (findObj && each?.outletReqno === findObj?.outwardRequestNo && each?.itemid === findObj?.OutwardItemId && each?.Barcode === findObj?.barcode && each?.batchno === findObj?.outwardBatchNo) {
        each.Batchqty = each?.Batchqty + Number(findObj?.outwardOutletQty!)
        each.requestedQty = each?.requestedQty + Number(findObj?.outwardOutletQty)
        return each
      }
      return each
    })
    setOutItemRequestItem([...updatedList])
    outwardOutletDetails?.splice(index, 1)
    setOutwardOutletDetails([...outwardOutletDetails]);
  }

  async function saveOutwardOutlet(e) {
    // setLoading(true);
    e.preventDefault();
    const entryNos = await outwardtoOutletStore.getEntryNo('OutwardtoOutlet');
    outwardOutlet.outwardId = entryNos.entryNo + 1;
    outwardOutlet.outwardOutletDetails = outwardOutletDetails;
    const status = await outwardtoOutletStore.saveOutwardOutlet(outwardOutlet);

    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }

  }

  const handleCancel = () => {
    navigate('/Admin/OutwardToOutlet/OutwardToOutletDetail');
  }
  const handleFailureModalClose = () => setFailureModal(false);
  
  const handleCheckQtyModalClose = () => {
    setCheckQtyModal(false);
    setReqQtyModal(false);
  }

  const handleReqQtyModalClose = () => {
    setCheckQtyModal(false);
    setReqQtyModal(false);
  }

  const handleSuccessModalClose = () => {
    navigate('/Admin/OutwardToOutlet/OutwardToOutletDetail');
  }

  const isInitialRender = useRef(true);

  // useEffect(() => {
  //   if (isInitialRender.current) {
  //     fetchOultetItems();
  //     isInitialRender.current = false;
  //   }
  //   return () => { };
  // }, []);
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Outward_to_outlet';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchOultetItems();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/OutwardToOutlet/OutwardToOutletDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {


    return (
      <>

        {/* <div className='SpinnerBox'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> 
        <> */}
        <div className='container'>
          <div className='vertical-space-20'></div>
          <div className='outletInputField inputFormBox LgInputField'>
            <div className='hrBox'>
              <h3>Outward To Outlet</h3>
            </div>
            <div className='inputBoxLists'>
              <div className='row'>
                {
                  isSpinner ?
                    <div className='SpinnerBox'>
                      <Spinner animation="grow" size="sm" />
                    </div> : null
                }
                <div className='col-md-2'>
                  <div className='inputBox'>
                    <label>Entry No <span>*</span></label>
                    <input type="text" className=''
                      value={outwardOutlet.outwardId = outwardtoOutletStore.entryNo.entryNo + 1}
                      name='outwardId' disabled onChange={handleInputChange}
                      style={{ width: "100%" }} placeholder='Entry No..'></input>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-2'>
                  <div className='inputBox'>
                    <label>Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      value={outwardOutlet.outwardDate = outwardOutlet?.outwardDate ?? new Date().toISOString().substr(0, 10)}
                      defaultValue={new Date().toISOString().substr(0, 10)}
                      name='outwardDate' onChange={handleInputChange}
                      placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                    {errors.outwardDate && <p style={{ color: 'red' }}>{errors.outwardDate}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                {(loadWarehouses?.length > 0) ? <div className='col-sm-4'>
                  <div className='inputBox'>
                    <label>Warehouse <span>*</span></label>
                    <AutoComplete placeholder='Select Warehouse..' clsName='full-width' getOptionLabel='warehouseName'
                      emitOption={(val) => handleWarehouse('warehouseId', {}, val)} options={loadWarehouses} />
                    {errors.warehouseId && <p style={{ color: 'red' }}>{errors.warehouseId}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div> : null}

                {outwardtoOutletStore?.loadEmployess?.length > 0 && <div className='col-sm-4'>
                  <div className='inputBox'>
                    <label>Created by </label>
                    <AutoComplete placeholder='Select Created By..' clsName='full-width' getOptionLabel='employeeName'
                      emitOption={(val) => handleEmployees('createdBy', {}, val)} options={removeDuplicates(outwardtoOutletStore?.loadEmployess, 'employeeName')} />
                  </div>
                  <div className='vertical-space-10'></div>
                </div>}
                {outwardtoOutletStore?.outletItemDetails?.length > 0 && <div className='col-md-5'>
                  <div className='inputBox'>
                    <label>Outlet Name<span>*</span> </label>
                    <AutoComplete placeholder='Select Outlet..' clsName='full-width' getOptionLabel='outletName'
                      emitOption={(val) => handleOutletItems('outletId', {}, val)} options={outwardtoOutletStore?.outletItemDetails} />
                    {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>}
                {outItemRequestItem?.length > 0 &&
                  <div className='col-md-5'>
                    <div className='inputBox'>
                      <label>Outlet Request No<span>*</span> </label>
                      <AutoComplete placeholder='Select Request No..' clsName='full-width' getOptionLabel='outletReqno'
                        emitOption={(val) => handleoutletRequest('outletItemId', {}, val)} options={removeDuplicates(outItemRequestItem, 'outletReqno')} />
                      {errors.outletItemId && <p style={{ color: 'red' }}>{errors.outletItemId}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>}
                <div className='col-md-2'>
                  <div className='inputBox'>
                    <label>Requested Qty </label>
                    <input type="text" className='fullInput' name='requestedQty'
                      value={outwardOutlet.requestedQty} disabled
                      style={{ width: "100%" }} placeholder='Requested Qty..'></input>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                {(outItemRequestItem?.length > 0 && outwardOutlet?.outRequestNo) &&
                  <>
                    <div className='col-md-3'>
                      <div className='inputBox'>
                        <label>Item <span>*</span></label>

                        <AutoComplete value={outwardOutlet?.itemName} placeholder='Select Item Name..' clsName='full-width' getOptionLabel='itemname'
                          emitOption={(val) => handleItems('warehouseId', val)}
                          options={removeDuplicates(outItemFilter, 'itemid')} />
                        {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    {/* outwardOutlet?.brandtype !== "Own Brand" && */}
                    {
                      <div className="col-md-3">
                        <div className='inputBox'>
                          <label>Barcode </label>
                          <AutoComplete placeholder='Select Barcode' clsName='full-width' disabled={isDisabled} getOptionLabel='Barcode'
                            emitOption={(val) => handleOutletItems('Barcode', {}, val)} options={removeDuplicates(outItemRequestItem, 'Barcode')?.filter((each) => Number(each?.itemid) == Number(outwardOutlet?.itemId))} 
                            value={outwardOutlet?.barcode}
                            />
                           
                          {/* <AutoComplete value={outwardOutlet?.barcode} placeholder="Select Barcode"
                                emitOption={(val) => handleBatch('barcode', {}, val)}
                                options={removeDuplicates(outItemRequestItem, 'Barcode')?.filter((obj) => obj?.itemid === outwardOutlet?.itemId)}
                                getOptionLabel='Barcode'
                                clsName='full-width'
                              /> */}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>}
                    {/* {outItemRequestItem?.length > 0 && */}
                    <div className="col-md-3">
                      <div className='inputBox'>
                        <label>Batch No </label>
                        <AutoComplete placeholder="Select Batch No"
                          disabled={isDisabled}
                          value={outwardOutlet?.batchNo}
                          emitOption={(val) => handleBatch('batchId', {}, val)}
                          options={batchnoList}
                          // options={batchnoList?.filter((obj) => (outwardOutlet?.brandtype !== "Own Brand"  && outwardOutlet?.barcode) ? obj?.Barcode === outwardOutlet?.barcode : (obj?.itemid === outwardOutlet?.itemId) && obj)}
                          getOptionLabel='batchno'
                          clsName='full-width'
                        />
                      </div>
                      {errors.batchId && <p style={{ color: 'red' }}>{errors.batchId}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    {/* } */}
                  </>
                }
                <div className='col-md-2'>
                  <div className='inputBox'>
                    <label>Warehouse Stock </label>
                    <input type="text" className='fullInput'
                      name='warehouseStock' disabled
                      value={outwardOutlet?.warehosueStock}
                      style={{ width: "100%" }} placeholder='Stock..'></input>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-2'>
                  <div className='inputBox'>
                    <label>Batch Qty </label>
                    <input type="text" className='fullInput' name='batchQty'
                      value={outwardOutlet.batchQty} disabled
                      style={{ width: "100%" }} placeholder='Batch Qty..'></input>
                    {errors.batchQty && <p style={{ color: 'red' }}>{errors.batchQty}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-2'>
                  <div className='inputBox'>
                    <label>MRP </label>
                    <input type="text" className='fullInput' name='batchQty'
                      value={outwardOutlet.Mrprate} disabled
                      style={{ width: "100%" }} placeholder='Batch Qty..'></input>
                    {errors.batchQty && <p style={{ color: 'red' }}>{errors.batchQty}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-2'>
                  <div className='inputBox'>
                    <label>Out Qty <span>*</span></label>
                    <input type="text" className='fullInput' style={{ width: "100%" }}
                      disabled={isDisabled}
                      value={outletQty}
                      defaultValue={outwardOutlet.outletQty}
                      name='outletQty' onChange={handleInputChange}
                      placeholder='Out Qty..'></input>
                    {errors.outletQty && <p style={{ color: 'red' }}>{errors.outletQty}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-sm-2'>
                  <button className='dfBtn' onClick={addOutwardDetails}>ADD</button>
                </div>
              </div>
              <div className='vertical-space-10'></div>
              <div className='tableBox' style={{ maxHeight: '500px', overflow: 'scroll' }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Warehouse Stock</th>
                      <th scope="col">Out Qty</th>
                      <th scope="col">Outlet Request No</th>
                      <th scope="col">Outlet Request Qty</th>
                      <th scope="col">Barcode</th>
                      <th scope="col">Batch No</th>
                      <th scope="col">MRP</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: 'middle' }}>
                    {outwardOutletDetails.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.outwardItemName}</td>
                          <td>{val.outwardWarehosueStock!}</td>
                          <td>{val.outwardOutletQty}</td>
                          <td>{val.outwardRequestNo}</td>
                          <td>{val.outwardrequestedQty}</td>
                          <td>{val.barcode}</td>
                          <td>{val.outwardBatchNo}</td>
                          <td>{val.Mrprate}</td>
                          <td>
                            <button style={{
                              border: '2px solid #dc3545', background: '#dc3545',
                              color: 'white', fontWeight: '400', borderRadius: '6px', fontSize: '12px'
                            }}
                              type='submit' onClick={() => handleRemove(key)}>Remove
                            </button>
                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className='btnBox'>
              <button className='secondaryBtn' onClick={handleCancel} >List</button>
              <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveOutwardOutlet}>Submit</button>
            </div>
          </div>
          {
            outwardOutlet ?
              <Modal show={isCheckQtyModal} onHide={handleCheckQtyModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>

                    {/* <h4>This BatchNo:{outwardOutlet.batchNo} already reached batch quantity</h4> */}
                    <h4>Excess Qty Please Check</h4>
                    <h4>Actual Batch Quantity:{outwardOutlet.batchQty}</h4>
                    <h4>Entered Out Quantity:{totalQty}</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCheckQtyModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal> : null
          }
          {
            outwardOutlet ?
              <Modal show={isReqQtyModal} onHide={handleReqQtyModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>

                    {/* <h4>This BatchNo:{outwardOutlet.batchNo} already reached batch quantity</h4> */}
                    <h4>Excess Qty Please Check</h4>
                    <h4>Actual Request Quantity:{outwardOutlet.requestedQty}</h4>
                    <h4>Batch Quantity:{outwardOutlet.batchQty}</h4>
                    <h4>Entered Out Quantity:{totalQty}</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCheckQtyModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal> : null
          }
          <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../gsmecom/images/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../gsmecom/images/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFailureModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>


    )
  }
});

export default OutwardToOutlet;
