import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IDamageEntryWarehouseSearchoptions } from './model';
import { QRCode } from 'react-qrcode-logo';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';



function DamageEntryWarehouseDetail() {

  const { DamageEntryWareHouseStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getDamageEntryWarehouseDetail, getDamageEntryWarehouseDetails } = DamageEntryWareHouseStore;
  const [isDamageMasterShow, setDamageMasterModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const getDamageMasterDetail = async (damagentryMaterid) => {
    setLoading(true);
    const data = await getDamageEntryWarehouseDetails(damagentryMaterid);
    if (data !== undefined || data !== null) {
      setLoading(false);
      setDamageMasterModal(true);
    }
  }
  const handleCreate = () => {
    navigate('/Admin/DamageEntryWarehouse');
  }

  const handleClose = () => {
    setDamageMasterModal(false);
  }

  const deleteDamageEntryWarehouse = async (damagewareid) => {

    setLoading(true);
    const status = await DamageEntryWareHouseStore.deleteDamageEntryWarehouse(damagewareid);

    if (status === 'Success') {
      fetchDamageMaster();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deleteDamageEntryWarehouseDetail = async (damagedetailid) => {
    setDamageMasterModal(false);
    setLoading(true);
    const status = await DamageEntryWareHouseStore.deleteDamageEntryWarehouseDetail(damagedetailid);

    if (status === 'Success') {
      fetchDamageMaster();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchDamageMaster();
  }


  async function fetchDamageMaster() {
    setLoading(false)
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Damage_entry_warehouse'), DamageEntryWareHouseStore.getDamageEntryWarehouse()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchDamageMaster();
      isCurrentPage.current = false;
    }
    return () => { }
  }, []);

  const sortedData = DamageEntryWareHouseStore?.getDamageEntryWarehouseList?.length > 0 && DamageEntryWareHouseStore.getDamageEntryWarehouseList.slice().sort((a, b) => {
    const dateA = new Date(a.damageEntryDate);
    const dateB = new Date(b.damageEntryDate);

    if (dateA > dateB) return -1;
    if (dateB < dateA) return 1;
    return 0;
  })


  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (sortedData && sortedData?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(sortedData)
    }
  }, [sortedData])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : null
      }
      <div className='container-fluid'>
        <div className='vertical-space-20'></div>
        <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={sortedData}
          searchTitles={[{ key: "warehouseName", value: "Warehouse Name" }]}
          emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
        <div className='vertical-space-20'></div>
        <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className='btnBox'>
            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{sortedData?.length} Records</button>
          </div>
          <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
          </Stack>
        </div>
        <div className='vertical-space-20'></div>
        <div className='tableListDetails'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope='col'>Entry Date  </th>
                <th scope='col'>Warehouse   </th>
                <th scope='col'></th>
                <th scope='col'>Create </th>
                <th scope='col'>Update </th>
                <th scope='col'>Delete </th>
              </tr>
            </thead>
            <tbody>
              {
                (DamageEntryWareHouseStore?.getDamageEntryWarehouseList?.length > 0 && (isSearch ? filteredData : currentPageData)?.length > 0) &&
                (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                  return (
                    <tr key={key}>
                      <td>{val.damageEntryDate}</td>
                      <td>{val.warehouseName}</td>
                      <td><button disabled={!userRights?.view} onClick={() => getDamageMasterDetail(val.damagentryMaterid)}>Detail</button></td>
                      <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                      <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                      <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteDamageEntryWarehouse(val.damagentryMaterid)} /></button></td>
                    </tr>
                  )
                }
                )}

            </tbody>
          </table>
        </div>

        {
          isDamageMasterShow ?
            <Modal show={isDamageMasterShow} onHide={handleClose} className='PriceHistoryModel'>
              <Modal.Header closeButton>
                <Modal.Title>Detail</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                      <th scope="col">S.No</th>
                        <th scope="col"> Item Name</th>
                        <th scope='col'>Barcode</th>
                        <th scope="col"> Batch No</th>
                        <th scope="col"> Qty</th>
                        <th scope="col"> MRP</th>
                        <th scope="col"> Damage Description</th>
                        {/* <th scope="col"> Delete</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getDamageEntryWarehouseDetail?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{val.damageItemName}</td>
                              <td>{val?.damageBarcode}</td>
                              <td>{val.damageBatchNo}</td>
                              <td>{val.damageItemQty}</td>
                              <td>{val.damageMrprate}</td>
                              <td>{val.damageDescription}</td>
                              {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteDamageEntryWarehouseDetail(val.damagedetailid)} /></button></td> */}
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
            </Modal> : null
        }
      </div>
      <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/checked.png')} />
            </div>
            <h4>Succesfully Deleted</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/warning.png')} />
            </div>
            <h4>Failed</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFailureModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );

};

export default DamageEntryWarehouseDetail;
