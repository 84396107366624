import { IReuseConversion, IReuseConversionApprovalDetails } from "../model";

export function CheckValidation(rejectionConv: IReuseConversionApprovalDetails) {
    let error: any = {};
    if(!rejectionConv.convBatchno) {
        error.convBatchno = "Enter Batch No"
    }
    if(!rejectionConv.convItemid) {
        error.convItemid = "Select Item"
    }
    if(!rejectionConv.convQty) {
        error.convQty = "Enter Qty"
    }
    return error;
}

export function SubmitValidation(rejeConvMaster: IReuseConversion) {
    let error: any = {};
    if(!rejeConvMaster.createdby) {
        error.createdby = "Selected Created By"
    }
    if(!rejeConvMaster?.rejectionconversionApprovaldetails?.length) {
        error.rejectionList = "Check atlease one item"
    }
    return error;
}