export class IDamageEntryApproval {
    entryNo?: number
    entryDate?: string
    wid?: number
    outletid?: number
    convertedby?: number
    damageApprovaldetails?: IDamageApprovalDetails[]
}

export class IDamageApprovalDetails {
    entryid?: number
    itemid?: number
    itemName?: string
    barcode?: string
    batchno?: string
    qty?: number
    dType?: string
    masterid?: number
    detailid?: number
    damagefrom?: string
    reuseQty?: number = 0
    returnQty?: number = 0
    rejectionQty?: number = 0
}