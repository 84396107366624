import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AutoComplete from '../../common/shared/autoComplete';
import useStores from '../../hooks';
import moment from 'moment';
import { Autocomplete, TextField } from '@mui/material';
import { IReuseConversion, IReuseConversionApprovalDetails } from './model';
import _ from 'lodash';
import { CheckValidation, SubmitValidation } from './validation';
import { removeDuplicates } from '../../common/shared/utils';
import Cookies from 'js-cookie';

const ReuseConversion = () => {

    const { supplierMasterStore, commonStore, warehouseStores, inwardStore, indentStore } = useStores();

    const navigate = useNavigate();

    const { loadEmployee, loadEmployees } = commonStore;

    const [rejectionConversionMaster, setRejectionConversionMaster] = useState<IReuseConversion>({})

    const [itemMasterJson, setItemMasterJson] = useState<any[]>([])
    const [rejectionItems, setRejectionItems] = useState<any[]>([])

    // console.log(itemMasterJson, 'itemMasterJson>>')

    const [errors, serErrors] = useState<any>({})
    const [isLoadModal, setLoadModal] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isSpinner, setSpinner] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false)
    const [isFailureModal, setFailureModal] = useState(false)
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    function handleInputchange(e) {
        const { name, value } = e.target;
        setRejectionConversionMaster({ ...rejectionConversionMaster, [name]: value })
    }

    function handleAutoComplete(name, value, val?: any) {
        if (name === "convertedby" && value != null) {
            setRejectionConversionMaster({ ...rejectionConversionMaster, createdby: value?.employeeId })
        } else if (name === 'convItemid' && value != null) {
            debugger
            const existing: any = rejectionConversionMaster?.rejectionconversionApprovaldetails?.length! > 0 ?
                rejectionConversionMaster?.rejectionconversionApprovaldetails?.find(m => m?.entryid === val?.Entryid) : {}

            if (existing && Object.keys(existing)?.length === 0) {
                let rejectionConversion: IReuseConversionApprovalDetails = {}
                rejectionConversion.barcode = val.Barcode;
                rejectionConversion.batchno = val.Batchno;
                rejectionConversion.convBatchno = rejectionConversion.convBatchno;
                rejectionConversion.convItemid = value.itemid;
                rejectionConversion.convQty = rejectionConversion.convQty;
                rejectionConversion.convUOM = value.uom;
                // rejectionConversion.convUOM = value.convuom;
                rejectionConversion.rejid = rejectionConversion.rejid;
                rejectionConversion.entryid = val.Entryid;
                rejectionConversion.itemid = val.Itemid;
                rejectionConversion.qty = val.Qty;
                rejectionConversion.checked = rejectionConversion.checked;
                if (!rejectionConversionMaster?.rejectionconversionApprovaldetails?.length) {
                    rejectionConversionMaster!.rejectionconversionApprovaldetails = Array<IReuseConversionApprovalDetails>();
                }
                rejectionConversionMaster!.rejectionconversionApprovaldetails!.push(rejectionConversion)
                setRejectionConversionMaster(_.cloneDeep(rejectionConversionMaster))
            } else {
                debugger
                let arrayofobj: IReuseConversionApprovalDetails[] = Object.assign([], rejectionConversionMaster!.rejectionconversionApprovaldetails)
                arrayofobj!.map((item, i) => {
                    if (item.entryid === val.Entryid) {
                        item.convItemid = value?.itemid;
                        item.convUOM = value?.convuom;
                        return item;
                    }
                    return item;
                })
                rejectionConversionMaster!.rejectionconversionApprovaldetails = arrayofobj
                setRejectionConversionMaster(_.cloneDeep(rejectionConversionMaster));
            }

        }
    }

    function handlebatchdetails(event, val) {
        debugger
        const { name, value } = event?.target;
        const existing: any = rejectionConversionMaster?.rejectionconversionApprovaldetails?.length! > 0 ?
            rejectionConversionMaster?.rejectionconversionApprovaldetails?.find(m => m?.entrydid === val?.Entrydid) : {}
        console.log(existing, 'existing555')
        if (existing && Object.keys(existing)?.length != 0) {
            let arrayofobj: IReuseConversionApprovalDetails[] = Object.assign([], rejectionConversionMaster!.rejectionconversionApprovaldetails)
            arrayofobj!.map((item, i) => {
                if (item.entrydid === val.Entrydid) {
                    item[name] = name === 'convQty' ? Number(value) : value;
                    item.checked = false;
                    return item
                }
                return item;
            })
            rejectionConversionMaster!.rejectionconversionApprovaldetails = arrayofobj
            setRejectionConversionMaster(_.cloneDeep(rejectionConversionMaster));

        } else {
            let rejectionConversion: IReuseConversionApprovalDetails = {}
            rejectionConversion.barcode = val.Barcode;
            rejectionConversion.batchno = val.Batchno;
            rejectionConversion.itemid = val.Itemid;
            rejectionConversion.qty = val.Qty;
            rejectionConversion.entryid = val.Entryid;
            rejectionConversion.entrydid = val.Entrydid
            rejectionConversion[name] = value;
            rejectionConversion.checked = false;
            if (!rejectionConversionMaster?.rejectionconversionApprovaldetails?.length) {
                rejectionConversionMaster!.rejectionconversionApprovaldetails = Array<IReuseConversionApprovalDetails>();
            }
            console.log(rejectionConversionMaster, 'existing555')
            rejectionConversionMaster!.rejectionconversionApprovaldetails!.push(rejectionConversion)
            setRejectionConversionMaster(_.cloneDeep(rejectionConversionMaster))
        }
    }

    const handlebatchNo = (e, val) => {
        debugger
        let error: any = {}
        let { checked } = e.target;
        const existing = rejectionConversionMaster?.rejectionconversionApprovaldetails?.length! > 0 ?
            rejectionConversionMaster!.rejectionconversionApprovaldetails!.filter(m => m?.entryid === val.Entryid) : []
        if (checked == true) {
            if (existing.length === 0) {
                let rejectionConversion: IReuseConversionApprovalDetails = {}
                rejectionConversion.barcode = val.Barcode;
                rejectionConversion.batchno = val.Batchno;
                rejectionConversion.checked = true;
                rejectionConversion.convBatchno = rejectionConversion.convBatchno;
                rejectionConversion.convItemid = rejectionConversion.convItemid;
                rejectionConversion.convQty = rejectionConversion.convQty;
                rejectionConversion.convUOM = rejectionConversion.convUOM;
                rejectionConversion.rejid = rejectionConversion.rejid;
                rejectionConversion.entryid = val.Entryid;
                rejectionConversion.itemid = val.Itemid;
                rejectionConversion.qty = val.Qty;
                if (!rejectionConversionMaster?.rejectionconversionApprovaldetails?.length) {
                    rejectionConversionMaster!.rejectionconversionApprovaldetails = Array<IReuseConversionApprovalDetails>();
                }
                error = CheckValidation(rejectionConversion)
                if (Object.keys(error).length === 0) {
                    rejectionConversionMaster!.rejectionconversionApprovaldetails!.push(rejectionConversion)
                    setRejectionConversionMaster(_.cloneDeep(rejectionConversionMaster))
                }
            } else {
                let arrayofobj: IReuseConversionApprovalDetails[] = Object.assign([], rejectionConversionMaster!.rejectionconversionApprovaldetails)
                arrayofobj!.map((item, i) => {
                    if (item.entryid === val.Entryid) {
                        item.checked = true;
                        error = CheckValidation(item)
                        return item
                    }
                    return item;
                })
                if (Object.keys(error).length === 0) {
                    rejectionConversionMaster!.rejectionconversionApprovaldetails = arrayofobj
                    setRejectionConversionMaster(_.cloneDeep(rejectionConversionMaster));
                }
            }
        } else {
            const posBatchDetail = rejectionConversionMaster!.rejectionconversionApprovaldetails!.filter(m => m.entryid !== val.Entryid)
            rejectionConversionMaster!.rejectionconversionApprovaldetails = posBatchDetail
            setRejectionConversionMaster(_.cloneDeep(rejectionConversionMaster))
        }
        serErrors(error)
    }

    async function saveReuseConversion() {
        let error: any = {};
        rejectionConversionMaster?.rejectionconversionApprovaldetails?.map((detail: IReuseConversionApprovalDetails) => {
            detail['rejid'] = rejectionConversionMaster?.entryNo
            return detail
        })
        error = SubmitValidation(rejectionConversionMaster)
        serErrors(error)
        if (Object.keys(error).length === 0) {
            setLoading(true)
            const status = await warehouseStores.reuseConversionSave(rejectionConversionMaster)
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    const handleCancel = () => {
        navigate('/Admin/ReuseConversionList');
    }

    const handleSuccessModalClose = () => {
        navigate('/Admin/ReuseConversionList');
    }
    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const isCurrenPage = useRef(true)

    async function fetchPreLoadData() {
        await loadEmployee()
        const getRejectionItems = await warehouseStores.getReuseItems();
        setRejectionItems([...getRejectionItems])
        const itemMasterJson = await inwardStore.loadInwardItemMasterJSON()
        setItemMasterJson([...itemMasterJson])
        const entryNo = await indentStore.getEntryNo('RejectionCoversion')
        setRejectionConversionMaster({ ...rejectionConversionMaster, entryNo: Number(entryNo?.entrynumber ?? 0) + 1 })
        setLoading(false)
    }
    // useEffect(() => {
    //     if (isCurrenPage.current) {
    //         fetchPreLoadData();
    //         isCurrenPage.current = false;
    //     }
    //     return () => { }
    // }, [])
    // const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Rejection_conversions';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            fetchPreLoadData();

                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/RejectionConversionList');
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {


        return (
            <>
                {/* {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> : null
            }
            <> */}
                <div className='container'>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField'>
                        <div className='hrBox'>
                            <h3>Reuse Conversion</h3>
                        </div>
                        <div className='inputBoxLists pd-b-0'>
                            <div className='tableBox' style={{ maxHeight: '500px', overflow: 'scroll' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Entry No</th>
                                            <th scope="col">Entry Date</th>
                                            <th scope="col">Item Name</th>
                                            <th scope="col" style={{ width: '80px' }}>Barcode</th>
                                            <th scope="col" style={{ width: '80px' }}>Batch No</th>
                                            <th scope="col" style={{ width: '80px' }}>Qty</th>
                                            <th scope="col">Conversion Item</th>
                                            <th scope='col'></th>
                                            <th scope="col" style={{ width: '80px' }}>Qty</th>
                                            <th scope="col">UOM</th>
                                            <th scope="col">Batch No</th>
                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ verticalAlign: 'middle' }}>
                                        {rejectionItems?.map((val, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{val?.Entryid}</td>
                                                    <td>{moment(val.EntryDate).format("DD-MMM-YYYY")}</td>
                                                    <td style={{ width: '80px' }}>{val?.itemname}</td>
                                                    <td style={{ width: '80px' }}>{val?.Barcode}</td>
                                                    <td style={{ width: '80px' }}>{val?.Batchno}</td>
                                                    <td style={{ width: '80px' }}>{val?.Qty}</td>
                                                    <td colSpan={2}>
                                                        <div className='inputBox'>
                                                            <Autocomplete size="small"
                                                                options={itemMasterJson?.filter((each) => each?.BrandType === 'OWNBRAND')}
                                                                getOptionLabel={(option: any) => option.itemname}
                                                                onChange={(event, value) => handleAutoComplete('convItemid', value, val)}
                                                                style={{ width: "300px" }}
                                                                renderInput={(params: any) =>
                                                                    <TextField  {...params}
                                                                        id="outlined-size-small"
                                                                        color='info'
                                                                        size="small"
                                                                        type="text"
                                                                        placeholder='Select Item..'
                                                                        name='convItemid'
                                                                    />}
                                                            />
                                                            {errors.convItemid && <p style={{ color: 'red' }}>{errors.convItemid}</p>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='inputBox'>
                                                            <input type="number"
                                                                name='convQty'
                                                                value={rejectionConversionMaster?.rejectionconversionApprovaldetails?.find((m) => m.entryid === val?.Entryid)?.convQty ?? 0}
                                                                onChange={(e) => handlebatchdetails(e, val)}
                                                                style={{ width: "100%" }} placeholder='Enter Conv. Qty..'></input>
                                                            {errors.convQty && <p style={{ color: 'red' }}>{errors.convQty}</p>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='inputBox'>
                                                            <input type="text" name='convUOM' disabled
                                                                value={rejectionConversionMaster?.rejectionconversionApprovaldetails?.find((m) => m.entryid === val?.Entryid)?.convUOM ?? ''}
                                                                style={{ width: "100%" }} placeholder='Enter convUOM..'></input>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='inputBox'>
                                                            <input type="text"
                                                                name='convBatchno'
                                                                value={rejectionConversionMaster?.rejectionconversionApprovaldetails?.find((m) => m.entrydid === val?.Entrydid)?.convBatchno ?? ''}
                                                                onChange={(e) => handlebatchdetails(e, val)}
                                                                style={{ width: "100%" }} placeholder='Enter Batch No..'></input>
                                                            {errors.convBatchno && <p style={{ color: 'red' }}>{errors.convBatchno}</p>}
                                                        </div>
                                                    </td>
                                                    <td><input type="checkbox"
                                                        checked={rejectionConversionMaster?.rejectionconversionApprovaldetails?.find((m) => m.entrydid === val?.Entrydid)?.checked ?? false}
                                                        onChange={(e) => handlebatchNo(e, val)}></input></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {errors.rejectionList && <p style={{ color: 'red' }}>{errors.rejectionList}</p>}
                            </div>
                            <div className='LgInputListsBox mr-left-0'>
                                <div className='vertical-space-10'></div>
                                <div className='row'>
                                    {isSpinner &&
                                        <div className='SpinnerBox'>
                                            <Spinner animation="grow" size="sm" />
                                        </div>}
                                    <div className='col-sm-8'>
                                        <div className='inputBox'>
                                            <label>Created by </label>
                                            <AutoComplete
                                                placeholder='Select Created by..'
                                                options={removeDuplicates(loadEmployees, 'employeeName')}
                                                emitOption={(val) => handleAutoComplete('convertedby', val)}
                                                getOptionLabel='employeeName'
                                            />
                                        </div>
                                        {errors.createdby && <p style={{ color: 'red' }}>{errors.createdby}</p>}
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Entry Date <span>*</span></label>
                                            <input type="date"
                                                name='entryDate'
                                                defaultValue={new Date().toISOString().substr(0, 10)}
                                                value={rejectionConversionMaster.entryDate = rejectionConversionMaster?.entryDate ?? new Date().toISOString().substr(0, 10)}
                                                onChange={handleInputchange}
                                                style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='vertical-space-10'></div>

                        </div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' onClick={handleCancel}>List</button>
                            <button className='dfBtn' type='submit'
                                onClick={saveReuseConversion}
                            >Submit</button>
                        </div>
                    </div>
                    <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../../image/checked.png')} />
                                </div>
                                <h4>Succesfully Submitted</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleSuccessModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../../image/warning.png')} />
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFailureModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>

        )
    }
}

export default ReuseConversion