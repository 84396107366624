import { observable } from "mobx";
export interface IStockAdjustment {
    assignOfferDiscount?: IStockAdjustment[];

}
export interface IStockAdjustment {
    stockadjsno?: number;
    Stockadjdate?: string;
    stkoid?: number;
    stkwid?: number;
    stockAdjoutdetails?: IStockAdjustmentDetails[];
}

export interface IStockAdjustmentSearchoptions {
    searchOption?: string;
    searchValue?: string;
}

export class IStockAdjustmentModel {
    entryNo?: number;
    entryDate?: string;
    itemid?: number;
    itemName?: string;
    currentStock?: number;
    Outlet?: string;
    wid?: number;
    barcode?: string;
    batchno?: string; 
    batchqty?: number = 0;
    addQty?: number = 0;
    lessQty?: number = 0;
    mrp?: number = 0;
    stockAdjoutdetails?: IStockAdjustmentDetails[]
}

export class IStockAdjustmentDetails {
    smid?: number;
    itemName?: string;
    itemname?: string;
    itemid?: number;
    barcode?: string;  
    batchno?: string;
    batchqty?: number = 0;
    addQty?: number;
    lessQty?: number;
    mrp?: number;
}

const StockAdjustmentModel = observable<IStockAdjustment>({
    stockadjsno: 0,
    Stockadjdate: '',
    stkoid: 0,
    stkwid: 0,
    stockAdjoutdetails: []
});

export default StockAdjustmentModel;
