import { observable } from "mobx";

export class IDamageEntryWarehouse {
    damageEntryId?: number = 0;
    damageWarehouseId?: number = 0;
    damageEntryDate?: string = "";
    warehouseId?: number = 0;
    warehouseName?: string = "";
    empId?: number = 0;
    damageItemId?: number = 0;
    damageItemQty?: number = 0;
    damageBarcode?: string = "";
    damageBatchNo?: string = "";
    damageDescription?: string = "";
    damageItemName?: string = "";
    damageMrprate?: number = 0;
    currentStock?: number = 0;
    damageEntryWarehouseDetails?: IDamageEntryWarehouseDetails[]

}

export class IDamageEntryWarehouseDetails {
    damageItemId?: number = 0;
    damageItemQty?: number = 0;
    damageBarcode?: string = "";
    damageBatchNo?: string = "";
    damageDescription?: string = "";
    damageItemName?: string = "";
    damageMrprate?: number = 0;
    currentStock?: number = 0;
}

export class IDamageEntryWarehouseSearchoptions {
    searchOption?: string = "";
    searchValue?: string = "";
}

export class IDamgeBatchNoRequest {
    warehouseId?: number = 0;
    itemId?: number = 0;
}

