import { action, set, makeAutoObservable, observable, runInAction } from 'mobx';
import { IitemMaster } from '../model/index';
import apiAgent from '../../../APIAgent';
import { access, accessSync } from 'fs';

class ItemMasterStore {
    @observable iitemMasterDetls = new Map()
    itemMasterList = new Map();
    @observable isItemMasterLoading = true;
    @observable itemMasterStatus = new Map();
    @observable saveimageStatus = new Map();
    @observable itemSupplierDetails = new Map();
    @observable itemDetls = new Map();
    @observable entryNo = new Map();
    @observable taxMasters = new Map();
    @observable itemWiseStockList = new Map();
    gsmCode = new Map();
    @observable isEdit = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action saveItemMaster = async (itemDetails: IitemMaster) => {
        try {
            const extension = itemDetails.fileName?.split('.').pop();

            itemDetails.fileName = itemDetails.itemCode + '.' + extension;
            const status = await apiAgent.itemMaster.saveItemMaster(itemDetails);
            runInAction(() => {
                this.itemMasterStatus = status;
            });

            return this.itemMasterStatus;
        }
        catch (error) {
            console.log(error);
        }
    }

    saveItemImage = async (itemImage, itemcode) => {
        try {
            const formdata = new FormData();
            const extension = itemImage.name.split('.').pop();

            formdata.append('file', itemImage, itemcode + '.' + extension);

            const status = await apiAgent.itemMaster.saveImageItemMaster(formdata);

            runInAction(() => {
                this.saveimageStatus = status;
            });

            return this.saveimageStatus;

        }
        catch (error) {
            console.log(error);
        }
    }
    getItemMasterDetails = async () => {
        try {
            const itemMasterDetails = await apiAgent.itemMaster.getItemMasterDetails()
            console.log(itemMasterDetails, "");

            return JSON.parse(itemMasterDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
    getItemMasters = async () => {
        try {
            const masterData = await apiAgent.itemMaster.getItemMasterDetails();
            console.log(masterData, "masterDataaaaaa");
            this.itemMasterList = masterData;

            runInAction(() => {
                this.itemMasterList = masterData;
            });
            return JSON.parse(masterData);
        }
        catch (error) {
            console.log(error);
        }
    }

    GETItemwiseStockAll = async (mode: any, itemid: any, barcode: any, outletid: any) => {

        try {
            const masterData = await apiAgent.posMaster.GETItemwiseStockAll(mode, itemid, barcode, outletid);
            this.itemWiseStockList = JSON.parse(masterData);

            runInAction(() => {
                this.itemWiseStockList = JSON.parse(masterData);
            });
            return JSON.parse(masterData);


        }
        catch (error) {
            console.log(error);
        }
    }

    getSupplierDetails = async (itemId) => {
        try {
            const supplierData = await apiAgent.itemMaster.getSupplierDetails(itemId);

            runInAction(() => {
                this.itemSupplierDetails = supplierData;

            });
            return supplierData;
        }
        catch (error) {
            console.log(error);
        }
    }

    importExportForm = async (body) => {
        try {
            const userCreationData = await apiAgent.itemMaster.importExportForm(body);
            return userCreationData
        }
        catch (error) {
            console.log(error);
        }
    }
    importExportFormSave = async (body) => {
        try {
            const userCreationData = await apiAgent.itemMaster.importExportFormSave(body);
            return userCreationData
        }
        catch (error) {
            console.log(error);
        }
    }

    getItemDetails = async (itemId: Number) => {

        try {
            const itemData = await apiAgent.itemMaster.getItemDetails(itemId);
            runInAction(() => {
                this.itemDetls = JSON.parse(itemData);
            });
            return JSON.parse(itemData);
        }
        catch (error) {
            console.log(error);
        }
    }
    getEntryNo = async (flag) => {
        try {
            const entryNo = await apiAgent.helper.getWareHosueModuleEntryNo(flag);

            runInAction(() => {
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch (error) {
            console.log(error);
        }
    }
    getTaxMaster = async (flag) => {
        try {
            const ledgerData = await apiAgent.itemMaster.getTaxMaster(flag);

            runInAction(() => {
                this.taxMasters = ledgerData;
            })
            return ledgerData;
        }
        catch (error) {
            console.log(error);
        }
    }
    getGsmCode = async (gsmDetail) => {
        try {
            const gsmCode = await apiAgent.itemMaster.getGsmCode(gsmDetail);

            runInAction(() => {
                this.gsmCode = gsmCode;
            })
            return this.gsmCode;
        }
        catch (error) {
            console.log(error);
        }
    }
    updateItemMaster = async (itemDetails: IitemMaster) => {
        try {
            if (itemDetails.fileName !== undefined) {
                const extension = itemDetails.fileName?.split('.').pop();

                itemDetails.fileName = itemDetails.itemId + '.' + extension;
            }

            const status = await apiAgent.itemMaster.updateItemMaster(itemDetails);

            runInAction(() => {
                this.itemMasterStatus = status;
            });

            return this.itemMasterStatus;
        }
        catch (error) {
            console.log(error);
        }
    }
    uploadItemImage = async (itemImage, itemcode) => {
        try {
            const formdata = new FormData();
            const extension = itemImage.name.split('.').pop();

            formdata.append('file', itemImage, itemcode + '.' + extension);

            console.log(itemImage, extension, itemcode, formdata, 'itemImage>>')
            const status = await apiAgent.itemMaster.uploadItemImage(formdata, itemcode);

            runInAction(() => {
                this.saveimageStatus = status;
            });

            return this.saveimageStatus;

        }
        catch (error) {
            console.log(error);
        }
    }

    updateItemMasterStatus = async (itemId: number, activeStatus: string) => {
        try {
            const status = await apiAgent.itemMaster.updateItemMasterStatus(itemId, activeStatus);
            runInAction(() => {
                this.itemMasterStatus = status;
            });
            return this.itemMasterStatus;
        }
        catch (error) {
            console.log(error);
        }
    }

    getSearchItemMasters = async (searchOptions) => {

        try {

            const masterData = await apiAgent.itemMaster.getSearchItemDetails(searchOptions);
            this.itemMasterList = masterData;

            runInAction(() => {
                this.itemMasterList = masterData;
            });
            return this.itemMasterList;
        }
        catch (error) {
            console.log(error);
        }
    }

}
export default ItemMasterStore;
