export class IStockWarehouse {
    stocktoWId?: number = 0;
    stocktoWDate?: string = new Date()?.toISOString()?.substr(0, 10);
    warehouseId?: number = 0;
    stocktoWarehousedetails?: StockToWarehouseDetails[] = []
}

export class StockToWarehouseDetails {
    stocktoWId?: number = 0;
    Itemid?: number = 0;
    itemname?: string = "";
    pid?: number = 0 // optional
    qty?: number = 0;
    Mrprate?: number = 0;
    batchNo?: string = "";
    barcode?: string = "";
    balQty?: string = "";
    ttype?: string = "";
    ctype?: string = "";
    pidid?: number = 0
}