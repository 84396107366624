import React, { useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import { IInwardDetailSearchRequest } from './model';
import moment from 'moment';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { SearchDates } from '../../Admin/QuoteFromSupplier/model';

const PurchaseBillDetail = observer((): JSX.Element => {
  const navigate = useNavigate();
  const { purchaseBillStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const [isLoading, setLoading] = useState(true);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [searchFileds, setSearchFields] = useState<SearchDates>(new SearchDates())

  function handleCreate() {
    navigate("/Admin/PurchaseBill")
  }

  const [isInward, setInward] = useState(false);

  const modalClose = () => setInward(false);
  const handleInwardDetails = async (purchaseBillId) => {

    setLoading(true);
    await purchaseBillStore.getPurchaseBillDetails(purchaseBillId);
    setLoading(false);
    setInward(true);
  }

  async function preLoadingBillDetails() {
    const userRights = await userCreationStore?.getUserRight('Purchase_bill')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await purchaseBillStore.getPurchaseBillMasterDetails();
    await purchaseBillStore.getInwardSupliers();
    setLoading(false);
  }

  const isCurrenPage = useRef(true)

  useEffect(() => {
    if (isCurrenPage.current) { preLoadingBillDetails(); isCurrenPage.current = false; }
    return () => { }
  }, []);


  const sortedData = useMemo((): any => {
    if (purchaseBillStore?.puchaseBillDetails?.length) {
      const listedData = purchaseBillStore?.puchaseBillDetails?.slice()?.sort((a, b) => {
        const dateA = new Date(a.purchaseBillDate);
        const dateB = new Date(b.purchaseBillDate);

        if (dateA > dateB) return -1;
        if (dateB < dateA) return 1;
        return 0;
      });
      return listedData;
    }
  }, [purchaseBillStore?.puchaseBillDetails])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (sortedData && sortedData?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(sortedData)
    }
  }, [sortedData])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  function handleFindDate(date) {
    if (searchFileds?.fromdate && searchFileds?.todate) {
      const yesterday = new Date(searchFileds?.fromdate);
      yesterday.setDate(yesterday.getDate() - 1)
      if (new Date(date) >= yesterday && new Date(date) < new Date(searchFileds?.todate)) {
        return true;
      } else {
        return false
      }
    } else if (searchFileds?.fromdate) {
      if (new Date(date) > new Date(searchFileds?.fromdate)) {
        return true
      } else {
        return false
      }
    } else if (searchFileds?.todate) {
      if (new Date(date) < new Date(searchFileds?.todate)) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <div className='container-fluid'>
      <div className='vertical-space-20'></div>

      <div className='outletInputField inputFormBox LgInputField' style={{ width: '90%' }}>
        <div className='inputBoxLists'>
          <div className='ItemInwardInputBox'>
            <div className='row'>
              <div className="col-md-2">
                <label>From Date</label>
                <div className="inputBox">
                  <input type="date"
                    placeholder="DD/MMM/YYYY"
                    data-date="" style={{ width: "100%" }}
                    data-date-format="DD MMMM YYYY"
                    value={searchFileds?.fromdate}
                    onChange={(e) => setSearchFields({ ...searchFileds, fromdate: e.target.value })}
                    name="fromdate"
                  ></input>
                </div>
                <div className="vertical-space-10"></div>
              </div>
              <div className="col-md-2">
                <div className="inputBox">
                  <label> To Date</label>
                  <input type="date"
                    style={{ width: "100%" }}
                    placeholder="DD/MMM/YYYY"
                    data-date=""
                    data-date-format="DD MMMM YYYY"
                    value={searchFileds?.todate}
                    onChange={(e) => setSearchFields({ ...searchFileds, todate: e.target.value })}
                    name="todate"
                  ></input>
                </div>
                <div className="vertical-space-10"></div>
              </div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={purchaseBillStore?.puchaseBillDetails}
                searchTitles={[{ key: "supplierName", value: "Supplier" }, { key: "billNo", value: "By Bill" }]}
                emitClear={() => setSearchFields(new SearchDates())}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} isEmitClear />
            </div>
            <div className='vertical-space-20'></div>
            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className='btnBox'>
                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{purchaseBillStore?.puchaseBillDetails?.length} Records</button>
              </div>
              <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
              </Stack>
            </div>
            <div className='vertical-space-20'></div>
            <div className='tableListDetails'>
              {
                isLoading ?
                  <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div> : null
              }
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>Date  </th>
                    <th scope='col'>Supplier  </th>
                    <th scope='col'>Bill No  </th>
                    <th scope='col'>Remarks  </th>
                    <th scope='col'>Discounts</th>
                    <th scope='col'>Expenses</th>
                    <th scope='col'>Net Amout</th>
                    <th scope='col'></th>
                    <th scope='col'>Create </th>
                    <th scope='col'>Update </th>
                    <th scope='col'>Delete </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (isSearch ? filteredData : currentPageData)?.length > 0 &&
                    (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                      return ((searchFileds?.fromdate && searchFileds?.todate) ? handleFindDate(val?.purchaseBillDate) : val?.purchaseBillDate) && (
                        <tr key={key}>
                          <td>{moment(val.purchaseBillDate).format('DD-MM-YYYY')}</td>
                          <td>{val.supplierName}</td>
                          <td>{val.billNo}</td>
                          <td>{val.remarks}</td>
                          <td>{val?.discount}</td>
                          <td>{val?.expenses}</td>
                          <td>{val?.nettotal}</td>
                          <td><button disabled={!userRights?.view} onClick={() => handleInwardDetails(val.purchaseBillId)}>Detail</button></td>
                          <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                          <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                          <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {
        isInward ? <Modal show={isInward} onHide={modalClose} className='PriceHistoryModel'>
          <Modal.Header closeButton>
            <Modal.Title>Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Item Name</th>
                    <th scope="col">Bill Qty</th>
                    <th scope="col">Rate</th>
                    <th scope="col">Dis Value</th>
                    <th scope='col'>Sub Total</th>
                    <th scope="col">CGST %</th>
                    <th scope="col">CGST Value</th>
                    <th scope="col">SGST %</th>
                    <th scope="col">SGST Value</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    purchaseBillStore.puchaseDetails.length > 0 &&
                    purchaseBillStore.puchaseDetails.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.itemName}</td>
                          <td>{val.billQty}</td>
                          <td>{val.rate}</td>
                          <td>{val.discountValue}</td>
                          <td>{val.subTotal}</td>
                          <td>{val.cgstper}</td>
                          <td>{val.cgstamt}</td>
                          <td>{val.sgstper}</td>
                          <td>{val.sgstamt}</td>
                          <td>{val.totalAmount}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal> : null
      }

    </div>
  );

});

export default PurchaseBillDetail;
